.application .black {
	background-color: #000 !important;
	border-color: #000 !important;
}

.application .black--text {
	color: #000 !important;
	caret-color: #000 !important;
}

.application .white {
	background-color: #fff !important;
	border-color: #fff !important;
}

.application .white--text {
	color: #fff !important;
	caret-color: #fff !important;
}

.application .transparent {
	background-color: transparent !important;
	border-color: transparent !important;
}

.application .transparent--text {
	color: transparent !important;
	caret-color: transparent !important;
}

.application .red {
	background-color: #f44336 !important;
	border-color: #f44336 !important;
}

.application .red--text {
	color: #f44336 !important;
	caret-color: #f44336 !important;
}

.application .red.lighten-5 {
	background-color: #ffebee !important;
	border-color: #ffebee !important;
}

.application .red--text.text--lighten-5 {
	color: #ffebee !important;
	caret-color: #ffebee !important;
}

.application .red.lighten-4 {
	background-color: #ffcdd2 !important;
	border-color: #ffcdd2 !important;
}

.application .red--text.text--lighten-4 {
	color: #ffcdd2 !important;
	caret-color: #ffcdd2 !important;
}

.application .red.lighten-3 {
	background-color: #ef9a9a !important;
	border-color: #ef9a9a !important;
}

.application .red--text.text--lighten-3 {
	color: #ef9a9a !important;
	caret-color: #ef9a9a !important;
}

.application .red.lighten-2 {
	background-color: #e57373 !important;
	border-color: #e57373 !important;
}

.application .red--text.text--lighten-2 {
	color: #e57373 !important;
	caret-color: #e57373 !important;
}

.application .red.lighten-1 {
	background-color: #ef5350 !important;
	border-color: #ef5350 !important;
}

.application .red--text.text--lighten-1 {
	color: #ef5350 !important;
	caret-color: #ef5350 !important;
}

.application .red.darken-1 {
	background-color: #e53935 !important;
	border-color: #e53935 !important;
}

.application .red--text.text--darken-1 {
	color: #e53935 !important;
	caret-color: #e53935 !important;
}

.application .red.darken-2 {
	background-color: #d32f2f !important;
	border-color: #d32f2f !important;
}

.application .red--text.text--darken-2 {
	color: #d32f2f !important;
	caret-color: #d32f2f !important;
}

.application .red.darken-3 {
	background-color: #c62828 !important;
	border-color: #c62828 !important;
}

.application .red--text.text--darken-3 {
	color: #c62828 !important;
	caret-color: #c62828 !important;
}

.application .red.darken-4 {
	background-color: #b71c1c !important;
	border-color: #b71c1c !important;
}

.application .red--text.text--darken-4 {
	color: #b71c1c !important;
	caret-color: #b71c1c !important;
}

.application .red.accent-1 {
	background-color: #ff8a80 !important;
	border-color: #ff8a80 !important;
}

.application .red--text.text--accent-1 {
	color: #ff8a80 !important;
	caret-color: #ff8a80 !important;
}

.application .red.accent-2 {
	background-color: #ff5252 !important;
	border-color: #ff5252 !important;
}

.application .red--text.text--accent-2 {
	color: #ff5252 !important;
	caret-color: #ff5252 !important;
}

.application .red.accent-3 {
	background-color: #ff1744 !important;
	border-color: #ff1744 !important;
}

.application .red--text.text--accent-3 {
	color: #ff1744 !important;
	caret-color: #ff1744 !important;
}

.application .red.accent-4 {
	background-color: #d50000 !important;
	border-color: #d50000 !important;
}

.application .red--text.text--accent-4 {
	color: #d50000 !important;
	caret-color: #d50000 !important;
}

.application .pink {
	background-color: #e91e63 !important;
	border-color: #e91e63 !important;
}

.application .pink--text {
	color: #e91e63 !important;
	caret-color: #e91e63 !important;
}

.application .pink.lighten-5 {
	background-color: #fce4ec !important;
	border-color: #fce4ec !important;
}

.application .pink--text.text--lighten-5 {
	color: #fce4ec !important;
	caret-color: #fce4ec !important;
}

.application .pink.lighten-4 {
	background-color: #f8bbd0 !important;
	border-color: #f8bbd0 !important;
}

.application .pink--text.text--lighten-4 {
	color: #f8bbd0 !important;
	caret-color: #f8bbd0 !important;
}

.application .pink.lighten-3 {
	background-color: #f48fb1 !important;
	border-color: #f48fb1 !important;
}

.application .pink--text.text--lighten-3 {
	color: #f48fb1 !important;
	caret-color: #f48fb1 !important;
}

.application .pink.lighten-2 {
	background-color: #f06292 !important;
	border-color: #f06292 !important;
}

.application .pink--text.text--lighten-2 {
	color: #f06292 !important;
	caret-color: #f06292 !important;
}

.application .pink.lighten-1 {
	background-color: #ec407a !important;
	border-color: #ec407a !important;
}

.application .pink--text.text--lighten-1 {
	color: #ec407a !important;
	caret-color: #ec407a !important;
}

.application .pink.darken-1 {
	background-color: #d81b60 !important;
	border-color: #d81b60 !important;
}

.application .pink--text.text--darken-1 {
	color: #d81b60 !important;
	caret-color: #d81b60 !important;
}

.application .pink.darken-2 {
	background-color: #c2185b !important;
	border-color: #c2185b !important;
}

.application .pink--text.text--darken-2 {
	color: #c2185b !important;
	caret-color: #c2185b !important;
}

.application .pink.darken-3 {
	background-color: #ad1457 !important;
	border-color: #ad1457 !important;
}

.application .pink--text.text--darken-3 {
	color: #ad1457 !important;
	caret-color: #ad1457 !important;
}

.application .pink.darken-4 {
	background-color: #880e4f !important;
	border-color: #880e4f !important;
}

.application .pink--text.text--darken-4 {
	color: #880e4f !important;
	caret-color: #880e4f !important;
}

.application .pink.accent-1 {
	background-color: #ff80ab !important;
	border-color: #ff80ab !important;
}

.application .pink--text.text--accent-1 {
	color: #ff80ab !important;
	caret-color: #ff80ab !important;
}

.application .pink.accent-2 {
	background-color: #ff4081 !important;
	border-color: #ff4081 !important;
}

.application .pink--text.text--accent-2 {
	color: #ff4081 !important;
	caret-color: #ff4081 !important;
}

.application .pink.accent-3 {
	background-color: #f50057 !important;
	border-color: #f50057 !important;
}

.application .pink--text.text--accent-3 {
	color: #f50057 !important;
	caret-color: #f50057 !important;
}

.application .pink.accent-4 {
	background-color: #c51162 !important;
	border-color: #c51162 !important;
}

.application .pink--text.text--accent-4 {
	color: #c51162 !important;
	caret-color: #c51162 !important;
}

.application .purple {
	background-color: #9c27b0 !important;
	border-color: #9c27b0 !important;
}

.application .purple--text {
	color: #9c27b0 !important;
	caret-color: #9c27b0 !important;
}

.application .purple.lighten-5 {
	background-color: #f3e5f5 !important;
	border-color: #f3e5f5 !important;
}

.application .purple--text.text--lighten-5 {
	color: #f3e5f5 !important;
	caret-color: #f3e5f5 !important;
}

.application .purple.lighten-4 {
	background-color: #e1bee7 !important;
	border-color: #e1bee7 !important;
}

.application .purple--text.text--lighten-4 {
	color: #e1bee7 !important;
	caret-color: #e1bee7 !important;
}

.application .purple.lighten-3 {
	background-color: #ce93d8 !important;
	border-color: #ce93d8 !important;
}

.application .purple--text.text--lighten-3 {
	color: #ce93d8 !important;
	caret-color: #ce93d8 !important;
}

.application .purple.lighten-2 {
	background-color: #ba68c8 !important;
	border-color: #ba68c8 !important;
}

.application .purple--text.text--lighten-2 {
	color: #ba68c8 !important;
	caret-color: #ba68c8 !important;
}

.application .purple.lighten-1 {
	background-color: #ab47bc !important;
	border-color: #ab47bc !important;
}

.application .purple--text.text--lighten-1 {
	color: #ab47bc !important;
	caret-color: #ab47bc !important;
}

.application .purple.darken-1 {
	background-color: #8e24aa !important;
	border-color: #8e24aa !important;
}

.application .purple--text.text--darken-1 {
	color: #8e24aa !important;
	caret-color: #8e24aa !important;
}

.application .purple.darken-2 {
	background-color: #7b1fa2 !important;
	border-color: #7b1fa2 !important;
}

.application .purple--text.text--darken-2 {
	color: #7b1fa2 !important;
	caret-color: #7b1fa2 !important;
}

.application .purple.darken-3 {
	background-color: #6a1b9a !important;
	border-color: #6a1b9a !important;
}

.application .purple--text.text--darken-3 {
	color: #6a1b9a !important;
	caret-color: #6a1b9a !important;
}

.application .purple.darken-4 {
	background-color: #4a148c !important;
	border-color: #4a148c !important;
}

.application .purple--text.text--darken-4 {
	color: #4a148c !important;
	caret-color: #4a148c !important;
}

.application .purple.accent-1 {
	background-color: #ea80fc !important;
	border-color: #ea80fc !important;
}

.application .purple--text.text--accent-1 {
	color: #ea80fc !important;
	caret-color: #ea80fc !important;
}

.application .purple.accent-2 {
	background-color: #e040fb !important;
	border-color: #e040fb !important;
}

.application .purple--text.text--accent-2 {
	color: #e040fb !important;
	caret-color: #e040fb !important;
}

.application .purple.accent-3 {
	background-color: #d500f9 !important;
	border-color: #d500f9 !important;
}

.application .purple--text.text--accent-3 {
	color: #d500f9 !important;
	caret-color: #d500f9 !important;
}

.application .purple.accent-4 {
	background-color: #a0f !important;
	border-color: #a0f !important;
}

.application .purple--text.text--accent-4 {
	color: #a0f !important;
	caret-color: #a0f !important;
}

.application .deep-purple {
	background-color: #673ab7 !important;
	border-color: #673ab7 !important;
}

.application .deep-purple--text {
	color: #673ab7 !important;
	caret-color: #673ab7 !important;
}

.application .deep-purple.lighten-5 {
	background-color: #ede7f6 !important;
	border-color: #ede7f6 !important;
}

.application .deep-purple--text.text--lighten-5 {
	color: #ede7f6 !important;
	caret-color: #ede7f6 !important;
}

.application .deep-purple.lighten-4 {
	background-color: #d1c4e9 !important;
	border-color: #d1c4e9 !important;
}

.application .deep-purple--text.text--lighten-4 {
	color: #d1c4e9 !important;
	caret-color: #d1c4e9 !important;
}

.application .deep-purple.lighten-3 {
	background-color: #b39ddb !important;
	border-color: #b39ddb !important;
}

.application .deep-purple--text.text--lighten-3 {
	color: #b39ddb !important;
	caret-color: #b39ddb !important;
}

.application .deep-purple.lighten-2 {
	background-color: #9575cd !important;
	border-color: #9575cd !important;
}

.application .deep-purple--text.text--lighten-2 {
	color: #9575cd !important;
	caret-color: #9575cd !important;
}

.application .deep-purple.lighten-1 {
	background-color: #7e57c2 !important;
	border-color: #7e57c2 !important;
}

.application .deep-purple--text.text--lighten-1 {
	color: #7e57c2 !important;
	caret-color: #7e57c2 !important;
}

.application .deep-purple.darken-1 {
	background-color: #5e35b1 !important;
	border-color: #5e35b1 !important;
}

.application .deep-purple--text.text--darken-1 {
	color: #5e35b1 !important;
	caret-color: #5e35b1 !important;
}

.application .deep-purple.darken-2 {
	background-color: #512da8 !important;
	border-color: #512da8 !important;
}

.application .deep-purple--text.text--darken-2 {
	color: #512da8 !important;
	caret-color: #512da8 !important;
}

.application .deep-purple.darken-3 {
	background-color: #4527a0 !important;
	border-color: #4527a0 !important;
}

.application .deep-purple--text.text--darken-3 {
	color: #4527a0 !important;
	caret-color: #4527a0 !important;
}

.application .deep-purple.darken-4 {
	background-color: #311b92 !important;
	border-color: #311b92 !important;
}

.application .deep-purple--text.text--darken-4 {
	color: #311b92 !important;
	caret-color: #311b92 !important;
}

.application .deep-purple.accent-1 {
	background-color: #b388ff !important;
	border-color: #b388ff !important;
}

.application .deep-purple--text.text--accent-1 {
	color: #b388ff !important;
	caret-color: #b388ff !important;
}

.application .deep-purple.accent-2 {
	background-color: #7c4dff !important;
	border-color: #7c4dff !important;
}

.application .deep-purple--text.text--accent-2 {
	color: #7c4dff !important;
	caret-color: #7c4dff !important;
}

.application .deep-purple.accent-3 {
	background-color: #651fff !important;
	border-color: #651fff !important;
}

.application .deep-purple--text.text--accent-3 {
	color: #651fff !important;
	caret-color: #651fff !important;
}

.application .deep-purple.accent-4 {
	background-color: #6200ea !important;
	border-color: #6200ea !important;
}

.application .deep-purple--text.text--accent-4 {
	color: #6200ea !important;
	caret-color: #6200ea !important;
}

.application .indigo {
	background-color: #3f51b5 !important;
	border-color: #3f51b5 !important;
}

.application .indigo--text {
	color: #3f51b5 !important;
	caret-color: #3f51b5 !important;
}

.application .indigo.lighten-5 {
	background-color: #e8eaf6 !important;
	border-color: #e8eaf6 !important;
}

.application .indigo--text.text--lighten-5 {
	color: #e8eaf6 !important;
	caret-color: #e8eaf6 !important;
}

.application .indigo.lighten-4 {
	background-color: #c5cae9 !important;
	border-color: #c5cae9 !important;
}

.application .indigo--text.text--lighten-4 {
	color: #c5cae9 !important;
	caret-color: #c5cae9 !important;
}

.application .indigo.lighten-3 {
	background-color: #9fa8da !important;
	border-color: #9fa8da !important;
}

.application .indigo--text.text--lighten-3 {
	color: #9fa8da !important;
	caret-color: #9fa8da !important;
}

.application .indigo.lighten-2 {
	background-color: #7986cb !important;
	border-color: #7986cb !important;
}

.application .indigo--text.text--lighten-2 {
	color: #7986cb !important;
	caret-color: #7986cb !important;
}

.application .indigo.lighten-1 {
	background-color: #5c6bc0 !important;
	border-color: #5c6bc0 !important;
}

.application .indigo--text.text--lighten-1 {
	color: #5c6bc0 !important;
	caret-color: #5c6bc0 !important;
}

.application .indigo.darken-1 {
	background-color: #3949ab !important;
	border-color: #3949ab !important;
}

.application .indigo--text.text--darken-1 {
	color: #3949ab !important;
	caret-color: #3949ab !important;
}

.application .indigo.darken-2 {
	background-color: #303f9f !important;
	border-color: #303f9f !important;
}

.application .indigo--text.text--darken-2 {
	color: #303f9f !important;
	caret-color: #303f9f !important;
}

.application .indigo.darken-3 {
	background-color: #283593 !important;
	border-color: #283593 !important;
}

.application .indigo--text.text--darken-3 {
	color: #283593 !important;
	caret-color: #283593 !important;
}

.application .indigo.darken-4 {
	background-color: #1a237e !important;
	border-color: #1a237e !important;
}

.application .indigo--text.text--darken-4 {
	color: #1a237e !important;
	caret-color: #1a237e !important;
}

.application .indigo.accent-1 {
	background-color: #8c9eff !important;
	border-color: #8c9eff !important;
}

.application .indigo--text.text--accent-1 {
	color: #8c9eff !important;
	caret-color: #8c9eff !important;
}

.application .indigo.accent-2 {
	background-color: #536dfe !important;
	border-color: #536dfe !important;
}

.application .indigo--text.text--accent-2 {
	color: #536dfe !important;
	caret-color: #536dfe !important;
}

.application .indigo.accent-3 {
	background-color: #3d5afe !important;
	border-color: #3d5afe !important;
}

.application .indigo--text.text--accent-3 {
	color: #3d5afe !important;
	caret-color: #3d5afe !important;
}

.application .indigo.accent-4 {
	background-color: #304ffe !important;
	border-color: #304ffe !important;
}

.application .indigo--text.text--accent-4 {
	color: #304ffe !important;
	caret-color: #304ffe !important;
}

.application .blue {
	background-color: #2196f3 !important;
	border-color: #2196f3 !important;
}

.application .blue--text {
	color: #2196f3 !important;
	caret-color: #2196f3 !important;
}

.application .blue.lighten-5 {
	background-color: #e3f2fd !important;
	border-color: #e3f2fd !important;
}

.application .blue--text.text--lighten-5 {
	color: #e3f2fd !important;
	caret-color: #e3f2fd !important;
}

.application .blue.lighten-4 {
	background-color: #bbdefb !important;
	border-color: #bbdefb !important;
}

.application .blue--text.text--lighten-4 {
	color: #bbdefb !important;
	caret-color: #bbdefb !important;
}

.application .blue.lighten-3 {
	background-color: #90caf9 !important;
	border-color: #90caf9 !important;
}

.application .blue--text.text--lighten-3 {
	color: #90caf9 !important;
	caret-color: #90caf9 !important;
}

.application .blue.lighten-2 {
	background-color: #64b5f6 !important;
	border-color: #64b5f6 !important;
}

.application .blue--text.text--lighten-2 {
	color: #64b5f6 !important;
	caret-color: #64b5f6 !important;
}

.application .blue.lighten-1 {
	background-color: #42a5f5 !important;
	border-color: #42a5f5 !important;
}

.application .blue--text.text--lighten-1 {
	color: #42a5f5 !important;
	caret-color: #42a5f5 !important;
}

.application .blue.darken-1 {
	background-color: #1e88e5 !important;
	border-color: #1e88e5 !important;
}

.application .blue--text.text--darken-1 {
	color: #1e88e5 !important;
	caret-color: #1e88e5 !important;
}

.application .blue.darken-2 {
	background-color: #1976d2 !important;
	border-color: #1976d2 !important;
}

.application .blue--text.text--darken-2 {
	color: #1976d2 !important;
	caret-color: #1976d2 !important;
}

.application .blue.darken-3 {
	background-color: #1565c0 !important;
	border-color: #1565c0 !important;
}

.application .blue--text.text--darken-3 {
	color: #1565c0 !important;
	caret-color: #1565c0 !important;
}

.application .blue.darken-4 {
	background-color: #0d47a1 !important;
	border-color: #0d47a1 !important;
}

.application .blue--text.text--darken-4 {
	color: #0d47a1 !important;
	caret-color: #0d47a1 !important;
}

.application .blue.accent-1 {
	background-color: #82b1ff !important;
	border-color: #82b1ff !important;
}

.application .blue--text.text--accent-1 {
	color: #82b1ff !important;
	caret-color: #82b1ff !important;
}

.application .blue.accent-2 {
	background-color: #448aff !important;
	border-color: #448aff !important;
}

.application .blue--text.text--accent-2 {
	color: #448aff !important;
	caret-color: #448aff !important;
}

.application .blue.accent-3 {
	background-color: #2979ff !important;
	border-color: #2979ff !important;
}

.application .blue--text.text--accent-3 {
	color: #2979ff !important;
	caret-color: #2979ff !important;
}

.application .blue.accent-4 {
	background-color: #2962ff !important;
	border-color: #2962ff !important;
}

.application .blue--text.text--accent-4 {
	color: #2962ff !important;
	caret-color: #2962ff !important;
}

.application .light-blue {
	background-color: #03a9f4 !important;
	border-color: #03a9f4 !important;
}

.application .light-blue--text {
	color: #03a9f4 !important;
	caret-color: #03a9f4 !important;
}

.application .light-blue.lighten-5 {
	background-color: #e1f5fe !important;
	border-color: #e1f5fe !important;
}

.application .light-blue--text.text--lighten-5 {
	color: #e1f5fe !important;
	caret-color: #e1f5fe !important;
}

.application .light-blue.lighten-4 {
	background-color: #b3e5fc !important;
	border-color: #b3e5fc !important;
}

.application .light-blue--text.text--lighten-4 {
	color: #b3e5fc !important;
	caret-color: #b3e5fc !important;
}

.application .light-blue.lighten-3 {
	background-color: #81d4fa !important;
	border-color: #81d4fa !important;
}

.application .light-blue--text.text--lighten-3 {
	color: #81d4fa !important;
	caret-color: #81d4fa !important;
}

.application .light-blue.lighten-2 {
	background-color: #4fc3f7 !important;
	border-color: #4fc3f7 !important;
}

.application .light-blue--text.text--lighten-2 {
	color: #4fc3f7 !important;
	caret-color: #4fc3f7 !important;
}

.application .light-blue.lighten-1 {
	background-color: #29b6f6 !important;
	border-color: #29b6f6 !important;
}

.application .light-blue--text.text--lighten-1 {
	color: #29b6f6 !important;
	caret-color: #29b6f6 !important;
}

.application .light-blue.darken-1 {
	background-color: #039be5 !important;
	border-color: #039be5 !important;
}

.application .light-blue--text.text--darken-1 {
	color: #039be5 !important;
	caret-color: #039be5 !important;
}

.application .light-blue.darken-2 {
	background-color: #0288d1 !important;
	border-color: #0288d1 !important;
}

.application .light-blue--text.text--darken-2 {
	color: #0288d1 !important;
	caret-color: #0288d1 !important;
}

.application .light-blue.darken-3 {
	background-color: #0277bd !important;
	border-color: #0277bd !important;
}

.application .light-blue--text.text--darken-3 {
	color: #0277bd !important;
	caret-color: #0277bd !important;
}

.application .light-blue.darken-4 {
	background-color: #01579b !important;
	border-color: #01579b !important;
}

.application .light-blue--text.text--darken-4 {
	color: #01579b !important;
	caret-color: #01579b !important;
}

.application .light-blue.accent-1 {
	background-color: #80d8ff !important;
	border-color: #80d8ff !important;
}

.application .light-blue--text.text--accent-1 {
	color: #80d8ff !important;
	caret-color: #80d8ff !important;
}

.application .light-blue.accent-2 {
	background-color: #40c4ff !important;
	border-color: #40c4ff !important;
}

.application .light-blue--text.text--accent-2 {
	color: #40c4ff !important;
	caret-color: #40c4ff !important;
}

.application .light-blue.accent-3 {
	background-color: #00b0ff !important;
	border-color: #00b0ff !important;
}

.application .light-blue--text.text--accent-3 {
	color: #00b0ff !important;
	caret-color: #00b0ff !important;
}

.application .light-blue.accent-4 {
	background-color: #0091ea !important;
	border-color: #0091ea !important;
}

.application .light-blue--text.text--accent-4 {
	color: #0091ea !important;
	caret-color: #0091ea !important;
}

.application .cyan {
	background-color: #00bcd4 !important;
	border-color: #00bcd4 !important;
}

.application .cyan--text {
	color: #00bcd4 !important;
	caret-color: #00bcd4 !important;
}

.application .cyan.lighten-5 {
	background-color: #e0f7fa !important;
	border-color: #e0f7fa !important;
}

.application .cyan--text.text--lighten-5 {
	color: #e0f7fa !important;
	caret-color: #e0f7fa !important;
}

.application .cyan.lighten-4 {
	background-color: #b2ebf2 !important;
	border-color: #b2ebf2 !important;
}

.application .cyan--text.text--lighten-4 {
	color: #b2ebf2 !important;
	caret-color: #b2ebf2 !important;
}

.application .cyan.lighten-3 {
	background-color: #80deea !important;
	border-color: #80deea !important;
}

.application .cyan--text.text--lighten-3 {
	color: #80deea !important;
	caret-color: #80deea !important;
}

.application .cyan.lighten-2 {
	background-color: #4dd0e1 !important;
	border-color: #4dd0e1 !important;
}

.application .cyan--text.text--lighten-2 {
	color: #4dd0e1 !important;
	caret-color: #4dd0e1 !important;
}

.application .cyan.lighten-1 {
	background-color: #26c6da !important;
	border-color: #26c6da !important;
}

.application .cyan--text.text--lighten-1 {
	color: #26c6da !important;
	caret-color: #26c6da !important;
}

.application .cyan.darken-1 {
	background-color: #00acc1 !important;
	border-color: #00acc1 !important;
}

.application .cyan--text.text--darken-1 {
	color: #00acc1 !important;
	caret-color: #00acc1 !important;
}

.application .cyan.darken-2 {
	background-color: #0097a7 !important;
	border-color: #0097a7 !important;
}

.application .cyan--text.text--darken-2 {
	color: #0097a7 !important;
	caret-color: #0097a7 !important;
}

.application .cyan.darken-3 {
	background-color: #00838f !important;
	border-color: #00838f !important;
}

.application .cyan--text.text--darken-3 {
	color: #00838f !important;
	caret-color: #00838f !important;
}

.application .cyan.darken-4 {
	background-color: #006064 !important;
	border-color: #006064 !important;
}

.application .cyan--text.text--darken-4 {
	color: #006064 !important;
	caret-color: #006064 !important;
}

.application .cyan.accent-1 {
	background-color: #84ffff !important;
	border-color: #84ffff !important;
}

.application .cyan--text.text--accent-1 {
	color: #84ffff !important;
	caret-color: #84ffff !important;
}

.application .cyan.accent-2 {
	background-color: #18ffff !important;
	border-color: #18ffff !important;
}

.application .cyan--text.text--accent-2 {
	color: #18ffff !important;
	caret-color: #18ffff !important;
}

.application .cyan.accent-3 {
	background-color: #00e5ff !important;
	border-color: #00e5ff !important;
}

.application .cyan--text.text--accent-3 {
	color: #00e5ff !important;
	caret-color: #00e5ff !important;
}

.application .cyan.accent-4 {
	background-color: #00b8d4 !important;
	border-color: #00b8d4 !important;
}

.application .cyan--text.text--accent-4 {
	color: #00b8d4 !important;
	caret-color: #00b8d4 !important;
}

.application .teal {
	background-color: #009688 !important;
	border-color: #009688 !important;
}

.application .teal--text {
	color: #009688 !important;
	caret-color: #009688 !important;
}

.application .teal.lighten-5 {
	background-color: #e0f2f1 !important;
	border-color: #e0f2f1 !important;
}

.application .teal--text.text--lighten-5 {
	color: #e0f2f1 !important;
	caret-color: #e0f2f1 !important;
}

.application .teal.lighten-4 {
	background-color: #b2dfdb !important;
	border-color: #b2dfdb !important;
}

.application .teal--text.text--lighten-4 {
	color: #b2dfdb !important;
	caret-color: #b2dfdb !important;
}

.application .teal.lighten-3 {
	background-color: #80cbc4 !important;
	border-color: #80cbc4 !important;
}

.application .teal--text.text--lighten-3 {
	color: #80cbc4 !important;
	caret-color: #80cbc4 !important;
}

.application .teal.lighten-2 {
	background-color: #4db6ac !important;
	border-color: #4db6ac !important;
}

.application .teal--text.text--lighten-2 {
	color: #4db6ac !important;
	caret-color: #4db6ac !important;
}

.application .teal.lighten-1 {
	background-color: #26a69a !important;
	border-color: #26a69a !important;
}

.application .teal--text.text--lighten-1 {
	color: #26a69a !important;
	caret-color: #26a69a !important;
}

.application .teal.darken-1 {
	background-color: #00897b !important;
	border-color: #00897b !important;
}

.application .teal--text.text--darken-1 {
	color: #00897b !important;
	caret-color: #00897b !important;
}

.application .teal.darken-2 {
	background-color: #00796b !important;
	border-color: #00796b !important;
}

.application .teal--text.text--darken-2 {
	color: #00796b !important;
	caret-color: #00796b !important;
}

.application .teal.darken-3 {
	background-color: #00695c !important;
	border-color: #00695c !important;
}

.application .teal--text.text--darken-3 {
	color: #00695c !important;
	caret-color: #00695c !important;
}

.application .teal.darken-4 {
	background-color: #004d40 !important;
	border-color: #004d40 !important;
}

.application .teal--text.text--darken-4 {
	color: #004d40 !important;
	caret-color: #004d40 !important;
}

.application .teal.accent-1 {
	background-color: #a7ffeb !important;
	border-color: #a7ffeb !important;
}

.application .teal--text.text--accent-1 {
	color: #a7ffeb !important;
	caret-color: #a7ffeb !important;
}

.application .teal.accent-2 {
	background-color: #64ffda !important;
	border-color: #64ffda !important;
}

.application .teal--text.text--accent-2 {
	color: #64ffda !important;
	caret-color: #64ffda !important;
}

.application .teal.accent-3 {
	background-color: #1de9b6 !important;
	border-color: #1de9b6 !important;
}

.application .teal--text.text--accent-3 {
	color: #1de9b6 !important;
	caret-color: #1de9b6 !important;
}

.application .teal.accent-4 {
	background-color: #00bfa5 !important;
	border-color: #00bfa5 !important;
}

.application .teal--text.text--accent-4 {
	color: #00bfa5 !important;
	caret-color: #00bfa5 !important;
}

.application .green {
	background-color: #4caf50 !important;
	border-color: #4caf50 !important;
}

.application .green--text {
	color: #4caf50 !important;
	caret-color: #4caf50 !important;
}

.application .green.lighten-5 {
	background-color: #e8f5e9 !important;
	border-color: #e8f5e9 !important;
}

.application .green--text.text--lighten-5 {
	color: #e8f5e9 !important;
	caret-color: #e8f5e9 !important;
}

.application .green.lighten-4 {
	background-color: #c8e6c9 !important;
	border-color: #c8e6c9 !important;
}

.application .green--text.text--lighten-4 {
	color: #c8e6c9 !important;
	caret-color: #c8e6c9 !important;
}

.application .green.lighten-3 {
	background-color: #a5d6a7 !important;
	border-color: #a5d6a7 !important;
}

.application .green--text.text--lighten-3 {
	color: #a5d6a7 !important;
	caret-color: #a5d6a7 !important;
}

.application .green.lighten-2 {
	background-color: #81c784 !important;
	border-color: #81c784 !important;
}

.application .green--text.text--lighten-2 {
	color: #81c784 !important;
	caret-color: #81c784 !important;
}

.application .green.lighten-1 {
	background-color: #66bb6a !important;
	border-color: #66bb6a !important;
}

.application .green--text.text--lighten-1 {
	color: #66bb6a !important;
	caret-color: #66bb6a !important;
}

.application .green.darken-1 {
	background-color: #43a047 !important;
	border-color: #43a047 !important;
}

.application .green--text.text--darken-1 {
	color: #43a047 !important;
	caret-color: #43a047 !important;
}

.application .green.darken-2 {
	background-color: #388e3c !important;
	border-color: #388e3c !important;
}

.application .green--text.text--darken-2 {
	color: #388e3c !important;
	caret-color: #388e3c !important;
}

.application .green.darken-3 {
	background-color: #2e7d32 !important;
	border-color: #2e7d32 !important;
}

.application .green--text.text--darken-3 {
	color: #2e7d32 !important;
	caret-color: #2e7d32 !important;
}

.application .green.darken-4 {
	background-color: #1b5e20 !important;
	border-color: #1b5e20 !important;
}

.application .green--text.text--darken-4 {
	color: #1b5e20 !important;
	caret-color: #1b5e20 !important;
}

.application .green.accent-1 {
	background-color: #b9f6ca !important;
	border-color: #b9f6ca !important;
}

.application .green--text.text--accent-1 {
	color: #b9f6ca !important;
	caret-color: #b9f6ca !important;
}

.application .green.accent-2 {
	background-color: #69f0ae !important;
	border-color: #69f0ae !important;
}

.application .green--text.text--accent-2 {
	color: #69f0ae !important;
	caret-color: #69f0ae !important;
}

.application .green.accent-3 {
	background-color: #00e676 !important;
	border-color: #00e676 !important;
}

.application .green--text.text--accent-3 {
	color: #00e676 !important;
	caret-color: #00e676 !important;
}

.application .green.accent-4 {
	background-color: #00c853 !important;
	border-color: #00c853 !important;
}

.application .green--text.text--accent-4 {
	color: #00c853 !important;
	caret-color: #00c853 !important;
}

.application .light-green {
	background-color: #8bc34a !important;
	border-color: #8bc34a !important;
}

.application .light-green--text {
	color: #8bc34a !important;
	caret-color: #8bc34a !important;
}

.application .light-green.lighten-5 {
	background-color: #f1f8e9 !important;
	border-color: #f1f8e9 !important;
}

.application .light-green--text.text--lighten-5 {
	color: #f1f8e9 !important;
	caret-color: #f1f8e9 !important;
}

.application .light-green.lighten-4 {
	background-color: #dcedc8 !important;
	border-color: #dcedc8 !important;
}

.application .light-green--text.text--lighten-4 {
	color: #dcedc8 !important;
	caret-color: #dcedc8 !important;
}

.application .light-green.lighten-3 {
	background-color: #c5e1a5 !important;
	border-color: #c5e1a5 !important;
}

.application .light-green--text.text--lighten-3 {
	color: #c5e1a5 !important;
	caret-color: #c5e1a5 !important;
}

.application .light-green.lighten-2 {
	background-color: #aed581 !important;
	border-color: #aed581 !important;
}

.application .light-green--text.text--lighten-2 {
	color: #aed581 !important;
	caret-color: #aed581 !important;
}

.application .light-green.lighten-1 {
	background-color: #9ccc65 !important;
	border-color: #9ccc65 !important;
}

.application .light-green--text.text--lighten-1 {
	color: #9ccc65 !important;
	caret-color: #9ccc65 !important;
}

.application .light-green.darken-1 {
	background-color: #7cb342 !important;
	border-color: #7cb342 !important;
}

.application .light-green--text.text--darken-1 {
	color: #7cb342 !important;
	caret-color: #7cb342 !important;
}

.application .light-green.darken-2 {
	background-color: #689f38 !important;
	border-color: #689f38 !important;
}

.application .light-green--text.text--darken-2 {
	color: #689f38 !important;
	caret-color: #689f38 !important;
}

.application .light-green.darken-3 {
	background-color: #558b2f !important;
	border-color: #558b2f !important;
}

.application .light-green--text.text--darken-3 {
	color: #558b2f !important;
	caret-color: #558b2f !important;
}

.application .light-green.darken-4 {
	background-color: #33691e !important;
	border-color: #33691e !important;
}

.application .light-green--text.text--darken-4 {
	color: #33691e !important;
	caret-color: #33691e !important;
}

.application .light-green.accent-1 {
	background-color: #ccff90 !important;
	border-color: #ccff90 !important;
}

.application .light-green--text.text--accent-1 {
	color: #ccff90 !important;
	caret-color: #ccff90 !important;
}

.application .light-green.accent-2 {
	background-color: #b2ff59 !important;
	border-color: #b2ff59 !important;
}

.application .light-green--text.text--accent-2 {
	color: #b2ff59 !important;
	caret-color: #b2ff59 !important;
}

.application .light-green.accent-3 {
	background-color: #76ff03 !important;
	border-color: #76ff03 !important;
}

.application .light-green--text.text--accent-3 {
	color: #76ff03 !important;
	caret-color: #76ff03 !important;
}

.application .light-green.accent-4 {
	background-color: #64dd17 !important;
	border-color: #64dd17 !important;
}

.application .light-green--text.text--accent-4 {
	color: #64dd17 !important;
	caret-color: #64dd17 !important;
}

.application .lime {
	background-color: #cddc39 !important;
	border-color: #cddc39 !important;
}

.application .lime--text {
	color: #cddc39 !important;
	caret-color: #cddc39 !important;
}

.application .lime.lighten-5 {
	background-color: #f9fbe7 !important;
	border-color: #f9fbe7 !important;
}

.application .lime--text.text--lighten-5 {
	color: #f9fbe7 !important;
	caret-color: #f9fbe7 !important;
}

.application .lime.lighten-4 {
	background-color: #f0f4c3 !important;
	border-color: #f0f4c3 !important;
}

.application .lime--text.text--lighten-4 {
	color: #f0f4c3 !important;
	caret-color: #f0f4c3 !important;
}

.application .lime.lighten-3 {
	background-color: #e6ee9c !important;
	border-color: #e6ee9c !important;
}

.application .lime--text.text--lighten-3 {
	color: #e6ee9c !important;
	caret-color: #e6ee9c !important;
}

.application .lime.lighten-2 {
	background-color: #dce775 !important;
	border-color: #dce775 !important;
}

.application .lime--text.text--lighten-2 {
	color: #dce775 !important;
	caret-color: #dce775 !important;
}

.application .lime.lighten-1 {
	background-color: #d4e157 !important;
	border-color: #d4e157 !important;
}

.application .lime--text.text--lighten-1 {
	color: #d4e157 !important;
	caret-color: #d4e157 !important;
}

.application .lime.darken-1 {
	background-color: #c0ca33 !important;
	border-color: #c0ca33 !important;
}

.application .lime--text.text--darken-1 {
	color: #c0ca33 !important;
	caret-color: #c0ca33 !important;
}

.application .lime.darken-2 {
	background-color: #afb42b !important;
	border-color: #afb42b !important;
}

.application .lime--text.text--darken-2 {
	color: #afb42b !important;
	caret-color: #afb42b !important;
}

.application .lime.darken-3 {
	background-color: #9e9d24 !important;
	border-color: #9e9d24 !important;
}

.application .lime--text.text--darken-3 {
	color: #9e9d24 !important;
	caret-color: #9e9d24 !important;
}

.application .lime.darken-4 {
	background-color: #827717 !important;
	border-color: #827717 !important;
}

.application .lime--text.text--darken-4 {
	color: #827717 !important;
	caret-color: #827717 !important;
}

.application .lime.accent-1 {
	background-color: #f4ff81 !important;
	border-color: #f4ff81 !important;
}

.application .lime--text.text--accent-1 {
	color: #f4ff81 !important;
	caret-color: #f4ff81 !important;
}

.application .lime.accent-2 {
	background-color: #eeff41 !important;
	border-color: #eeff41 !important;
}

.application .lime--text.text--accent-2 {
	color: #eeff41 !important;
	caret-color: #eeff41 !important;
}

.application .lime.accent-3 {
	background-color: #c6ff00 !important;
	border-color: #c6ff00 !important;
}

.application .lime--text.text--accent-3 {
	color: #c6ff00 !important;
	caret-color: #c6ff00 !important;
}

.application .lime.accent-4 {
	background-color: #aeea00 !important;
	border-color: #aeea00 !important;
}

.application .lime--text.text--accent-4 {
	color: #aeea00 !important;
	caret-color: #aeea00 !important;
}

.application .yellow {
	background-color: #ffeb3b !important;
	border-color: #ffeb3b !important;
}

.application .yellow--text {
	color: #ffeb3b !important;
	caret-color: #ffeb3b !important;
}

.application .yellow.lighten-5 {
	background-color: #fffde7 !important;
	border-color: #fffde7 !important;
}

.application .yellow--text.text--lighten-5 {
	color: #fffde7 !important;
	caret-color: #fffde7 !important;
}

.application .yellow.lighten-4 {
	background-color: #fff9c4 !important;
	border-color: #fff9c4 !important;
}

.application .yellow--text.text--lighten-4 {
	color: #fff9c4 !important;
	caret-color: #fff9c4 !important;
}

.application .yellow.lighten-3 {
	background-color: #fff59d !important;
	border-color: #fff59d !important;
}

.application .yellow--text.text--lighten-3 {
	color: #fff59d !important;
	caret-color: #fff59d !important;
}

.application .yellow.lighten-2 {
	background-color: #fff176 !important;
	border-color: #fff176 !important;
}

.application .yellow--text.text--lighten-2 {
	color: #fff176 !important;
	caret-color: #fff176 !important;
}

.application .yellow.lighten-1 {
	background-color: #ffee58 !important;
	border-color: #ffee58 !important;
}

.application .yellow--text.text--lighten-1 {
	color: #ffee58 !important;
	caret-color: #ffee58 !important;
}

.application .yellow.darken-1 {
	background-color: #fdd835 !important;
	border-color: #fdd835 !important;
}

.application .yellow--text.text--darken-1 {
	color: #fdd835 !important;
	caret-color: #fdd835 !important;
}

.application .yellow.darken-2 {
	background-color: #fbc02d !important;
	border-color: #fbc02d !important;
}

.application .yellow--text.text--darken-2 {
	color: #fbc02d !important;
	caret-color: #fbc02d !important;
}

.application .yellow.darken-3 {
	background-color: #f9a825 !important;
	border-color: #f9a825 !important;
}

.application .yellow--text.text--darken-3 {
	color: #f9a825 !important;
	caret-color: #f9a825 !important;
}

.application .yellow.darken-4 {
	background-color: #f57f17 !important;
	border-color: #f57f17 !important;
}

.application .yellow--text.text--darken-4 {
	color: #f57f17 !important;
	caret-color: #f57f17 !important;
}

.application .yellow.accent-1 {
	background-color: #ffff8d !important;
	border-color: #ffff8d !important;
}

.application .yellow--text.text--accent-1 {
	color: #ffff8d !important;
	caret-color: #ffff8d !important;
}

.application .yellow.accent-2 {
	background-color: #ff0 !important;
	border-color: #ff0 !important;
}

.application .yellow--text.text--accent-2 {
	color: #ff0 !important;
	caret-color: #ff0 !important;
}

.application .yellow.accent-3 {
	background-color: #ffea00 !important;
	border-color: #ffea00 !important;
}

.application .yellow--text.text--accent-3 {
	color: #ffea00 !important;
	caret-color: #ffea00 !important;
}

.application .yellow.accent-4 {
	background-color: #ffd600 !important;
	border-color: #ffd600 !important;
}

.application .yellow--text.text--accent-4 {
	color: #ffd600 !important;
	caret-color: #ffd600 !important;
}

.application .amber {
	background-color: #ffc107 !important;
	border-color: #ffc107 !important;
}

.application .amber--text {
	color: #ffc107 !important;
	caret-color: #ffc107 !important;
}

.application .amber.lighten-5 {
	background-color: #fff8e1 !important;
	border-color: #fff8e1 !important;
}

.application .amber--text.text--lighten-5 {
	color: #fff8e1 !important;
	caret-color: #fff8e1 !important;
}

.application .amber.lighten-4 {
	background-color: #ffecb3 !important;
	border-color: #ffecb3 !important;
}

.application .amber--text.text--lighten-4 {
	color: #ffecb3 !important;
	caret-color: #ffecb3 !important;
}

.application .amber.lighten-3 {
	background-color: #ffe082 !important;
	border-color: #ffe082 !important;
}

.application .amber--text.text--lighten-3 {
	color: #ffe082 !important;
	caret-color: #ffe082 !important;
}

.application .amber.lighten-2 {
	background-color: #ffd54f !important;
	border-color: #ffd54f !important;
}

.application .amber--text.text--lighten-2 {
	color: #ffd54f !important;
	caret-color: #ffd54f !important;
}

.application .amber.lighten-1 {
	background-color: #ffca28 !important;
	border-color: #ffca28 !important;
}

.application .amber--text.text--lighten-1 {
	color: #ffca28 !important;
	caret-color: #ffca28 !important;
}

.application .amber.darken-1 {
	background-color: #ffb300 !important;
	border-color: #ffb300 !important;
}

.application .amber--text.text--darken-1 {
	color: #ffb300 !important;
	caret-color: #ffb300 !important;
}

.application .amber.darken-2 {
	background-color: #ffa000 !important;
	border-color: #ffa000 !important;
}

.application .amber--text.text--darken-2 {
	color: #ffa000 !important;
	caret-color: #ffa000 !important;
}

.application .amber.darken-3 {
	background-color: #ff8f00 !important;
	border-color: #ff8f00 !important;
}

.application .amber--text.text--darken-3 {
	color: #ff8f00 !important;
	caret-color: #ff8f00 !important;
}

.application .amber.darken-4 {
	background-color: #ff6f00 !important;
	border-color: #ff6f00 !important;
}

.application .amber--text.text--darken-4 {
	color: #ff6f00 !important;
	caret-color: #ff6f00 !important;
}

.application .amber.accent-1 {
	background-color: #ffe57f !important;
	border-color: #ffe57f !important;
}

.application .amber--text.text--accent-1 {
	color: #ffe57f !important;
	caret-color: #ffe57f !important;
}

.application .amber.accent-2 {
	background-color: #ffd740 !important;
	border-color: #ffd740 !important;
}

.application .amber--text.text--accent-2 {
	color: #ffd740 !important;
	caret-color: #ffd740 !important;
}

.application .amber.accent-3 {
	background-color: #ffc400 !important;
	border-color: #ffc400 !important;
}

.application .amber--text.text--accent-3 {
	color: #ffc400 !important;
	caret-color: #ffc400 !important;
}

.application .amber.accent-4 {
	background-color: #ffab00 !important;
	border-color: #ffab00 !important;
}

.application .amber--text.text--accent-4 {
	color: #ffab00 !important;
	caret-color: #ffab00 !important;
}

.application .orange {
	background-color: #ff9800 !important;
	border-color: #ff9800 !important;
}

.application .orange--text {
	color: #ff9800 !important;
	caret-color: #ff9800 !important;
}

.application .orange.lighten-5 {
	background-color: #fff3e0 !important;
	border-color: #fff3e0 !important;
}

.application .orange--text.text--lighten-5 {
	color: #fff3e0 !important;
	caret-color: #fff3e0 !important;
}

.application .orange.lighten-4 {
	background-color: #ffe0b2 !important;
	border-color: #ffe0b2 !important;
}

.application .orange--text.text--lighten-4 {
	color: #ffe0b2 !important;
	caret-color: #ffe0b2 !important;
}

.application .orange.lighten-3 {
	background-color: #ffcc80 !important;
	border-color: #ffcc80 !important;
}

.application .orange--text.text--lighten-3 {
	color: #ffcc80 !important;
	caret-color: #ffcc80 !important;
}

.application .orange.lighten-2 {
	background-color: #ffb74d !important;
	border-color: #ffb74d !important;
}

.application .orange--text.text--lighten-2 {
	color: #ffb74d !important;
	caret-color: #ffb74d !important;
}

.application .orange.lighten-1 {
	background-color: #ffa726 !important;
	border-color: #ffa726 !important;
}

.application .orange--text.text--lighten-1 {
	color: #ffa726 !important;
	caret-color: #ffa726 !important;
}

.application .orange.darken-1 {
	background-color: #fb8c00 !important;
	border-color: #fb8c00 !important;
}

.application .orange--text.text--darken-1 {
	color: #fb8c00 !important;
	caret-color: #fb8c00 !important;
}

.application .orange.darken-2 {
	background-color: #f57c00 !important;
	border-color: #f57c00 !important;
}

.application .orange--text.text--darken-2 {
	color: #f57c00 !important;
	caret-color: #f57c00 !important;
}

.application .orange.darken-3 {
	background-color: #ef6c00 !important;
	border-color: #ef6c00 !important;
}

.application .orange--text.text--darken-3 {
	color: #ef6c00 !important;
	caret-color: #ef6c00 !important;
}

.application .orange.darken-4 {
	background-color: #e65100 !important;
	border-color: #e65100 !important;
}

.application .orange--text.text--darken-4 {
	color: #e65100 !important;
	caret-color: #e65100 !important;
}

.application .orange.accent-1 {
	background-color: #ffd180 !important;
	border-color: #ffd180 !important;
}

.application .orange--text.text--accent-1 {
	color: #ffd180 !important;
	caret-color: #ffd180 !important;
}

.application .orange.accent-2 {
	background-color: #ffab40 !important;
	border-color: #ffab40 !important;
}

.application .orange--text.text--accent-2 {
	color: #ffab40 !important;
	caret-color: #ffab40 !important;
}

.application .orange.accent-3 {
	background-color: #ff9100 !important;
	border-color: #ff9100 !important;
}

.application .orange--text.text--accent-3 {
	color: #ff9100 !important;
	caret-color: #ff9100 !important;
}

.application .orange.accent-4 {
	background-color: #ff6d00 !important;
	border-color: #ff6d00 !important;
}

.application .orange--text.text--accent-4 {
	color: #ff6d00 !important;
	caret-color: #ff6d00 !important;
}

.application .deep-orange {
	background-color: #ff5722 !important;
	border-color: #ff5722 !important;
}

.application .deep-orange--text {
	color: #ff5722 !important;
	caret-color: #ff5722 !important;
}

.application .deep-orange.lighten-5 {
	background-color: #fbe9e7 !important;
	border-color: #fbe9e7 !important;
}

.application .deep-orange--text.text--lighten-5 {
	color: #fbe9e7 !important;
	caret-color: #fbe9e7 !important;
}

.application .deep-orange.lighten-4 {
	background-color: #ffccbc !important;
	border-color: #ffccbc !important;
}

.application .deep-orange--text.text--lighten-4 {
	color: #ffccbc !important;
	caret-color: #ffccbc !important;
}

.application .deep-orange.lighten-3 {
	background-color: #ffab91 !important;
	border-color: #ffab91 !important;
}

.application .deep-orange--text.text--lighten-3 {
	color: #ffab91 !important;
	caret-color: #ffab91 !important;
}

.application .deep-orange.lighten-2 {
	background-color: #ff8a65 !important;
	border-color: #ff8a65 !important;
}

.application .deep-orange--text.text--lighten-2 {
	color: #ff8a65 !important;
	caret-color: #ff8a65 !important;
}

.application .deep-orange.lighten-1 {
	background-color: #ff7043 !important;
	border-color: #ff7043 !important;
}

.application .deep-orange--text.text--lighten-1 {
	color: #ff7043 !important;
	caret-color: #ff7043 !important;
}

.application .deep-orange.darken-1 {
	background-color: #f4511e !important;
	border-color: #f4511e !important;
}

.application .deep-orange--text.text--darken-1 {
	color: #f4511e !important;
	caret-color: #f4511e !important;
}

.application .deep-orange.darken-2 {
	background-color: #e64a19 !important;
	border-color: #e64a19 !important;
}

.application .deep-orange--text.text--darken-2 {
	color: #e64a19 !important;
	caret-color: #e64a19 !important;
}

.application .deep-orange.darken-3 {
	background-color: #d84315 !important;
	border-color: #d84315 !important;
}

.application .deep-orange--text.text--darken-3 {
	color: #d84315 !important;
	caret-color: #d84315 !important;
}

.application .deep-orange.darken-4 {
	background-color: #bf360c !important;
	border-color: #bf360c !important;
}

.application .deep-orange--text.text--darken-4 {
	color: #bf360c !important;
	caret-color: #bf360c !important;
}

.application .deep-orange.accent-1 {
	background-color: #ff9e80 !important;
	border-color: #ff9e80 !important;
}

.application .deep-orange--text.text--accent-1 {
	color: #ff9e80 !important;
	caret-color: #ff9e80 !important;
}

.application .deep-orange.accent-2 {
	background-color: #ff6e40 !important;
	border-color: #ff6e40 !important;
}

.application .deep-orange--text.text--accent-2 {
	color: #ff6e40 !important;
	caret-color: #ff6e40 !important;
}

.application .deep-orange.accent-3 {
	background-color: #ff3d00 !important;
	border-color: #ff3d00 !important;
}

.application .deep-orange--text.text--accent-3 {
	color: #ff3d00 !important;
	caret-color: #ff3d00 !important;
}

.application .deep-orange.accent-4 {
	background-color: #dd2c00 !important;
	border-color: #dd2c00 !important;
}

.application .deep-orange--text.text--accent-4 {
	color: #dd2c00 !important;
	caret-color: #dd2c00 !important;
}

.application .brown {
	background-color: #795548 !important;
	border-color: #795548 !important;
}

.application .brown--text {
	color: #795548 !important;
	caret-color: #795548 !important;
}

.application .brown.lighten-5 {
	background-color: #efebe9 !important;
	border-color: #efebe9 !important;
}

.application .brown--text.text--lighten-5 {
	color: #efebe9 !important;
	caret-color: #efebe9 !important;
}

.application .brown.lighten-4 {
	background-color: #d7ccc8 !important;
	border-color: #d7ccc8 !important;
}

.application .brown--text.text--lighten-4 {
	color: #d7ccc8 !important;
	caret-color: #d7ccc8 !important;
}

.application .brown.lighten-3 {
	background-color: #bcaaa4 !important;
	border-color: #bcaaa4 !important;
}

.application .brown--text.text--lighten-3 {
	color: #bcaaa4 !important;
	caret-color: #bcaaa4 !important;
}

.application .brown.lighten-2 {
	background-color: #a1887f !important;
	border-color: #a1887f !important;
}

.application .brown--text.text--lighten-2 {
	color: #a1887f !important;
	caret-color: #a1887f !important;
}

.application .brown.lighten-1 {
	background-color: #8d6e63 !important;
	border-color: #8d6e63 !important;
}

.application .brown--text.text--lighten-1 {
	color: #8d6e63 !important;
	caret-color: #8d6e63 !important;
}

.application .brown.darken-1 {
	background-color: #6d4c41 !important;
	border-color: #6d4c41 !important;
}

.application .brown--text.text--darken-1 {
	color: #6d4c41 !important;
	caret-color: #6d4c41 !important;
}

.application .brown.darken-2 {
	background-color: #5d4037 !important;
	border-color: #5d4037 !important;
}

.application .brown--text.text--darken-2 {
	color: #5d4037 !important;
	caret-color: #5d4037 !important;
}

.application .brown.darken-3 {
	background-color: #4e342e !important;
	border-color: #4e342e !important;
}

.application .brown--text.text--darken-3 {
	color: #4e342e !important;
	caret-color: #4e342e !important;
}

.application .brown.darken-4 {
	background-color: #3e2723 !important;
	border-color: #3e2723 !important;
}

.application .brown--text.text--darken-4 {
	color: #3e2723 !important;
	caret-color: #3e2723 !important;
}

.application .blue-grey {
	background-color: #607d8b !important;
	border-color: #607d8b !important;
}

.application .blue-grey--text {
	color: #607d8b !important;
	caret-color: #607d8b !important;
}

.application .blue-grey.lighten-5 {
	background-color: #eceff1 !important;
	border-color: #eceff1 !important;
}

.application .blue-grey--text.text--lighten-5 {
	color: #eceff1 !important;
	caret-color: #eceff1 !important;
}

.application .blue-grey.lighten-4 {
	background-color: #cfd8dc !important;
	border-color: #cfd8dc !important;
}

.application .blue-grey--text.text--lighten-4 {
	color: #cfd8dc !important;
	caret-color: #cfd8dc !important;
}

.application .blue-grey.lighten-3 {
	background-color: #b0bec5 !important;
	border-color: #b0bec5 !important;
}

.application .blue-grey--text.text--lighten-3 {
	color: #b0bec5 !important;
	caret-color: #b0bec5 !important;
}

.application .blue-grey.lighten-2 {
	background-color: #90a4ae !important;
	border-color: #90a4ae !important;
}

.application .blue-grey--text.text--lighten-2 {
	color: #90a4ae !important;
	caret-color: #90a4ae !important;
}

.application .blue-grey.lighten-1 {
	background-color: #78909c !important;
	border-color: #78909c !important;
}

.application .blue-grey--text.text--lighten-1 {
	color: #78909c !important;
	caret-color: #78909c !important;
}

.application .blue-grey.darken-1 {
	background-color: #546e7a !important;
	border-color: #546e7a !important;
}

.application .blue-grey--text.text--darken-1 {
	color: #546e7a !important;
	caret-color: #546e7a !important;
}

.application .blue-grey.darken-2 {
	background-color: #455a64 !important;
	border-color: #455a64 !important;
}

.application .blue-grey--text.text--darken-2 {
	color: #455a64 !important;
	caret-color: #455a64 !important;
}

.application .blue-grey.darken-3 {
	background-color: #37474f !important;
	border-color: #37474f !important;
}

.application .blue-grey--text.text--darken-3 {
	color: #37474f !important;
	caret-color: #37474f !important;
}

.application .blue-grey.darken-4 {
	background-color: #263238 !important;
	border-color: #263238 !important;
}

.application .blue-grey--text.text--darken-4 {
	color: #263238 !important;
	caret-color: #263238 !important;
}

.application .grey {
	background-color: #9e9e9e !important;
	border-color: #9e9e9e !important;
}

.application .grey--text {
	color: #9e9e9e !important;
	caret-color: #9e9e9e !important;
}

.application .grey.lighten-5 {
	background-color: #fafafa !important;
	border-color: #fafafa !important;
}

.application .grey--text.text--lighten-5 {
	color: #fafafa !important;
	caret-color: #fafafa !important;
}

.application .grey.lighten-4 {
	background-color: #f5f5f5 !important;
	border-color: #f5f5f5 !important;
}

.application .grey--text.text--lighten-4 {
	color: #f5f5f5 !important;
	caret-color: #f5f5f5 !important;
}

.application .grey.lighten-3 {
	background-color: #eee !important;
	border-color: #eee !important;
}

.application .grey--text.text--lighten-3 {
	color: #eee !important;
	caret-color: #eee !important;
}

.application .grey.lighten-2 {
	background-color: #e0e0e0 !important;
	border-color: #e0e0e0 !important;
}

.application .grey--text.text--lighten-2 {
	color: #e0e0e0 !important;
	caret-color: #e0e0e0 !important;
}

.application .grey.lighten-1 {
	background-color: #bdbdbd !important;
	border-color: #bdbdbd !important;
}

.application .grey--text.text--lighten-1 {
	color: #bdbdbd !important;
	caret-color: #bdbdbd !important;
}

.application .grey.darken-1 {
	background-color: #757575 !important;
	border-color: #757575 !important;
}

.application .grey--text.text--darken-1 {
	color: #757575 !important;
	caret-color: #757575 !important;
}

.application .grey.darken-2 {
	background-color: #616161 !important;
	border-color: #616161 !important;
}

.application .grey--text.text--darken-2 {
	color: #616161 !important;
	caret-color: #616161 !important;
}

.application .grey.darken-3 {
	background-color: #424242 !important;
	border-color: #424242 !important;
}

.application .grey--text.text--darken-3 {
	color: #424242 !important;
	caret-color: #424242 !important;
}

.application .grey.darken-4 {
	background-color: #212121 !important;
	border-color: #212121 !important;
}

.application .grey--text.text--darken-4 {
	color: #212121 !important;
	caret-color: #212121 !important;
}

.application .shades.black {
	background-color: #000 !important;
	border-color: #000 !important;
}

.application .shades--text.text--black {
	color: #000 !important;
	caret-color: #000 !important;
}

.application .shades.white {
	background-color: #fff !important;
	border-color: #fff !important;
}

.application .shades--text.text--white {
	color: #fff !important;
	caret-color: #fff !important;
}

.application .shades.transparent {
	background-color: transparent !important;
	border-color: transparent !important;
}

.application .shades--text.text--transparent {
	color: transparent !important;
	caret-color: transparent !important;
}

.application .elevation-0 {
	-webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-1 {
	-webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-2 {
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-3 {
	-webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-4 {
	-webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-5 {
	-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-6 {
	-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-7 {
	-webkit-box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-8 {
	-webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-9 {
	-webkit-box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-10 {
	-webkit-box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-11 {
	-webkit-box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-12 {
	-webkit-box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-13 {
	-webkit-box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-14 {
	-webkit-box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-15 {
	-webkit-box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-16 {
	-webkit-box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-17 {
	-webkit-box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-18 {
	-webkit-box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-19 {
	-webkit-box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-20 {
	-webkit-box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-21 {
	-webkit-box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-22 {
	-webkit-box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-23 {
	-webkit-box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12) !important;
}

.application .elevation-24 {
	-webkit-box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
	        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
}

.application html {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	overflow-y: scroll;
    /* All browsers without overlaying scrollbars */
	-webkit-text-size-adjust: 100%;
    /* iOS 8+ */;
}

.application *,
  .application ::before,
  .application ::after {
	-webkit-box-sizing: inherit;
	        box-sizing: inherit;
}

.application ::before,
  .application ::after {
	text-decoration: inherit;
    /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
	vertical-align: inherit;
}

.application * {
	background-repeat: no-repeat;
    /* Set `background-repeat: no-repeat` to all elements */
	padding: 0;
    /* Reset `padding` and `margin` of all elements */
	margin: 0;
}

.application audio:not([controls]) {
	display: none;
	height: 0;
}

.application hr {
	overflow: visible;
    /* Show the overflow in Edge and IE */;
}

.application article,
  .application aside,
  .application details,
  .application figcaption,
  .application figure,
  .application footer,
  .application header,
  .application main,
  .application menu,
  .application nav,
  .application section,
  .application summary {
	display: block;
}

.application summary {
	display: list-item;
    /* Add the correct display in all browsers */;
}

.application small {
	font-size: 80%;
    /* Set font-size to 80% in `small` elements */;
}

.application [hidden],
  .application template {
	display: none;
    /* Add the correct display in IE */;
}

.application abbr[title] {
	border-bottom: 1px dotted;
    /* Add a bordered underline effect in all browsers */
	text-decoration: none;
    /* Remove text decoration in Firefox 40+ */;
}

.application a {
	background-color: transparent;
    /* Remove the gray background on active links in IE 10 */
	-webkit-text-decoration-skip: objects;
    /* Remove gaps in links underline in iOS 8+ and Safari 8+ */;
}

.application a:active,
  .application a:hover {
	outline-width: 0;
    /* Remove the outline when hovering in all browsers */;
}

.application code,
  .application kbd,
  .application pre,
  .application samp {
	font-family: monospace, monospace;
    /* Specify the font family of code elements */;
}

.application b,
  .application strong {
	font-weight: bolder;
    /* Correct style set to `bold` in Edge 12+, Safari 6.2+, and Chrome 18+ */;
}

.application dfn {
	font-style: italic;
    /* Address styling not present in Safari and Chrome */;
}

.application mark {
	background-color: #ff0;
	color: #000;
}

.application sub,
  .application sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

.application sub {
	bottom: -0.25em;
}

.application sup {
	top: -0.5em;
}

.application input {
	border-radius: 0;
}

.application button,
  .application [type="button"],
  .application [type="reset"],
  .application [type="submit"],
  .application [role="button"] {
	cursor: pointer;
}

.application [disabled] {
	cursor: default;
}

.application [type="number"] {
	width: auto;
    /* Firefox 36+ */;
}

.application [type="search"] {
	-webkit-appearance: textfield;
    /* Safari 8+ */;
}

.application [type="search"]::-webkit-search-cancel-button,
  .application [type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
    /* Safari 8 */;
}

.application textarea {
	overflow: auto;
    /* Internet Explorer 11+ */
	resize: vertical;
    /* Specify textarea resizability */;
}

.application button,
  .application input,
  .application optgroup,
  .application select,
  .application textarea {
	font: inherit;
    /* Specify font inheritance of form elements */;
}

.application optgroup {
	font-weight: bold;
    /* Restore the font weight unset by the previous rule. */;
}

.application button {
	overflow: visible;
    /* Address `overflow` set to `hidden` in IE 8/9/10/11 */;
}

.application button::-moz-focus-inner,
  .application [type="button"]::-moz-focus-inner,
  .application [type="reset"]::-moz-focus-inner,
  .application [type="submit"]::-moz-focus-inner {
	border-style: 0;
	padding: 0;
}

.application button:-moz-focusring,
  .application [type="button"]::-moz-focus-inner,
  .application [type="reset"]::-moz-focus-inner,
  .application [type="submit"]::-moz-focus-inner {
	outline: 0;
	border: 0;
}

.application button,
  .application html [type="button"],
  .application [type="reset"],
  .application [type="submit"] {
	-webkit-appearance: button;
    /* Correct the inability to style clickable types in iOS */;
}

.application button,
  .application select {
	text-transform: none;
    /* Firefox 40+, Internet Explorer 11- */;
}

.application button,
  .application input,
  .application select,
  .application textarea {
	background-color: transparent;
	border-style: none;
	color: inherit;
}

.application select {
	-moz-appearance: none;
    /* Firefox 36+ */
	-webkit-appearance: none;
    /* Chrome 41+ */;
}

.application select::-ms-expand {
	display: none;
    /* Internet Explorer 11+ */;
}

.application select::-ms-value {
	color: currentColor;
    /* Internet Explorer 11+ */;
}

.application legend {
	border: 0;
    /* Correct `color` not being inherited in IE 8/9/10/11 */
	color: inherit;
    /* Correct the color inheritance from `fieldset` elements in IE */
	display: table;
    /* Correct the text wrapping in Edge and IE */
	max-width: 100%;
    /* Correct the text wrapping in Edge and IE */
	white-space: normal;
    /* Correct the text wrapping in Edge and IE */;
}

.application ::-webkit-file-upload-button {
	-webkit-appearance: button;
    /* Correct the inability to style clickable types in iOS and Safari */
	font: inherit;
    /* Change font properties to `inherit` in Chrome and Safari */;
}

.application [type="search"] {
	-webkit-appearance: textfield;
    /* Correct the odd appearance in Chrome and Safari */
	outline-offset: -2px;
    /* Correct the outline style in Safari */;
}

.application img {
	border-style: none;
    /* Remove border when inside `a` element in IE 8/9/10 */;
}

.application progress {
	vertical-align: baseline;
}

.application svg:not(:root) {
	overflow: hidden;
    /* Internet Explorer 11- */;
}

.application audio,
  .application canvas,
  .application progress,
  .application video {
	display: inline-block;
    /* Internet Explorer 11+, Windows Phone 8.1+ */;
}

.application [aria-busy="true"] {
	cursor: progress;
}

.application [aria-controls] {
	cursor: pointer;
}

.application [aria-disabled] {
	cursor: default;
}

.application ::selection {
	background-color: #b3d4fc;
    /* Required when declaring ::selection */
	color: #000;
	text-shadow: none;
}

.application .bottom-sheet-transition-enter {
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}

.application .bottom-sheet-transition-leave-to {
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}

.application .carousel-transition-enter {
	-webkit-transform: translate(100%, 0);
	transform: translate(100%, 0);
}

.application .carousel-transition-leave,
  .application .carousel-transition-leave-to {
	position: absolute;
	top: 0;
	-webkit-transform: translate(-100%, 0);
	transform: translate(-100%, 0);
}

.application .carousel-reverse-transition-enter {
	-webkit-transform: translate(-100%, 0);
	transform: translate(-100%, 0);
}

.application .carousel-reverse-transition-leave,
  .application .carousel-reverse-transition-leave-to {
	position: absolute;
	top: 0;
	-webkit-transform: translate(100%, 0);
	transform: translate(100%, 0);
}

.application .dialog-transition-enter,
  .application .dialog-transition-leave-to {
	-webkit-transform: scale(0.5);
	transform: scale(0.5);
	opacity: 0;
}

.application .dialog-transition-enter-to,
  .application .dialog-transition-leave {
	opacity: 1;
}

.application .dialog-bottom-transition-enter,
  .application .dialog-bottom-transition-leave-to {
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}

.application .picker-transition-enter-active,
  .application .picker-reverse-transition-enter-active,
  .application .picker-transition-leave-active,
  .application .picker-reverse-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
	transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.application .picker-transition-enter,
  .application .picker-reverse-transition-enter,
  .application .picker-transition-leave-to,
  .application .picker-reverse-transition-leave-to {
	opacity: 0;
}

.application .picker-transition-leave,
  .application .picker-reverse-transition-leave,
  .application .picker-transition-leave-active,
  .application .picker-reverse-transition-leave-active,
  .application .picker-transition-leave-to,
  .application .picker-reverse-transition-leave-to {
	position: absolute !important;
}

.application .picker-transition-enter {
	-webkit-transform: translate(0, 100%);
	transform: translate(0, 100%);
}

.application .picker-transition-leave-to {
	-webkit-transform: translate(0, -100%);
	transform: translate(0, -100%);
}

.application .picker-reverse-transition-enter {
	-webkit-transform: translate(0, -100%);
	transform: translate(0, -100%);
}

.application .picker-reverse-transition-leave-to {
	-webkit-transform: translate(0, 100%);
	transform: translate(0, 100%);
}

.application .picker-title-transition-enter-to,
  .application .picker-title-transition-leave {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}

.application .picker-title-transition-enter {
	-webkit-transform: translate(-100%, 0);
	transform: translate(-100%, 0);
}

.application .picker-title-transition-leave-to {
	opacity: 0;
	-webkit-transform: translate(100%, 0);
	transform: translate(100%, 0);
}

.application .picker-title-transition-leave,
  .application .picker-title-transition-leave-to,
  .application .picker-title-transition-leave-active {
	position: absolute !important;
}

.application .tab-transition-enter {
	-webkit-transform: translate(100%, 0);
	transform: translate(100%, 0);
}

.application .tab-transition-leave,
  .application .tab-transition-leave-active {
	position: absolute;
	top: 0;
}

.application .tab-transition-leave-to {
	position: absolute;
	-webkit-transform: translate(-100%, 0);
	transform: translate(-100%, 0);
}

.application .tab-reverse-transition-enter {
	-webkit-transform: translate(-100%, 0);
	transform: translate(-100%, 0);
}

.application .tab-reverse-transition-leave,
  .application .tab-reverse-transition-leave-to {
	top: 0;
	position: absolute;
	-webkit-transform: translate(100%, 0);
	transform: translate(100%, 0);
}

.application .expand-transition-enter-active,
  .application .expand-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .expand-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .scale-transition-enter-active,
  .application .scale-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .scale-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .scale-transition-enter,
  .application .scale-transition-leave,
  .application .scale-transition-leave-to {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.application .message-transition-enter-active,
  .application .message-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .message-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .message-transition-enter,
  .application .message-transition-leave-to {
	opacity: 0;
	-webkit-transform: translateY(-15px);
	transform: translateY(-15px);
}

.application .message-transition-leave,
  .application .message-transition-leave-active {
	position: absolute;
}

.application .slide-y-transition-enter-active,
  .application .slide-y-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .slide-y-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .slide-y-transition-enter,
  .application .slide-y-transition-leave-to {
	opacity: 0;
	-webkit-transform: translateY(-15px);
	transform: translateY(-15px);
}

.application .slide-y-reverse-transition-enter-active,
  .application .slide-y-reverse-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .slide-y-reverse-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .slide-y-reverse-transition-enter,
  .application .slide-y-reverse-transition-leave-to {
	opacity: 0;
	-webkit-transform: translateY(15px);
	transform: translateY(15px);
}

.application .scroll-y-transition-enter-active,
  .application .scroll-y-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .scroll-y-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .scroll-y-transition-enter,
  .application .scroll-y-transition-leave-to {
	opacity: 0;
}

.application .scroll-y-transition-enter {
	-webkit-transform: translateY(-15px);
	transform: translateY(-15px);
}

.application .scroll-y-transition-leave-to {
	-webkit-transform: translateY(15px);
	transform: translateY(15px);
}

.application .scroll-y-reverse-transition-enter-active,
  .application .scroll-y-reverse-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .scroll-y-reverse-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .scroll-y-reverse-transition-enter,
  .application .scroll-y-reverse-transition-leave-to {
	opacity: 0;
}

.application .scroll-y-reverse-transition-enter {
	-webkit-transform: translateY(15px);
	transform: translateY(15px);
}

.application .scroll-y-reverse-transition-leave-to {
	-webkit-transform: translateY(-15px);
	transform: translateY(-15px);
}

.application .scroll-x-transition-enter-active,
  .application .scroll-x-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .scroll-x-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .scroll-x-transition-enter,
  .application .scroll-x-transition-leave-to {
	opacity: 0;
}

.application .scroll-x-transition-enter {
	-webkit-transform: translateX(-15px);
	transform: translateX(-15px);
}

.application .scroll-x-transition-leave-to {
	-webkit-transform: translateX(15px);
	transform: translateX(15px);
}

.application .scroll-x-reverse-transition-enter-active,
  .application .scroll-x-reverse-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .scroll-x-reverse-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .scroll-x-reverse-transition-enter,
  .application .scroll-x-reverse-transition-leave-to {
	opacity: 0;
}

.application .scroll-x-reverse-transition-enter {
	-webkit-transform: translateX(15px);
	transform: translateX(15px);
}

.application .scroll-x-reverse-transition-leave-to {
	-webkit-transform: translateX(-15px);
	transform: translateX(-15px);
}

.application .slide-x-transition-enter-active,
  .application .slide-x-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .slide-x-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .slide-x-transition-enter,
  .application .slide-x-transition-leave-to {
	opacity: 0;
	-webkit-transform: translateX(-15px);
	transform: translateX(-15px);
}

.application .slide-x-reverse-transition-enter-active,
  .application .slide-x-reverse-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .slide-x-reverse-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .slide-x-reverse-transition-enter,
  .application .slide-x-reverse-transition-leave-to {
	opacity: 0;
	-webkit-transform: translateX(15px);
	transform: translateX(15px);
}

.application .fade-transition-enter-active,
  .application .fade-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .fade-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .fade-transition-enter,
  .application .fade-transition-leave-to {
	opacity: 0;
}

.application .fab-transition-enter-active,
  .application .fab-transition-leave-active {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .fab-transition-move {
	transition: -webkit-transform 0.6s;
	-webkit-transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
}

.application .fab-transition-enter,
  .application .fab-transition-leave-to {
	-webkit-transform: scale(0) rotate(-45deg);
	transform: scale(0) rotate(-45deg);
}

.application .blockquote {
	padding: 16px 0 16px 24px;
	font-size: 18px;
	font-weight: 300;
}

.application code,
  .application kbd {
	display: inline-block;
	border-radius: 3px;
	white-space: pre-wrap;
	font-size: 85%;
	font-weight: 900;
}

.application code:after,
  .application kbd:after,
  .application code:before,
  .application kbd:before {
	content: "\A0";
	letter-spacing: -1px;
}

.application code {
	background-color: #f5f5f5;
	color: #bd4147;
	-webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.application kbd {
	background: #424242;
	color: #fff;
}

.application html {
	font-size: 14px;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
}

.application .application {
	font-family: 'Roboto', sans-serif;
	line-height: 1.5;
}

.application ::-ms-clear,
  .application ::-ms-reveal {
	display: none;
}

.application ul,
  .application ol {
	padding-left: 24px;
}

.application .display-4 {
	font-size: 112px !important;
	font-weight: 300;
	line-height: 1 !important;
	letter-spacing: -0.04em !important;
	font-family: 'Roboto', sans-serif !important;
}

.application .display-3 {
	font-size: 56px !important;
	font-weight: 400;
	line-height: 1.35 !important;
	letter-spacing: -0.02em !important;
	font-family: 'Roboto', sans-serif !important;
}

.application .display-2 {
	font-size: 45px !important;
	font-weight: 400;
	line-height: 48px !important;
	letter-spacing: normal !important;
	font-family: 'Roboto', sans-serif !important;
}

.application .display-1 {
	font-size: 34px !important;
	font-weight: 400;
	line-height: 40px !important;
	letter-spacing: normal !important;
	font-family: 'Roboto', sans-serif !important;
}

.application .headline {
	font-size: 24px !important;
	font-weight: 400;
	line-height: 32px !important;
	letter-spacing: normal !important;
	font-family: 'Roboto', sans-serif !important;
}

.application .title {
	font-size: 20px !important;
	font-weight: 500;
	line-height: 1 !important;
	letter-spacing: 0.02em !important;
	font-family: 'Roboto', sans-serif !important;
}

.application .subheading {
	font-size: 16px !important;
	font-weight: 400;
}

.application .body-2 {
	font-size: 14px !important;
	font-weight: 500;
}

.application .body-1 {
	font-size: 14px !important;
	font-weight: 400;
}

.application .caption {
	font-size: 12px !important;
	font-weight: 400;
}

.application .v-btn {
	font-size: 14px;
	font-weight: 500;
}

.application p {
	margin-bottom: 16px;
}

.application .overflow-hidden {
	overflow: hidden;
}

.application .overflow-x-hidden {
	overflow-x: hidden;
}

.application .overflow-y-hidden {
	overflow-y: hidden;
}

.application .right {
	float: right !important;
}

.application .left {
	float: left !important;
}

.application .ma-auto {
	margin-top: auto !important;
	margin-right: auto !important;
	margin-bottom: auto !important;
	margin-left: auto !important;
}

.application .my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.application .mx-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

.application .mt-auto {
	margin-top: auto;
}

.application .mr-auto {
	margin-right: auto;
}

.application .mb-auto {
	margin-bottom: auto;
}

.application .ml-auto {
	margin-left: auto;
}

.application .ma-0 {
	margin: 0 0 !important;
}

.application .my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.application .mx-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.application .mt-0 {
	margin-top: 0 !important;
}

.application .mr-0 {
	margin-right: 0 !important;
}

.application .mb-0 {
	margin-bottom: 0 !important;
}

.application .ml-0 {
	margin-left: 0 !important;
}

.application .pa-0 {
	padding: 0 0 !important;
}

.application .py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.application .px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.application .pt-0 {
	padding-top: 0 !important;
}

.application .pr-0 {
	padding-right: 0 !important;
}

.application .pb-0 {
	padding-bottom: 0 !important;
}

.application .pl-0 {
	padding-left: 0 !important;
}

.application .ma-1 {
	margin: 4px 4px !important;
}

.application .my-1 {
	margin-top: 4px !important;
	margin-bottom: 4px !important;
}

.application .mx-1 {
	margin-left: 4px !important;
	margin-right: 4px !important;
}

.application .mt-1 {
	margin-top: 4px !important;
}

.application .mr-1 {
	margin-right: 4px !important;
}

.application .mb-1 {
	margin-bottom: 4px !important;
}

.application .ml-1 {
	margin-left: 4px !important;
}

.application .pa-1 {
	padding: 4px 4px !important;
}

.application .py-1 {
	padding-top: 4px !important;
	padding-bottom: 4px !important;
}

.application .px-1 {
	padding-left: 4px !important;
	padding-right: 4px !important;
}

.application .pt-1 {
	padding-top: 4px !important;
}

.application .pr-1 {
	padding-right: 4px !important;
}

.application .pb-1 {
	padding-bottom: 4px !important;
}

.application .pl-1 {
	padding-left: 4px !important;
}

.application .ma-2 {
	margin: 8px 8px !important;
}

.application .my-2 {
	margin-top: 8px !important;
	margin-bottom: 8px !important;
}

.application .mx-2 {
	margin-left: 8px !important;
	margin-right: 8px !important;
}

.application .mt-2 {
	margin-top: 8px !important;
}

.application .mr-2 {
	margin-right: 8px !important;
}

.application .mb-2 {
	margin-bottom: 8px !important;
}

.application .ml-2 {
	margin-left: 8px !important;
}

.application .pa-2 {
	padding: 8px 8px !important;
}

.application .py-2 {
	padding-top: 8px !important;
	padding-bottom: 8px !important;
}

.application .px-2 {
	padding-left: 8px !important;
	padding-right: 8px !important;
}

.application .pt-2 {
	padding-top: 8px !important;
}

.application .pr-2 {
	padding-right: 8px !important;
}

.application .pb-2 {
	padding-bottom: 8px !important;
}

.application .pl-2 {
	padding-left: 8px !important;
}

.application .ma-3 {
	margin: 16px 16px !important;
}

.application .my-3 {
	margin-top: 16px !important;
	margin-bottom: 16px !important;
}

.application .mx-3 {
	margin-left: 16px !important;
	margin-right: 16px !important;
}

.application .mt-3 {
	margin-top: 16px !important;
}

.application .mr-3 {
	margin-right: 16px !important;
}

.application .mb-3 {
	margin-bottom: 16px !important;
}

.application .ml-3 {
	margin-left: 16px !important;
}

.application .pa-3 {
	padding: 16px 16px !important;
}

.application .py-3 {
	padding-top: 16px !important;
	padding-bottom: 16px !important;
}

.application .px-3 {
	padding-left: 16px !important;
	padding-right: 16px !important;
}

.application .pt-3 {
	padding-top: 16px !important;
}

.application .pr-3 {
	padding-right: 16px !important;
}

.application .pb-3 {
	padding-bottom: 16px !important;
}

.application .pl-3 {
	padding-left: 16px !important;
}

.application .ma-4 {
	margin: 24px 24px !important;
}

.application .my-4 {
	margin-top: 24px !important;
	margin-bottom: 24px !important;
}

.application .mx-4 {
	margin-left: 24px !important;
	margin-right: 24px !important;
}

.application .mt-4 {
	margin-top: 24px !important;
}

.application .mr-4 {
	margin-right: 24px !important;
}

.application .mb-4 {
	margin-bottom: 24px !important;
}

.application .ml-4 {
	margin-left: 24px !important;
}

.application .pa-4 {
	padding: 24px 24px !important;
}

.application .py-4 {
	padding-top: 24px !important;
	padding-bottom: 24px !important;
}

.application .px-4 {
	padding-left: 24px !important;
	padding-right: 24px !important;
}

.application .pt-4 {
	padding-top: 24px !important;
}

.application .pr-4 {
	padding-right: 24px !important;
}

.application .pb-4 {
	padding-bottom: 24px !important;
}

.application .pl-4 {
	padding-left: 24px !important;
}

.application .ma-5 {
	margin: 48px 48px !important;
}

.application .my-5 {
	margin-top: 48px !important;
	margin-bottom: 48px !important;
}

.application .mx-5 {
	margin-left: 48px !important;
	margin-right: 48px !important;
}

.application .mt-5 {
	margin-top: 48px !important;
}

.application .mr-5 {
	margin-right: 48px !important;
}

.application .mb-5 {
	margin-bottom: 48px !important;
}

.application .ml-5 {
	margin-left: 48px !important;
}

.application .pa-5 {
	padding: 48px 48px !important;
}

.application .py-5 {
	padding-top: 48px !important;
	padding-bottom: 48px !important;
}

.application .px-5 {
	padding-left: 48px !important;
	padding-right: 48px !important;
}

.application .pt-5 {
	padding-top: 48px !important;
}

.application .pr-5 {
	padding-right: 48px !important;
}

.application .pb-5 {
	padding-bottom: 48px !important;
}

.application .pl-5 {
	padding-left: 48px !important;
}

.application .font-weight-thin {
	font-weight: 100 !important;
}

.application .font-weight-light {
	font-weight: 300 !important;
}

.application .font-weight-regular {
	font-weight: 400 !important;
}

.application .font-weight-medium {
	font-weight: 500 !important;
}

.application .font-weight-bold {
	font-weight: 700 !important;
}

.application .font-weight-black {
	font-weight: 900 !important;
}

.application .font-italic {
	font-style: italic !important;
}

.application .text-capitalize {
	text-transform: capitalize !important;
}

.application .text-lowercase {
	text-transform: lowercase !important;
}

.application .text-uppercase {
	text-transform: uppercase !important;
}

.application .text-no-wrap {
	white-space: nowrap !important;
}

.application .text-truncate {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.application .transition-fast-out-slow-in {
	-webkit-transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
	transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.application .transition-linear-out-slow-in {
	-webkit-transition: 0.3s cubic-bezier(0, 0, 0.2, 1) !important;
	transition: 0.3s cubic-bezier(0, 0, 0.2, 1) !important;
}

.application .transition-fast-out-linear-in {
	-webkit-transition: 0.3s cubic-bezier(0.4, 0, 1, 1) !important;
	transition: 0.3s cubic-bezier(0.4, 0, 1, 1) !important;
}

.application .transition-ease-in-out {
	-webkit-transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1) !important;
	transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1) !important;
}

.application .transition-fast-in-fast-out {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}

.application .transition-swing {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
}

@media screen {
	.application [hidden~="screen"] {
		display: inherit;
	}

	.application [hidden~="screen"]:not(:active):not(:focus):not(:target) {
		position: absolute !important;
		clip: rect(0 0 0 0) !important;
	}
}

@media only screen and (max-width: 599px) {
	.application .hidden-xs-only {
		display: none !important;
	}
}

@media only screen and (min-width: 600px) and (max-width: 959px) {
	.application .hidden-sm-only {
		display: none !important;
	}
}

@media only screen and (max-width: 959px) {
	.application .hidden-sm-and-down {
		display: none !important;
	}
}

@media only screen and (min-width: 600px) {
	.application .hidden-sm-and-up {
		display: none !important;
	}
}

@media only screen and (min-width: 960px) and (max-width: 1263px) {
	.application .hidden-md-only {
		display: none !important;
	}
}

@media only screen and (max-width: 1263px) {
	.application .hidden-md-and-down {
		display: none !important;
	}
}

@media only screen and (min-width: 960px) {
	.application .hidden-md-and-up {
		display: none !important;
	}
}

@media only screen and (min-width: 1264px) and (max-width: 1903px) {
	.application .hidden-lg-only {
		display: none !important;
	}
}

@media only screen and (max-width: 1903px) {
	.application .hidden-lg-and-down {
		display: none !important;
	}
}

@media only screen and (min-width: 1264px) {
	.application .hidden-lg-and-up {
		display: none !important;
	}
}

@media only screen and (min-width: 1904px) {
	.application .hidden-xl-only {
		display: none !important;
	}
}

@media all and (min-width: 0) {
	.application .text-xs-left {
		text-align: left !important;
	}

	.application .text-xs-center {
		text-align: center !important;
	}

	.application .text-xs-right {
		text-align: right !important;
	}

	.application .text-xs-justify {
		text-align: justify !important;
	}
}

@media all and (min-width: 600px) {
	.application .text-sm-left {
		text-align: left !important;
	}

	.application .text-sm-center {
		text-align: center !important;
	}

	.application .text-sm-right {
		text-align: right !important;
	}

	.application .text-sm-justify {
		text-align: justify !important;
	}
}

@media all and (min-width: 960px) {
	.application .text-md-left {
		text-align: left !important;
	}

	.application .text-md-center {
		text-align: center !important;
	}

	.application .text-md-right {
		text-align: right !important;
	}

	.application .text-md-justify {
		text-align: justify !important;
	}
}

@media all and (min-width: 1264px) {
	.application .text-lg-left {
		text-align: left !important;
	}

	.application .text-lg-center {
		text-align: center !important;
	}

	.application .text-lg-right {
		text-align: right !important;
	}

	.application .text-lg-justify {
		text-align: justify !important;
	}
}

@media all and (min-width: 1904px) {
	.application .text-xl-left {
		text-align: left !important;
	}

	.application .text-xl-center {
		text-align: center !important;
	}

	.application .text-xl-right {
		text-align: right !important;
	}

	.application .text-xl-justify {
		text-align: justify !important;
	}
}

.application .application {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.application .application a {
	cursor: pointer;
}

.application .application--is-rtl {
	direction: rtl;
}

.application .application--wrap {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	min-height: 100vh;
	max-width: 100%;
	position: relative;
}

.application .theme--light.application {
	background: #fafafa;
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.application .text--primary {
	color: rgba(0, 0, 0, 0.87) !important;
}

.application .theme--light.application .text--secondary {
	color: rgba(0, 0, 0, 0.54) !important;
}

.application .theme--light.application .text--disabled {
	color: rgba(0, 0, 0, 0.38) !important;
}

.application .theme--dark.application {
	background: #303030;
	color: #fff;
}

.application .theme--dark.application .text--primary {
	color: #fff !important;
}

.application .theme--dark.application .text--secondary {
	color: rgba(255, 255, 255, 0.7) !important;
}

.application .theme--dark.application .text--disabled {
	color: rgba(255, 255, 255, 0.5) !important;
}

.application .v-alert {
	border-radius: 0;
	border-width: 4px 0 0 0;
	border-style: solid;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 14px;
	margin: 4px auto;
	padding: 16px;
	position: relative;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-alert .v-alert__icon.v-icon,
  .application .v-alert__dismissible .v-icon {
	-ms-flex-item-align: center;
	    align-self: center;
	color: rgba(0, 0, 0, 0.3);
	font-size: 24px;
}

.application .v-alert--outline .v-icon {
	color: inherit !important;
}

.application .v-alert__icon {
	margin-right: 16px;
}

.application .v-alert__dismissible {
	-ms-flex-item-align: start;
	    align-self: flex-start;
	color: inherit;
	margin-left: 16px;
	margin-right: 0;
	text-decoration: none;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-alert__dismissible:hover {
	opacity: 0.8;
}

.application .v-alert--no-icon .v-alert__icon {
	display: none;
}

.application .v-alert > div {
	-ms-flex-item-align: center;
	    align-self: center;
	-webkit-box-flex: 1;
	    -ms-flex: 1 1;
	        flex: 1 1;
}

.application .v-alert.v-alert {
	border-color: rgba(0, 0, 0, 0.12) !important;
}

.application .v-alert.v-alert--outline {
	border: 1px solid currentColor !important;
}

@media screen and (max-width: 600px) {
	.application .v-alert__icon {
		display: none;
	}
}

.application .theme--light.v-icon {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-icon.v-icon--disabled {
	color: rgba(0, 0, 0, 0.38) !important;
}

.application .theme--dark.v-icon {
	color: #fff;
}

.application .theme--dark.v-icon.v-icon--disabled {
	color: rgba(255, 255, 255, 0.5) !important;
}

.application .v-icon {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-font-feature-settings: 'liga';
	font-feature-settings: 'liga';
	font-size: 24px;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	line-height: 1;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	vertical-align: text-bottom;
}

.application .v-icon.v-icon.v-icon--link {
	cursor: pointer;
}

.application .v-icon.v-icon--large {
	font-size: 2.5rem;
}

.application .v-icon.v-icon--medium {
	font-size: 2rem;
}

.application .v-icon.v-icon--x-large {
	font-size: 3rem;
}

.application .v-icon.v-icon--disabled {
	pointer-events: none;
}

.application .v-autocomplete.v-input > .v-input__control > .v-input__slot {
	cursor: text;
}

.application .v-autocomplete input {
	-ms-flex-item-align: center;
	    align-self: center;
}

.application .v-autocomplete--is-selecting-index input {
	opacity: 0;
}

.application .v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line) .v-select__slot > input {
	margin-top: 24px;
}

.application .v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
	pointer-events: auto;
}

.application .v-autocomplete__content.v-menu__content {
	border-radius: 0;
}

.application .v-autocomplete__content.v-menu__content .v-card {
	border-radius: 0;
}

.application .theme--light.v-text-field .v-input__slot:before {
	border-color: rgba(0, 0, 0, 0.42);
}

.application .theme--light.v-text-field:not(.v-input--has-state) .v-input__slot:hover:before {
	border-color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
	-o-border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
	   border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
}

.application .theme--light.v-text-field__prefix,
  .application .theme--light.v-text-field__suffix {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-text-field--solo .v-input__slot {
	border-radius: 2px;
	background: #fff;
}

.application .theme--light.v-text-field--solo-inverted.v-text-field--solo .v-input__slot {
	background: rgba(0, 0, 0, 0.16);
}

.application .theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot {
	background: #424242;
}

.application .theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot .v-label,
  .application .theme--light.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot input {
	color: #fff;
}

.application .theme--light.v-text-field--box .v-input__slot {
	background: rgba(0, 0, 0, 0.06);
}

.application .theme--light.v-text-field--box:not(.v-input--is-focused) .v-input__slot:hover {
	background: rgba(0, 0, 0, 0.12);
}

.application .theme--light.v-text-field--outline .v-input__slot {
	border: 2px solid rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-text-field--outline:not(.v-input--is-focused) .v-input__slot:hover {
	border: 2px solid rgba(0, 0, 0, 0.87);
}

.application .theme--dark.v-text-field .v-input__slot:before {
	border-color: rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-text-field:not(.v-input--has-state) .v-input__slot:hover:before {
	border-color: #fff;
}

.application .theme--dark.v-text-field.v-input--is-disabled .v-input__slot:before {
	-o-border-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.5) 0px, rgba(255, 255, 255, 0.5) 2px, transparent 2px, transparent 4px) 1 repeat;
	   border-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.5) 0px, rgba(255, 255, 255, 0.5) 2px, transparent 2px, transparent 4px) 1 repeat;
}

.application .theme--dark.v-text-field__prefix,
  .application .theme--dark.v-text-field__suffix {
	color: rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-text-field--solo .v-input__slot {
	border-radius: 2px;
	background: #424242;
}

.application .theme--dark.v-text-field--solo-inverted.v-text-field--solo .v-input__slot {
	background: rgba(255, 255, 255, 0.16);
}

.application .theme--dark.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot {
	background: #fff;
}

.application .theme--dark.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot .v-label,
  .application .theme--dark.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused .v-input__slot input {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--dark.v-text-field--box .v-input__slot {
	background: rgba(0, 0, 0, 0.1);
}

.application .theme--dark.v-text-field--box:not(.v-input--is-focused) .v-input__slot:hover {
	background: rgba(0, 0, 0, 0.2);
}

.application .theme--dark.v-text-field--outline .v-input__slot {
	border: 2px solid rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-text-field--outline:not(.v-input--is-focused) .v-input__slot:hover {
	border: 2px solid #fff;
}

.application .application--is-rtl .v-text-field .v-label {
	-webkit-transform-origin: top right;
	transform-origin: top right;
}

.application .application--is-rtl .v-text-field .v-counter {
	margin-left: 0;
	margin-right: 8px;
}

.application .application--is-rtl .v-text-field--enclosed .v-input__append-outer {
	margin-left: 0;
	margin-right: 16px;
}

.application .application--is-rtl .v-text-field--enclosed .v-input__prepend-outer {
	margin-left: 16px;
	margin-right: 0;
}

.application .application--is-rtl .v-text-field--reverse input {
	text-align: left;
}

.application .application--is-rtl .v-text-field--reverse .v-label {
	-webkit-transform-origin: top left;
	transform-origin: top left;
}

.application .application--is-rtl .v-text-field__prefix {
	text-align: left;
	padding-right: 0;
	padding-left: 4px;
}

.application .application--is-rtl .v-text-field__suffix {
	padding-left: 0;
	padding-right: 4px;
}

.application .application--is-rtl .v-text-field--reverse .v-text-field__prefix {
	text-align: right;
	padding-left: 0;
	padding-right: 4px;
}

.application .application--is-rtl .v-text-field--reverse .v-text-field__suffix {
	padding-left: 0;
	padding-right: 4px;
}

.application .v-text-field {
	padding-top: 12px;
	margin-top: 4px;
}

.application .v-text-field input {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	line-height: 20px;
	padding: 8px 0 8px;
	max-width: 100%;
	min-width: 0px;
	width: 100%;
}

.application .v-text-field .v-input__prepend-inner,
  .application .v-text-field .v-input__append-inner {
	-ms-flex-item-align: start;
	    align-self: flex-start;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-top: 4px;
	line-height: 1;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-text-field .v-input__prepend-inner {
	margin-right: auto;
	padding-right: 4px;
}

.application .v-text-field .v-input__append-inner {
	margin-left: auto;
	padding-left: 4px;
}

.application .v-text-field .v-counter {
	margin-left: 8px;
	white-space: nowrap;
}

.application .v-text-field .v-label {
	max-width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
	top: 6px;
	-webkit-transform-origin: top left;
	transform-origin: top left;
	white-space: nowrap;
	pointer-events: none;
}

.application .v-text-field .v-label--active {
	max-width: 100%;
	-webkit-transform: translateY(-18px) scale(0.75);
	transform: translateY(-18px) scale(0.75);
}

.application .v-text-field > .v-input__control > .v-input__slot {
	cursor: text;
	-webkit-transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-text-field > .v-input__control > .v-input__slot:before,
  .application .v-text-field > .v-input__control > .v-input__slot:after {
	bottom: -1px;
	content: '';
	left: 0;
	position: absolute;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
}

.application .v-text-field > .v-input__control > .v-input__slot:before {
	border-style: solid;
	border-width: thin 0 0 0;
}

.application .v-text-field > .v-input__control > .v-input__slot:after {
	border-color: currentColor;
	border-style: solid;
	border-width: thin 0 thin 0;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
}

.application .v-text-field__details {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	max-width: 100%;
	overflow: hidden;
}

.application .v-text-field__prefix,
  .application .v-text-field__suffix {
	-ms-flex-item-align: center;
	    align-self: center;
	cursor: default;
}

.application .v-text-field__prefix {
	width: 16px;
	text-align: right;
	padding-right: 4px;
}

.application .v-text-field__suffix {
	padding-left: 4px;
	white-space: nowrap;
}

.application .v-text-field--reverse .v-text-field__prefix {
	text-align: left;
	padding-right: 0;
	padding-left: 4px;
}

.application .v-text-field--reverse .v-text-field__suffix {
	padding-left: 0;
	padding-right: 4px;
}

.application .v-text-field__slot {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	position: relative;
}

.application .v-text-field--box,
  .application .v-text-field--outline {
	position: relative;
}

.application .v-text-field--box .v-input__slot,
  .application .v-text-field--outline .v-input__slot {
	-webkit-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
}

.application .v-text-field--box input,
  .application .v-text-field--outline input {
	margin-top: 22px;
}

.application .v-text-field--box.v-text-field--single-line input,
  .application .v-text-field--outline.v-text-field--single-line input {
	margin-top: 12px;
}

.application .v-text-field--box .v-label,
  .application .v-text-field--outline .v-label {
	top: 18px;
}

.application .v-text-field--box .v-label--active,
  .application .v-text-field--outline .v-label--active {
	-webkit-transform: translateY(-6px) scale(0.75);
	transform: translateY(-6px) scale(0.75);
}

.application .v-text-field--box .v-input__slot,
  .application .v-text-field--outline .v-input__slot {
	min-height: 56px;
}

.application .v-text-field--box .v-input__slot {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.application .v-text-field--box .v-input__slot:before {
	border-style: solid;
	border-width: thin 0 thin 0;
}

.application .v-text-field.v-text-field--enclosed {
	margin: 0;
	padding: 0;
}

.application .v-text-field.v-text-field--enclosed:not(.v-text-field--box) .v-progress-linear__background {
	display: none;
}

.application .v-text-field.v-text-field--enclosed .v-input__prepend-outer,
  .application .v-text-field.v-text-field--enclosed .v-input__prepend-inner,
  .application .v-text-field.v-text-field--enclosed .v-input__append-inner,
  .application .v-text-field.v-text-field--enclosed .v-input__append-outer {
	margin-top: 16px;
}

.application .v-text-field.v-text-field--enclosed .v-text-field__details,
  .application .v-text-field.v-text-field--enclosed .v-input__slot {
	padding: 0 12px;
}

.application .v-text-field.v-text-field--enclosed .v-text-field__details {
	margin-bottom: 8px;
}

.application .v-text-field.v-text-field--full-width.v-input {
	margin-bottom: 0;
	margin-top: 0;
}

.application .v-text-field.v-text-field--full-width .v-label {
	top: calc(50% - 10px);
}

.application .v-text-field.v-text-field--full-width .v-input__control {
	padding: 12px 0;
}

.application .v-text-field.v-text-field--full-width .v-input__prepend-outer,
  .application .v-text-field.v-text-field--full-width .v-input__append-outer {
	margin-top: 4px;
}

.application .v-text-field.v-text-field--full-width .v-input__append-inner {
	-ms-flex-item-align: center;
	    align-self: center;
	margin-top: 0;
}

.application .v-text-field--reverse input {
	text-align: right;
}

.application .v-text-field--reverse .v-label {
	-webkit-transform-origin: top right;
	transform-origin: top right;
}

.application .v-text-field--reverse .v-input__slot,
  .application .v-text-field--reverse .v-text-field__slot {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}

.application .v-text-field--solo .v-input__slot:before,
  .application .v-text-field--outline .v-input__slot:before,
  .application .v-text-field--full-width .v-input__slot:before,
  .application .v-text-field--solo .v-input__slot:after,
  .application .v-text-field--outline .v-input__slot:after,
  .application .v-text-field--full-width .v-input__slot:after {
	display: none;
}

.application .v-text-field--outline {
	margin-bottom: 16px;
	-webkit-transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-text-field--outline .v-input__slot {
	background: transparent !important;
	border-radius: 4px;
}

.application .v-text-field--outline .v-input__prepend-outer,
  .application .v-text-field--outline .v-input__append-outer {
	margin-top: 18px;
}

.application .v-text-field--outline.v-input--is-focused .v-input__slot {
	border: 2px solid currentColor;
	-webkit-transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-text-field.v-text-field--solo .v-label {
	top: calc(50% - 10px);
}

.application .v-text-field.v-text-field--solo .v-input__control {
	min-height: 48px;
	padding: 0;
}

.application .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) .v-input__slot {
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.application .v-text-field.v-text-field--solo .v-text-field__slot {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.application .v-text-field.v-text-field--solo .v-input__append-inner,
  .application .v-text-field.v-text-field--solo .v-input__prepend-inner {
	-ms-flex-item-align: center;
	    align-self: center;
	margin-top: 0;
}

.application .v-text-field.v-text-field--solo .v-input__prepend-outer,
  .application .v-text-field.v-text-field--solo .v-input__append-outer {
	margin-top: 12px;
}

.application .v-text-field.v-input--is-focused .v-input__slot:after {
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
}

.application .v-text-field.v-input--has-state .v-input__slot:before {
	border-color: currentColor;
}

.application .theme--light.v-select .v-select__selections {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-select.v-input--is-disabled .v-select__selections {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-select .v-chip--disabled,
  .application .theme--light.v-select .v-select__selection--disabled {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-select.v-text-field--solo-inverted.v-input--is-focused .v-select__selections {
	color: #fff;
}

.application .theme--dark.v-select .v-select__selections {
	color: #fff;
}

.application .theme--dark.v-select.v-input--is-disabled .v-select__selections {
	color: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-select .v-chip--disabled,
  .application .theme--dark.v-select .v-select__selection--disabled {
	color: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-select.v-text-field--solo-inverted.v-input--is-focused .v-select__selections {
	color: rgba(0, 0, 0, 0.87);
}

.application .v-select {
	position: relative;
}

.application .v-select > .v-input__control > .v-input__slot {
	cursor: pointer;
}

.application .v-select .v-chip {
	-webkit-box-flex: 0;
	    -ms-flex: 0 1 auto;
	        flex: 0 1 auto;
}

.application .v-select .fade-transition-leave-active {
	position: absolute;
	left: 0;
}

.application .v-select.v-input--is-dirty ::-webkit-input-placeholder {
	color: transparent !important;
}

.application .v-select.v-input--is-dirty :-ms-input-placeholder {
	color: transparent !important;
}

.application .v-select.v-input--is-dirty ::-ms-input-placeholder {
	color: transparent !important;
}

.application .v-select.v-input--is-dirty ::placeholder {
	color: transparent !important;
}

.application .v-select.v-text-field--enclosed:not(.v-text-field--single-line) .v-select__selections {
	padding-top: 24px;
}

.application .v-select.v-text-field input {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1;
	        flex: 1 1;
	margin-top: 0;
	min-width: 0;
	pointer-events: none;
	position: relative;
}

.application .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.application .v-select.v-select--chips input {
	margin: 0;
}

.application .v-select.v-select--chips .v-select__selections {
	min-height: 42px;
}

.application .v-select.v-select--chips.v-select--chips--small .v-select__selections {
	min-height: 32px;
}

.application .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box .v-select__selections,
  .application .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
	min-height: 68px;
}

.application .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box.v-select--chips--small .v-select__selections,
  .application .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small .v-select__selections {
	min-height: 56px;
}

.application .v-select.v-text-field--reverse .v-select__slot,
  .application .v-select.v-text-field--reverse .v-select__selections {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}

.application .v-select__selections {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	line-height: 18px;
}

.application .v-select__selection {
	max-width: 90%;
}

.application .v-select__selection--comma {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin: 7px 4px 7px 0;
}

.application .v-select__slot {
	position: relative;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.application .v-select:not(.v-text-field--single-line) .v-select__slot > input {
	-ms-flex-item-align: end;
	    align-self: flex-end;
}

.application .theme--light.v-chip {
	background: #e0e0e0;
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-chip--disabled {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--dark.v-chip {
	background: #fff;
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--dark.v-chip--disabled {
	color: rgba(255, 255, 255, 0.5);
}

.application .v-chip {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	border-radius: 28px;
	border: 1px solid transparent;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 13px;
	margin: 4px;
	outline: none;
	position: relative;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	vertical-align: middle;
}

.application .v-chip .v-chip__content {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	border-radius: 28px;
	cursor: default;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	height: 32px;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	padding: 0 12px;
	vertical-align: middle;
	white-space: nowrap;
	z-index: 1;
}

.application .v-chip--removable .v-chip__content {
	padding: 0 4px 0 12px;
}

.application .v-chip .v-avatar {
	height: 32px !important;
	margin-left: -12px;
	margin-right: 8px;
	min-width: 32px;
	width: 32px !important;
}

.application .v-chip .v-avatar img {
	height: 100%;
	width: 100%;
}

.application .v-chip:focus:not(.v-chip--disabled),
  .application .v-chip--active,
  .application .v-chip--selected {
	border-color: rgba(0, 0, 0, 0.13);
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.application .v-chip:focus:not(.v-chip--disabled):after,
  .application .v-chip--active:after,
  .application .v-chip--selected:after {
	background: currentColor;
	border-radius: inherit;
	content: '';
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-transition: inherit;
	transition: inherit;
	width: 100%;
	pointer-events: none;
	opacity: 0.13;
}

.application .v-chip--label {
	border-radius: 2px;
}

.application .v-chip--label .v-chip__content {
	border-radius: 2px;
}

.application .v-chip.v-chip.v-chip--outline {
	background: transparent !important;
	border-color: currentColor;
	color: #9e9e9e;
}

.application .v-chip--small {
	height: 24px;
}

.application .v-chip--small .v-avatar {
	height: 24px;
	min-width: 24px;
	width: 24px;
}

.application .v-chip--small .v-icon {
	font-size: 20px;
}

.application .v-chip__close {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	color: inherit;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 20px;
	margin: 0 2px 0 8px;
	text-decoration: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-chip__close > .v-icon {
	color: inherit !important;
	font-size: 20px;
	cursor: pointer;
	opacity: 0.5;
}

.application .v-chip__close > .v-icon:hover {
	opacity: 1;
}

.application .v-chip--disabled .v-chip__close {
	pointer-events: none;
}

.application .v-chip--select-multi {
	margin: 4px 4px 4px 0;
}

.application .v-chip .v-icon {
	color: inherit;
}

.application .v-chip .v-icon--right {
	margin-left: 12px;
	margin-right: -8px;
}

.application .v-chip .v-icon--left {
	margin-left: -8px;
	margin-right: 12px;
}

.application .v-menu {
	display: block;
	position: relative;
	vertical-align: middle;
}

.application .v-menu--inline {
	display: inline-block;
}

.application .v-menu__activator {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}

.application .v-menu__activator * {
	cursor: pointer;
}

.application .v-menu__activator--disabled {
	pointer-events: none;
}

.application .v-menu__content {
	position: absolute;
	display: inline-block;
	border-radius: 2px;
	max-width: 80%;
	overflow-y: auto;
	overflow-x: hidden;
	contain: content;
	will-change: transform;
	-webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.application .v-menu__content--active {
	pointer-events: none;
}

.application .v-menu__content > .card {
	contain: content;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.application .v-menu > .v-menu__content {
	max-width: none;
}

.application .v-menu-transition-enter .v-list__tile {
	min-width: 0;
	pointer-events: none;
}

.application .v-menu-transition-enter-to .v-list__tile {
	pointer-events: auto;
	-webkit-transition-delay: 0.1s;
	        transition-delay: 0.1s;
}

.application .v-menu-transition-leave-active,
  .application .v-menu-transition-leave-to {
	pointer-events: none;
}

.application .v-menu-transition-enter,
  .application .v-menu-transition-leave-to {
	opacity: 0;
}

.application .v-menu-transition-enter-active,
  .application .v-menu-transition-leave-active {
	-webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.application .v-menu-transition-enter.v-menu__content--auto .v-list__tile {
	opacity: 0;
	-webkit-transform: translateY(-15px);
	transform: translateY(-15px);
}

.application .v-menu-transition-enter.v-menu__content--auto .v-list__tile--active {
	opacity: 1;
	-webkit-transform: none !important;
	transform: none !important;
	pointer-events: auto;
}

.application .theme--light.v-card {
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--dark.v-card {
	background-color: #424242;
	color: #fff;
}

.application .v-card {
	display: block;
	border-radius: 2px;
	min-width: 0;
	position: relative;
	text-decoration: none;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	-webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.application .v-card > *:first-child:not(.v-btn):not(.v-chip) {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}

.application .v-card > *:last-child:not(.v-btn):not(.v-chip) {
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
}

.application .v-card--raised {
	-webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.application .v-card--tile {
	border-radius: 0;
}

.application .v-card--flat {
	-webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.application .v-card--hover {
	cursor: pointer;
	-webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
	-webkit-transition-property: -webkit-box-shadow;
	transition-property: -webkit-box-shadow;
	transition-property: box-shadow;
	transition-property: box-shadow, -webkit-box-shadow;
}

.application .v-card--hover:hover {
	-webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.application .v-card__title {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	padding: 16px;
}

.application .v-card__title--primary {
	padding-top: 24px;
}

.application .v-card__text {
	padding: 16px;
	width: 100%;
}

.application .v-card__actions {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 8px;
}

.application .v-card__actions > *,
  .application .v-card__actions .v-btn {
	margin: 0;
}

.application .v-card__actions .v-btn + .v-btn {
	margin-left: 8px;
}

.application .theme--light.v-input--selection-controls.v-input--is-disabled .v-icon {
	color: rgba(0, 0, 0, 0.26) !important;
}

.application .theme--dark.v-input--selection-controls.v-input--is-disabled .v-icon {
	color: rgba(255, 255, 255, 0.3) !important;
}

.application .application--is-rtl .v-input--selection-controls .v-input--selection-controls__input {
	margin-right: 0;
	margin-left: 8px;
}

.application .v-input--selection-controls {
	margin-top: 16px;
	padding-top: 4px;
}

.application .v-input--selection-controls .v-input__append-outer,
  .application .v-input--selection-controls .v-input__prepend-outer {
	margin-top: 0;
	margin-bottom: 0;
}

.application .v-input--selection-controls .v-input__slot {
	margin-bottom: 12px;
}

.application .v-input--selection-controls__input {
	color: inherit;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 auto;
	        flex: 0 0 auto;
	height: 24px;
	position: relative;
	margin-right: 8px;
	transition: color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	-webkit-transition: color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	width: 24px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-input--selection-controls__input input {
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-input--selection-controls__input + .v-label {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-input--selection-controls__ripple {
	cursor: pointer;
	height: 48px;
	position: absolute;
	-webkit-transition: inherit;
	transition: inherit;
	width: 48px;
	left: -12px;
	top: calc(50% - 24px);
}

.application .v-input--selection-controls__ripple:before {
	border-radius: 50%;
	bottom: 0;
	content: '';
	position: absolute;
	opacity: 0.2;
	left: 0;
	right: 0;
	top: 0;
	-webkit-transform-origin: center center;
	transform-origin: center center;
	-webkit-transform: scale(0.2);
	transform: scale(0.2);
	-webkit-transition: inherit;
	transition: inherit;
}

.application .v-input--selection-controls.v-input .v-label {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	top: 0;
	height: auto;
}

.application .v-input--selection-controls.v-input--is-focused .v-input--selection-controls__ripple:before,
  .application .v-input--selection-controls .v-radio--is-focused .v-input--selection-controls__ripple:before {
	background: currentColor;
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
}

.application .theme--light.v-input:not(.v-input--is-disabled) input,
  .application .theme--light.v-input:not(.v-input--is-disabled) textarea {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-input input::-webkit-input-placeholder,
  .application .theme--light.v-input textarea::-webkit-input-placeholder {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-input input:-ms-input-placeholder,
  .application .theme--light.v-input textarea:-ms-input-placeholder {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-input input::-ms-input-placeholder,
  .application .theme--light.v-input textarea::-ms-input-placeholder {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-input input::placeholder,
  .application .theme--light.v-input textarea::placeholder {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-input--is-disabled .v-label,
  .application .theme--light.v-input--is-disabled input,
  .application .theme--light.v-input--is-disabled textarea {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--dark.v-input:not(.v-input--is-disabled) input,
  .application .theme--dark.v-input:not(.v-input--is-disabled) textarea {
	color: #fff;
}

.application .theme--dark.v-input input::-webkit-input-placeholder,
  .application .theme--dark.v-input textarea::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-input input:-ms-input-placeholder,
  .application .theme--dark.v-input textarea:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-input input::-ms-input-placeholder,
  .application .theme--dark.v-input textarea::-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-input input::placeholder,
  .application .theme--dark.v-input textarea::placeholder {
	color: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-input--is-disabled .v-label,
  .application .theme--dark.v-input--is-disabled input,
  .application .theme--dark.v-input--is-disabled textarea {
	color: rgba(255, 255, 255, 0.5);
}

.application .v-input {
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	font-size: 16px;
	text-align: left;
}

.application .v-input .v-progress-linear {
	top: calc(100% - 1px);
	left: 0;
	margin: 0;
	position: absolute;
}

.application .v-input input {
	max-height: 32px;
}

.application .v-input input:invalid,
  .application .v-input textarea:invalid {
	-webkit-box-shadow: none;
	        box-shadow: none;
}

.application .v-input input:focus,
  .application .v-input textarea:focus,
  .application .v-input input:active,
  .application .v-input textarea:active {
	outline: none;
}

.application .v-input .v-label {
	height: 20px;
	line-height: 20px;
}

.application .v-input__append-outer,
  .application .v-input__prepend-outer {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-bottom: 4px;
	margin-top: 4px;
	line-height: 1;
}

.application .v-input__append-outer .v-icon,
  .application .v-input__prepend-outer .v-icon {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-input__append-outer {
	margin-left: 9px;
}

.application .v-input__prepend-outer {
	margin-right: 9px;
}

.application .v-input__control {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	height: auto;
	-webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	position: relative;
	width: 100%;
}

.application .v-input__icon {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	height: 24px;
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	min-width: 24px;
	width: 24px;
}

.application .v-input__icon--clear {
	border-radius: 50%;
}

.application .v-input__slot {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	color: inherit;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 8px;
	min-height: inherit;
	position: relative;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
}

.application .v-input--is-disabled:not(.v-input--is-readonly) {
	pointer-events: none;
}

.application .v-input--is-loading .v-input__slot:before,
  .application .v-input--is-loading .v-input__slot:after {
	display: none;
}

.application .v-input--hide-details .v-input__slot {
	margin-bottom: 0;
}

.application .v-input--has-state.error--text .v-label {
	-webkit-animation: shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
	animation: shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .theme--light.v-label {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-label--is-disabled {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--dark.v-label {
	color: rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-label--is-disabled {
	color: rgba(255, 255, 255, 0.5);
}

.application .v-label {
	font-size: 16px;
	line-height: 1;
	min-height: 8px;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .theme--light.v-messages {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--dark.v-messages {
	color: rgba(255, 255, 255, 0.7);
}

.application .application--is-rtl .v-messages {
	text-align: right;
}

.application .v-messages {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	font-size: 12px;
	min-height: 12px;
	min-width: 1px;
	position: relative;
}

.application .v-messages__message {
	line-height: 1;
	word-break: break-word;
	overflow-wrap: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}

.application .v-progress-linear {
	background: transparent;
	margin: 1rem 0;
	overflow: hidden;
	width: 100%;
	position: relative;
}

.application .v-progress-linear__bar {
	width: 100%;
	height: inherit;
	position: relative;
	-webkit-transition: 0.2s;
	transition: 0.2s;
	z-index: 1;
}

.application .v-progress-linear__bar__determinate {
	height: inherit;
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

.application .v-progress-linear__bar__indeterminate .long,
  .application .v-progress-linear__bar__indeterminate .short {
	height: inherit;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	will-change: left, right;
	width: auto;
	background-color: inherit;
}

.application .v-progress-linear__bar__indeterminate--active .long {
	-webkit-animation: indeterminate;
	animation: indeterminate;
	-webkit-animation-duration: 2.2s;
	animation-duration: 2.2s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.application .v-progress-linear__bar__indeterminate--active .short {
	-webkit-animation: indeterminate-short;
	animation: indeterminate-short;
	-webkit-animation-duration: 2.2s;
	animation-duration: 2.2s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.application .v-progress-linear__background {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	-webkit-transition: 0.3s ease-in;
	transition: 0.3s ease-in;
}

.application .v-progress-linear--query .v-progress-linear__bar__indeterminate--active .long {
	-webkit-animation: query;
	animation: query;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.application .v-progress-linear--query .v-progress-linear__bar__indeterminate--active .short {
	-webkit-animation: query-short;
	animation: query-short;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

@-webkit-keyframes indeterminate {
	0% {
		left: -90%;
		right: 100%;
	}

	60% {
		left: -90%;
		right: 100%;
	}

	100% {
		left: 100%;
		right: -35%;
	}
}

@keyframes indeterminate {
	0% {
		left: -90%;
		right: 100%;
	}

	60% {
		left: -90%;
		right: 100%;
	}

	100% {
		left: 100%;
		right: -35%;
	}
}

@-webkit-keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}

	60% {
		left: 107%;
		right: -8%;
	}

	100% {
		left: 107%;
		right: -8%;
	}
}

@keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}

	60% {
		left: 107%;
		right: -8%;
	}

	100% {
		left: 107%;
		right: -8%;
	}
}

@-webkit-keyframes query {
	0% {
		right: -90%;
		left: 100%;
	}

	60% {
		right: -90%;
		left: 100%;
	}

	100% {
		right: 100%;
		left: -35%;
	}
}

@keyframes query {
	0% {
		right: -90%;
		left: 100%;
	}

	60% {
		right: -90%;
		left: 100%;
	}

	100% {
		right: 100%;
		left: -35%;
	}
}

@-webkit-keyframes query-short {
	0% {
		right: -200%;
		left: 100%;
	}

	60% {
		right: 107%;
		left: -8%;
	}

	100% {
		right: 107%;
		left: -8%;
	}
}

@keyframes query-short {
	0% {
		right: -200%;
		left: 100%;
	}

	60% {
		right: 107%;
		left: -8%;
	}

	100% {
		right: 107%;
		left: -8%;
	}
}

.application .theme--light.v-divider {
	border-color: rgba(0, 0, 0, 0.12);
}

.application .theme--dark.v-divider {
	border-color: rgba(255, 255, 255, 0.12);
}

.application .v-divider {
	display: block;
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 0px;
	        flex: 1 1 0px;
	max-width: 100%;
	height: 0px;
	max-height: 0px;
	border: solid;
	border-width: thin 0 0 0;
	-webkit-transition: inherit;
	transition: inherit;
}

.application .v-divider--inset:not(.v-divider--vertical) {
	margin-left: 72px;
	max-width: calc(100% - 72px);
}

.application .v-divider--vertical {
	-ms-flex-item-align: stretch;
	    align-self: stretch;
	border: solid;
	border-width: 0 thin 0 0;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	height: inherit;
	min-height: 100%;
	max-height: 100%;
	max-width: 0px;
	width: 0px;
	vertical-align: text-bottom;
}

.application .v-divider--vertical.v-divider--inset {
	margin-top: 8px;
	min-height: 0;
	max-height: calc(100% - 16px);
}

.application .theme--light.v-subheader {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--dark.v-subheader {
	color: rgba(255, 255, 255, 0.7);
}

.application .v-subheader {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 48px;
	font-size: 14px;
	font-weight: 500;
	padding: 0 16px 0 16px;
}

.application .v-subheader--inset {
	margin-left: 56px;
}

.application .theme--light.v-list {
	background: #fff;
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-list .v-list--disabled {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-list .v-list__tile__sub-title {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-list .v-list__tile__mask {
	color: rgba(0, 0, 0, 0.38);
	background: #eee;
}

.application .theme--light.v-list .v-list__tile--link:hover,
  .application .theme--light.v-list .v-list__tile--highlighted,
  .application .theme--light.v-list .v-list__group__header:hover {
	background: rgba(0, 0, 0, 0.04);
}

.application .theme--light.v-list .v-list__group--active:before,
  .application .theme--light.v-list .v-list__group--active:after {
	background: rgba(0, 0, 0, 0.12);
}

.application .theme--light.v-list .v-list__group--disabled .v-list__tile {
	color: rgba(0, 0, 0, 0.38) !important;
}

.application .theme--light.v-list .v-list__group--disabled .v-list__group__header__prepend-icon .v-icon {
	color: rgba(0, 0, 0, 0.38) !important;
}

.application .theme--dark.v-list {
	background: #424242;
	color: #fff;
}

.application .theme--dark.v-list .v-list--disabled {
	color: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-list .v-list__tile__sub-title {
	color: rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-list .v-list__tile__mask {
	color: rgba(255, 255, 255, 0.5);
	background: #494949;
}

.application .theme--dark.v-list .v-list__tile--link:hover,
  .application .theme--dark.v-list .v-list__tile--highlighted,
  .application .theme--dark.v-list .v-list__group__header:hover {
	background: rgba(255, 255, 255, 0.08);
}

.application .theme--dark.v-list .v-list__group--active:before,
  .application .theme--dark.v-list .v-list__group--active:after {
	background: rgba(255, 255, 255, 0.12);
}

.application .theme--dark.v-list .v-list__group--disabled .v-list__tile {
	color: rgba(255, 255, 255, 0.5) !important;
}

.application .theme--dark.v-list .v-list__group--disabled .v-list__group__header__prepend-icon .v-icon {
	color: rgba(255, 255, 255, 0.5) !important;
}

.application .v-list {
	list-style-type: none;
	padding: 8px 0 8px;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-list .v-input .v-messages {
	display: none;
}

.application .v-list .v-input,
  .application .v-list .v-input__slot {
	margin: 0;
}

.application .v-list > div {
	-webkit-transition: inherit;
	transition: inherit;
}

.application .v-list__tile {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	color: inherit;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 16px;
	font-weight: 400;
	height: 48px;
	margin: 0;
	padding: 0 16px;
	position: relative;
	text-decoration: none;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-list__tile--link {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-list__tile__content,
  .application .v-list__tile__action {
	height: 100%;
}

.application .v-list__tile__title,
  .application .v-list__tile__sub-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
}

.application .v-list__tile__title {
	height: 24px;
	line-height: 24px;
	position: relative;
	text-align: left;
}

.application .v-list__tile__sub-title {
	font-size: 14px;
}

.application .v-list__tile__avatar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	min-width: 56px;
}

.application .v-list__tile__action {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	min-width: 56px;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.application .v-list__tile__action .v-btn {
	padding: 0;
	margin: 0;
}

.application .v-list__tile__action .v-btn--icon {
	margin: -6px;
}

.application .v-list__tile__action .v-radio.v-radio {
	margin: 0;
}

.application .v-list__tile__action .v-input--selection-controls {
	padding: 0;
}

.application .v-list__tile__action-text {
	color: #9e9e9e;
	font-size: 12px;
}

.application .v-list__tile__action--stack {
	-webkit-box-align: end;
	    -ms-flex-align: end;
	        align-items: flex-end;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	padding-top: 8px;
	padding-bottom: 8px;
	white-space: nowrap;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

.application .v-list__tile__content {
	text-align: left;
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	overflow: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

.application .v-list__tile__content ~ .v-list__tile__avatar {
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
}

.application .v-list__tile__content ~ .v-list__tile__action:not(.v-list__tile__action--stack) {
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
}

.application .v-list__tile--active .v-list__tile__action:first-of-type .v-icon {
	color: inherit;
}

.application .v-list__tile--avatar {
	height: 56px;
}

.application .v-list--dense {
	padding-top: 4px;
	padding-bottom: 4px;
}

.application .v-list--dense .v-subheader {
	font-size: 13px;
	height: 40px;
}

.application .v-list--dense .v-list__group .v-subheader {
	height: 40px;
}

.application .v-list--dense .v-list__tile {
	font-size: 13px;
}

.application .v-list--dense .v-list__tile--avatar {
	height: 48px;
}

.application .v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
	height: 40px;
}

.application .v-list--dense .v-list__tile .v-icon {
	font-size: 22px;
}

.application .v-list--dense .v-list__tile__sub-title {
	font-size: 13px;
}

.application .v-list--disabled {
	pointer-events: none;
}

.application .v-list--two-line .v-list__tile {
	height: 72px;
}

.application .v-list--two-line.v-list--dense .v-list__tile {
	height: 60px;
}

.application .v-list--three-line .v-list__tile {
	height: 88px;
}

.application .v-list--three-line .v-list__tile__avatar {
	margin-top: -18px;
}

.application .v-list--three-line .v-list__tile__sub-title {
	white-space: initial;
	-webkit-line-clamp: 2;
	display: -webkit-box;
}

.application .v-list--three-line.v-list--dense .v-list__tile {
	height: 76px;
}

.application .v-list > .v-list__group:before {
	top: 0;
}

.application .v-list > .v-list__group:before .v-list__tile__avatar {
	margin-top: -14px;
}

.application .v-list__group {
	padding: 0;
	position: relative;
	-webkit-transition: inherit;
	transition: inherit;
}

.application .v-list__group:before,
  .application .v-list__group:after {
	content: '';
	height: 1px;
	left: 0;
	position: absolute;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
}

.application .v-list__group--active ~ .v-list__group:before {
	display: none;
}

.application .v-list__group__header {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	list-style-type: none;
}

.application .v-list__group__header > div:not(.v-list__group__header__prepend-icon):not(.v-list__group__header__append-icon) {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
}

.application .v-list__group__header .v-list__group__header__append-icon,
  .application .v-list__group__header .v-list__group__header__prepend-icon {
	padding: 0 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-list__group__header--sub-group {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.application .v-list__group__header--sub-group div .v-list__tile {
	padding-left: 0;
}

.application .v-list__group__header--sub-group .v-list__group__header__prepend-icon {
	padding: 0 0 0 40px;
	margin-right: 8px;
}

.application .v-list__group__header .v-list__group__header__prepend-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	min-width: 56px;
}

.application .v-list__group__header--active .v-list__group__header__append-icon .v-icon {
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.application .v-list__group__header--active .v-list__group__header__prepend-icon .v-icon {
	color: inherit;
}

.application .v-list__group__header--active.v-list__group__header--sub-group .v-list__group__header__prepend-icon .v-icon {
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.application .v-list__group__items {
	position: relative;
	padding: 0;
	-webkit-transition: inherit;
	transition: inherit;
}

.application .v-list__group__items > div {
	display: block;
}

.application .v-list__group__items--no-action .v-list__tile {
	padding-left: 72px;
}

.application .v-list__group--disabled {
	pointer-events: none;
}

.application .v-list--subheader {
	padding-top: 0;
}

.application .v-avatar {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	border-radius: 50%;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	position: relative;
	text-align: center;
	vertical-align: middle;
}

.application .v-avatar img,
  .application .v-avatar .v-icon,
  .application .v-avatar .v-image {
	border-radius: 50%;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	height: inherit;
	width: inherit;
}

.application .v-avatar--tile {
	border-radius: 0;
}

.application .v-avatar--tile img,
  .application .v-avatar--tile .v-icon,
  .application .v-avatar--tile .v-image {
	border-radius: 0;
}

.application .theme--light.v-counter {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--dark.v-counter {
	color: rgba(255, 255, 255, 0.7);
}

.application .v-counter {
	-webkit-box-flex: 0;
	    -ms-flex: 0 1 auto;
	        flex: 0 1 auto;
	font-size: 12px;
	min-height: 12px;
	line-height: 1;
}

.application .v-badge {
	display: inline-block;
	position: relative;
}

.application .v-badge__badge {
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	font-size: 14px;
	top: -11px;
	right: -22px;
	border-radius: 50%;
	height: 22px;
	width: 22px;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-badge__badge .v-icon {
	font-size: 14px;
}

.application .v-badge--overlap .v-badge__badge {
	top: -8px;
	right: -8px;
}

.application .v-badge--overlap.v-badge--left .v-badge__badge {
	left: -8px;
	right: initial;
}

.application .v-badge--overlap.v-badge--bottom .v-badge__badge {
	bottom: -8px;
	top: initial;
}

.application .v-badge--left .v-badge__badge {
	left: -22px;
}

.application .v-badge--bottom .v-badge__badge {
	bottom: -11px;
	top: initial;
}

.application .theme--light.v-bottom-nav {
	background-color: #fff;
}

.application .theme--dark.v-bottom-nav {
	background-color: #424242;
}

.application .v-bottom-nav {
	bottom: 0;
	-webkit-box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12);
	        box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	left: 0;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-transform: translate(0, 60px);
	transform: translate(0, 60px);
	-webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
}

.application .v-bottom-nav--absolute {
	position: absolute;
}

.application .v-bottom-nav--active {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}

.application .v-bottom-nav--fixed {
	position: fixed;
	z-index: 4;
}

.application .v-bottom-nav .v-btn {
	background: transparent !important;
	border-radius: 0;
	-webkit-box-shadow: none !important;
	        box-shadow: none !important;
	font-weight: 400;
	height: 100%;
	margin: 0;
	max-width: 168px;
	min-width: 80px;
	padding: 8px 12px 10px;
	text-transform: none;
	opacity: 0.5;
	width: 100%;
	-ms-flex-negative: 1;
	    flex-shrink: 1;
}

.application .v-bottom-nav .v-btn .v-btn__content {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: column-reverse;
	        flex-direction: column-reverse;
	font-size: 12px;
	white-space: nowrap;
	will-change: font-size;
}

.application .v-bottom-nav .v-btn .v-btn__content i.v-icon {
	color: inherit;
	margin-bottom: 4px;
	-webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-bottom-nav .v-btn .v-btn__content span {
	line-height: 1;
}

.application .v-bottom-nav .v-btn--active {
	opacity: 1;
	padding-top: 6px;
}

.application .v-bottom-nav .v-btn--active:before {
	background-color: transparent;
}

.application .v-bottom-nav .v-btn--active .v-btn__content {
	font-size: 14px;
}

.application .v-bottom-nav .v-btn--active .v-btn__content .v-icon {
	-webkit-transform: none;
	transform: none;
}

.application .v-bottom-nav .v-btn:not(.v-btn--active) {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}

.application .v-bottom-nav--shift .v-btn__content {
	font-size: 14px;
}

.application .v-bottom-nav--shift .v-btn {
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	min-width: 56px;
	max-width: 96px;
}

.application .v-bottom-nav--shift .v-btn--active {
	min-width: 96px;
	max-width: 168px;
}

.application .v-bottom-nav--shift .v-btn:not(.v-btn--active) .v-btn__content .v-icon {
	-webkit-transform: scale(1, 1) translate(0, 8px);
	transform: scale(1, 1) translate(0, 8px);
}

.application .v-bottom-nav--shift .v-btn:not(.v-btn--active) .v-btn__content span {
	color: transparent;
}

.application .v-bottom-sheet.v-dialog {
	-ms-flex-item-align: end;
	    align-self: flex-end;
	border-radius: 0;
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 100%;
	        flex: 1 0 100%;
	margin: 0;
	min-width: 100%;
	overflow: visible;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.application .v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
	max-width: 70%;
	min-width: 0;
}

@media only screen and (max-width: 599px) {
	.application .v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
		max-width: none;
	}
}

.application .v-dialog {
	-webkit-box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	border-radius: 2px;
	margin: 24px;
	overflow-y: auto;
	pointer-events: auto;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	width: 100%;
	z-index: inherit;
}

.application .v-dialog__content {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	left: 0;
	pointer-events: none;
	position: fixed;
	top: 0;
	-webkit-transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
	width: 100%;
	z-index: 6;
	outline: none;
}

.application .v-dialog:not(.v-dialog--fullscreen) {
	max-height: 90%;
}

.application .v-dialog__activator {
	cursor: pointer;
}

.application .v-dialog__activator * {
	cursor: pointer;
}

.application .v-dialog__activator--disabled {
	pointer-events: none;
}

.application .v-dialog__container {
	display: inline-block;
	vertical-align: middle;
}

.application .v-dialog--animated {
	-webkit-animation-duration: 0.15s;
	animation-duration: 0.15s;
	-webkit-animation-name: animate-dialog;
	animation-name: animate-dialog;
	-webkit-animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
	animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}

.application .v-dialog--fullscreen {
	border-radius: 0;
	margin: 0;
	height: 100%;
	position: fixed;
	overflow-y: auto;
	top: 0;
	left: 0;
}

.application .v-dialog--fullscreen > .v-card {
	min-height: 100%;
	min-width: 100%;
	margin: 0 !important;
	padding: 0 !important;
}

.application .v-dialog--scrollable {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.application .v-dialog--scrollable > .v-card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

.application .v-dialog--scrollable > .v-card > .v-card__title,
  .application .v-dialog--scrollable > .v-card > .v-card__actions {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
}

.application .v-dialog--scrollable > .v-card > .v-card__text {
	overflow-y: auto;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

@-webkit-keyframes animate-dialog {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	50% {
		-webkit-transform: scale(1.03);
		transform: scale(1.03);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes animate-dialog {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	50% {
		-webkit-transform: scale(1.03);
		transform: scale(1.03);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.application .v-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	z-index: 5;
}

.application .v-overlay--absolute {
	position: absolute;
}

.application .v-overlay:before {
	background-color: #212121;
	bottom: 0;
	content: '';
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	-webkit-transition: inherit;
	transition: inherit;
	-webkit-transition-delay: 150ms;
	        transition-delay: 150ms;
	width: 100%;
}

.application .v-overlay--active {
	pointer-events: auto;
	-ms-touch-action: none;
	    touch-action: none;
}

.application .v-overlay--active:before {
	opacity: 0.46;
}

.application .theme--light.v-breadcrumbs li.v-breadcrumbs__divider,
  .application .theme--light.v-breadcrumbs li:last-child .v-breadcrumbs__item,
  .application .theme--light.v-breadcrumbs li .v-breadcrumbs__item--disabled {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--dark.v-breadcrumbs li.v-breadcrumbs__divider,
  .application .theme--dark.v-breadcrumbs li:last-child .v-breadcrumbs__item,
  .application .theme--dark.v-breadcrumbs li .v-breadcrumbs__item--disabled {
	color: rgba(255, 255, 255, 0.5);
}

.application .v-breadcrumbs {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-flex: 0;
	    -ms-flex: 0 1 auto;
	        flex: 0 1 auto;
	list-style-type: none;
	margin: 0;
	padding: 18px 12px;
}

.application .v-breadcrumbs li {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 14px;
}

.application .v-breadcrumbs li .v-icon {
	font-size: 16px;
}

.application .v-breadcrumbs li:last-child a {
	cursor: default;
	pointer-events: none;
}

.application .v-breadcrumbs li:nth-child(even) {
	padding: 0 12px;
}

.application .v-breadcrumbs--large li {
	font-size: 16px;
}

.application .v-breadcrumbs--large li .v-icon {
	font-size: 16px;
}

.application .v-breadcrumbs__item {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	text-decoration: none;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-breadcrumbs__item--disabled {
	pointer-events: none;
}

.application .v-ripple__container {
	color: inherit;
	border-radius: inherit;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: hidden;
	z-index: 0;
	pointer-events: none;
	contain: strict;
}

.application .v-ripple__animation {
	color: inherit;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	background: currentColor;
	opacity: 0;
	-webkit-transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
	transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
	pointer-events: none;
	overflow: hidden;
	will-change: transform, opacity;
}

.application .v-ripple__animation--enter {
	-webkit-transition: none;
	transition: none;
}

.application .v-ripple__animation--visible {
	opacity: 0.15;
}

.application .theme--light.v-btn {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-btn.v-btn--disabled {
	color: rgba(0, 0, 0, 0.26) !important;
}

.application .theme--light.v-btn.v-btn--disabled .v-icon,
  .application .theme--light.v-btn.v-btn--disabled .v-btn__loading {
	color: rgba(0, 0, 0, 0.26) !important;
}

.application .theme--light.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat) {
	background-color: rgba(0, 0, 0, 0.12) !important;
}

.application .theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
	background-color: #f5f5f5;
}

.application .theme--dark.v-btn {
	color: #fff;
}

.application .theme--dark.v-btn.v-btn--disabled {
	color: rgba(255, 255, 255, 0.3) !important;
}

.application .theme--dark.v-btn.v-btn--disabled .v-icon,
  .application .theme--dark.v-btn.v-btn--disabled .v-btn__loading {
	color: rgba(255, 255, 255, 0.3) !important;
}

.application .theme--dark.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat) {
	background-color: rgba(255, 255, 255, 0.12) !important;
}

.application .theme--dark.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
	background-color: #212121;
}

.application .v-btn {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	border-radius: 2px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	height: 36px;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 auto;
	        flex: 0 0 auto;
	font-size: 14px;
	font-weight: 500;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	margin: 6px 8px;
	min-width: 88px;
	outline: 0;
	text-transform: uppercase;
	text-decoration: none;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
	position: relative;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-btn:before {
	border-radius: inherit;
	color: inherit;
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	opacity: 0.12;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
}

.application .v-btn {
	padding: 0 16px;
}

.application .v-btn--active,
  .application .v-btn:hover,
  .application .v-btn:focus {
	position: relative;
}

.application .v-btn--active:before,
  .application .v-btn:hover:before,
  .application .v-btn:focus:before {
	background-color: currentColor;
}

.application .v-btn__content {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	border-radius: inherit;
	color: inherit;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	margin: 0 auto;
	position: relative;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	white-space: nowrap;
	width: inherit;
}

.application .v-btn--small {
	font-size: 13px;
	height: 28px;
	padding: 0 8px;
}

.application .v-btn--large {
	font-size: 15px;
	height: 44px;
	padding: 0 32px;
}

.application .v-btn .v-btn__content .v-icon {
	color: inherit;
}

.application .v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
	will-change: box-shadow;
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.application .v-btn:not(.v-btn--depressed):not(.v-btn--flat):active {
	-webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.application .v-btn--icon {
	background: transparent;
	-webkit-box-shadow: none !important;
	        box-shadow: none !important;
	border-radius: 50%;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	min-width: 0;
	width: 36px;
}

.application .v-btn--icon.v-btn--small {
	width: 28px;
}

.application .v-btn--icon.v-btn--large {
	width: 44px;
}

.application .v-btn--icon:before {
	border-radius: 50%;
}

.application .v-btn--floating {
	border-radius: 50%;
	min-width: 0;
	height: 56px;
	width: 56px;
	padding: 0;
}

.application .v-btn--floating.v-btn--fixed,
  .application .v-btn--floating.v-btn--absolute {
	z-index: 4;
}

.application .v-btn--floating:not(.v-btn--depressed):not(.v-btn--flat) {
	-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.application .v-btn--floating:not(.v-btn--depressed):not(.v-btn--flat):active {
	-webkit-box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.application .v-btn--floating .v-btn__content {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	margin: 0;
	height: 100%;
}

.application .v-btn--floating:after {
	border-radius: 50%;
}

.application .v-btn--floating .v-btn__content :not(:only-child) {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-btn--floating .v-btn__content :not(:only-child):first-child {
	opacity: 1;
}

.application .v-btn--floating .v-btn__content :not(:only-child):last-child {
	opacity: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.application .v-btn--floating .v-btn__content :not(:only-child):last-child,
  .application .v-btn--floating .v-btn__content :not(:only-child):first-child {
	-webkit-backface-visibility: hidden;
	position: absolute;
	left: 0;
	top: 0;
}

.application .v-btn--floating.v-btn--active .v-btn__content :not(:only-child):first-child {
	opacity: 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.application .v-btn--floating.v-btn--active .v-btn__content :not(:only-child):last-child {
	opacity: 1;
	-webkit-transform: rotate(0);
	transform: rotate(0);
}

.application .v-btn--floating .v-icon {
	height: inherit;
	width: inherit;
}

.application .v-btn--floating.v-btn--small {
	height: 40px;
	width: 40px;
}

.application .v-btn--floating.v-btn--small .v-icon {
	font-size: 18px;
}

.application .v-btn--floating.v-btn--large {
	height: 72px;
	width: 72px;
}

.application .v-btn--floating.v-btn--large .v-icon {
	font-size: 30px;
}

.application .v-btn--reverse .v-btn__content {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}

.application .v-btn--reverse.v-btn--column .v-btn__content {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: column-reverse;
	        flex-direction: column-reverse;
}

.application .v-btn--fixed,
  .application .v-btn--absolute {
	margin: 0;
}

.application .v-btn.v-btn--absolute {
	position: absolute;
}

.application .v-btn.v-btn--fixed {
	position: fixed;
}

.application .v-btn--top:not(.v-btn--absolute) {
	top: 16px;
}

.application .v-btn--top.v-btn--absolute {
	top: -28px;
}

.application .v-btn--top.v-btn--absolute.v-btn--small {
	top: -20px;
}

.application .v-btn--top.v-btn--absolute.v-btn--large {
	top: -36px;
}

.application .v-btn--bottom:not(.v-btn--absolute) {
	bottom: 16px;
}

.application .v-btn--bottom.v-btn--absolute {
	bottom: -28px;
}

.application .v-btn--bottom.v-btn--absolute.v-btn--small {
	bottom: -20px;
}

.application .v-btn--bottom.v-btn--absolute.v-btn--large {
	bottom: -36px;
}

.application .v-btn--left {
	left: 16px;
}

.application .v-btn--right {
	right: 16px;
}

.application .v-btn.v-btn--disabled {
	-webkit-box-shadow: none !important;
	        box-shadow: none !important;
	pointer-events: none;
}

.application .v-btn:not(.v-btn--disabled):not(.v-btn--floating):not(.v-btn--icon) .v-btn__content .v-icon {
	-webkit-transition: none;
	transition: none;
}

.application .v-btn--icon {
	padding: 0;
}

.application .v-btn--loader {
	pointer-events: none;
}

.application .v-btn--loader .v-btn__content {
	opacity: 0;
}

.application .v-btn__loading {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.application .v-btn__loading .v-icon--left {
	margin-right: 1rem;
	line-height: inherit;
}

.application .v-btn__loading .v-icon--right {
	margin-left: 1rem;
	line-height: inherit;
}

.application .v-btn.v-btn--outline {
	border: 1px solid currentColor;
	background: transparent !important;
	-webkit-box-shadow: none;
	        box-shadow: none;
}

.application .v-btn.v-btn--outline:hover {
	-webkit-box-shadow: none;
	        box-shadow: none;
}

.application .v-btn--block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	margin: 6px 0;
	width: 100%;
}

.application .v-btn--round {
	border-radius: 28px;
}

.application .v-btn--round:after {
	border-radius: 28px;
}

.application .v-btn .v-icon--right {
	margin-left: 16px;
}

.application .v-btn .v-icon--left {
	margin-right: 16px;
}

.application .v-btn:not(.v-btn--outline).primary,
  .application .v-btn:not(.v-btn--outline).secondary,
  .application .v-btn:not(.v-btn--outline).accent,
  .application .v-btn:not(.v-btn--outline).success,
  .application .v-btn:not(.v-btn--outline).error,
  .application .v-btn:not(.v-btn--outline).warning,
  .application .v-btn:not(.v-btn--outline).info {
	color: #fff;
}

.application .v-progress-circular {
	position: relative;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
}

.application .v-progress-circular svg {
	width: 100%;
	height: 100%;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}

.application .v-progress-circular--indeterminate svg {
	-webkit-animation: progress-circular-rotate 1.4s linear infinite;
	animation: progress-circular-rotate 1.4s linear infinite;
	-webkit-transform-origin: center center;
	transform-origin: center center;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.application .v-progress-circular--indeterminate .v-progress-circular__overlay {
	-webkit-animation: progress-circular-dash 1.4s ease-in-out infinite;
	animation: progress-circular-dash 1.4s ease-in-out infinite;
	stroke-linecap: round;
	stroke-dasharray: 80, 200;
	stroke-dashoffset: 0px;
}

.application .v-progress-circular__underlay {
	stroke: rgba(0, 0, 0, 0.1);
	z-index: 1;
}

.application .v-progress-circular__overlay {
	stroke: currentColor;
	z-index: 2;
	-webkit-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
}

.application .v-progress-circular__info {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

@-webkit-keyframes progress-circular-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0px;
	}

	50% {
		stroke-dasharray: 100, 200;
		stroke-dashoffset: -15px;
	}

	100% {
		stroke-dasharray: 100, 200;
		stroke-dashoffset: -125px;
	}
}

@keyframes progress-circular-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0px;
	}

	50% {
		stroke-dasharray: 100, 200;
		stroke-dashoffset: -15px;
	}

	100% {
		stroke-dasharray: 100, 200;
		stroke-dashoffset: -125px;
	}
}

@-webkit-keyframes progress-circular-rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes progress-circular-rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.application .theme--light.v-btn-toggle {
	background: #fff;
}

.application .theme--light.v-btn-toggle .v-btn {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-btn-toggle .v-btn.v-btn--active:not(:last-child):not([data-only-child]) {
	border-right-color: rgba(0, 0, 0, 0.26);
}

.application .theme--dark.v-btn-toggle {
	background: #424242;
}

.application .theme--dark.v-btn-toggle .v-btn {
	color: #fff;
}

.application .theme--dark.v-btn-toggle .v-btn.v-btn--active:not(:last-child):not([data-only-child]) {
	border-right-color: rgba(255, 255, 255, 0.3);
}

.application .v-btn-toggle {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	border-radius: 2px;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	will-change: background, box-shadow;
}

.application .v-btn-toggle .v-btn {
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	min-width: auto;
	width: auto;
	padding: 0 8px;
	margin: 0;
	opacity: 0.4;
	border-radius: 0;
}

.application .v-btn-toggle .v-btn:not(:last-child) {
	border-right: 1px solid transparent;
}

.application .v-btn-toggle .v-btn:after {
	display: none;
}

.application .v-btn-toggle .v-btn.v-btn--active {
	opacity: 1;
}

.application .v-btn-toggle .v-btn span + .v-icon {
	font-size: initial;
	margin-left: 10px;
}

.application .v-btn-toggle .v-btn:first-child {
	border-radius: 2px 0 0 2px;
}

.application .v-btn-toggle .v-btn:last-child {
	border-radius: 0 2px 2px 0;
}

.application .v-btn-toggle--selected {
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.application .v-image {
	z-index: 0;
}

.application .v-image__image,
  .application .v-image__placeholder {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.application .v-image__image {
	background-repeat: no-repeat;
}

.application .v-image__image--preload {
	-webkit-filter: blur(2px);
	filter: blur(2px);
}

.application .v-image__image--contain {
	background-size: contain;
}

.application .v-image__image--cover {
	background-size: cover;
}

.application .v-responsive {
	position: relative;
	overflow: hidden;
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.application .v-responsive__content {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 0px;
	        flex: 1 0 0px;
}

.application .v-responsive__sizer {
	-webkit-transition: padding-bottom 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: padding-bottom 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 0px;
	        flex: 0 0 0px;
}

.application .application--is-rtl .v-carousel__prev {
	left: auto;
	right: 5px;
}

.application .application--is-rtl .v-carousel__next {
	left: 5px;
	right: auto;
}

.application .v-carousel {
	height: 500px;
	width: 100%;
	position: relative;
	overflow: hidden;
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.application .v-carousel__item {
	position: absolute;
	top: 0;
	width: 100%;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-carousel__prev,
  .application .v-carousel__next {
	position: absolute;
	top: 50%;
	z-index: 1;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.application .v-carousel__prev .v-btn,
  .application .v-carousel__next .v-btn {
	margin: 0;
	height: auto;
	width: auto;
}

.application .v-carousel__prev .v-btn i,
  .application .v-carousel__next .v-btn i {
	font-size: 48px;
}

.application .v-carousel__prev .v-btn:hover,
  .application .v-carousel__next .v-btn:hover {
	background: none;
}

.application .v-carousel__prev {
	left: 5px;
}

.application .v-carousel__next {
	right: 5px;
}

.application .v-carousel__controls {
	background: rgba(0, 0, 0, 0.5);
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	left: 0;
	position: absolute;
	height: 50px;
	list-style-type: none;
	width: 100%;
	z-index: 1;
}

.application .v-carousel__controls__item {
	margin: 0 8px !important;
}

.application .v-carousel__controls__item .v-icon {
	opacity: 0.5;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-carousel__controls__item--active .v-icon {
	opacity: 1;
	vertical-align: middle;
}

.application .v-carousel__controls__item:hover {
	background: none;
}

.application .v-carousel__controls__item:hover .v-icon {
	opacity: 0.8;
}

.application .theme--light.v-data-iterator .v-data-iterator__actions {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-data-iterator .v-data-iterator__actions__select .v-select .v-select__selection--comma {
	color: rgba(0, 0, 0, 0.54) !important;
}

.application .theme--light.v-data-iterator .v-data-iterator__actions__select .v-select .v-input__append-inner {
	color: rgba(0, 0, 0, 0.54) !important;
}

.application .theme--dark.v-data-iterator .v-data-iterator__actions {
	color: rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-data-iterator .v-data-iterator__actions__select .v-select .v-select__selection--comma {
	color: rgba(255, 255, 255, 0.7) !important;
}

.application .theme--dark.v-data-iterator .v-data-iterator__actions__select .v-select .v-input__append-inner {
	color: rgba(255, 255, 255, 0.7) !important;
}

.application .v-data-iterator__actions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-size: 12px;
	-ms-flex-wrap: wrap-reverse;
	    flex-wrap: wrap-reverse;
}

.application .v-data-iterator__actions .v-btn {
	color: inherit;
}

.application .v-data-iterator__actions .v-btn:last-of-type {
	margin-left: 14px;
}

.application .v-data-iterator__actions__range-controls {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	min-height: 48px;
}

.application .v-data-iterator__actions__pagination {
	display: block;
	text-align: center;
	margin: 0 32px 0 24px;
}

.application .v-data-iterator__actions__select {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	margin-right: 14px;
}

.application .v-data-iterator__actions__select .v-select {
	margin: 13px 0 13px 34px;
	padding: 0;
	position: initial;
}

.application .v-data-iterator__actions__select .v-select .v-select__selection--comma {
	font-size: 12px;
}

.application .theme--light.v-overflow-btn .v-input__control::before,
  .application .theme--light.v-overflow-btn .v-input__slot::before {
	background-color: rgba(0, 0, 0, 0.12) !important;
}

.application .theme--light.v-overflow-btn--segmented .v-input__append-inner,
  .application .theme--light.v-overflow-btn--editable:hover .v-input__append-inner,
  .application .theme--light.v-overflow-btn--editable.v-input--is-focused .v-input__append-inner,
  .application .theme--light.v-overflow-btn--editable.v-select--is-menu-active .v-input__append-inner {
	border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.application .theme--light.v-overflow-btn:hover .v-input__slot,
  .application .theme--light.v-overflow-btn.v-input--is-focused .v-input__slot,
  .application .theme--light.v-overflow-btn.v-select--is-menu-active .v-input__slot {
	background: #fff;
}

.application .theme--dark.v-overflow-btn .v-input__control::before,
  .application .theme--dark.v-overflow-btn .v-input__slot::before {
	background-color: rgba(255, 255, 255, 0.12) !important;
}

.application .theme--dark.v-overflow-btn--segmented .v-input__append-inner,
  .application .theme--dark.v-overflow-btn--editable:hover .v-input__append-inner,
  .application .theme--dark.v-overflow-btn--editable.v-input--is-focused .v-input__append-inner,
  .application .theme--dark.v-overflow-btn--editable.v-select--is-menu-active .v-input__append-inner {
	border-left: 1px solid rgba(255, 255, 255, 0.12);
}

.application .theme--dark.v-overflow-btn:hover .v-input__slot,
  .application .theme--dark.v-overflow-btn.v-input--is-focused .v-input__slot,
  .application .theme--dark.v-overflow-btn.v-select--is-menu-active .v-input__slot {
	background: #424242;
}

.application .v-overflow-btn {
	margin-top: 12px;
}

.application .v-overflow-btn:not(.v-overflow-btn--editable) > .v-input__control > .v-input__slot {
	cursor: pointer;
}

.application .v-overflow-btn .v-select__slot {
	height: 48px;
}

.application .v-overflow-btn .v-select__slot input {
	margin-left: 16px;
}

.application .v-overflow-btn .v-select__selection--comma:first-child {
	margin-left: 16px;
}

.application .v-overflow-btn .v-input__slot {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-overflow-btn .v-input__slot::after {
	content: none;
}

.application .v-overflow-btn .v-label {
	margin-left: 16px;
	top: calc(50% - 10px);
}

.application .v-overflow-btn .v-input__append-inner {
	width: 48px;
	height: 48px;
	-ms-flex-item-align: auto;
	    align-self: auto;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	margin-top: 0;
	padding: 0;
	-ms-flex-negative: 0;
	    flex-shrink: 0;
}

.application .v-overflow-btn .v-input__append-outer,
  .application .v-overflow-btn .v-input__prepend-outer {
	margin-top: 12px;
	margin-bottom: 12px;
}

.application .v-overflow-btn .v-input__control::before {
	height: 1px;
	top: -1px;
	content: '';
	left: 0;
	position: absolute;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
}

.application .v-overflow-btn.v-input--is-focused .v-input__slot,
  .application .v-overflow-btn.v-select--is-menu-active .v-input__slot {
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.application .v-overflow-btn .v-select__selections {
	width: 0px;
}

.application .v-overflow-btn--segmented .v-select__selections {
	-ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
}

.application .v-overflow-btn--segmented .v-select__selections .v-btn {
	border-radius: 0;
	margin: 0;
	margin-right: -16px;
	height: 48px;
	width: 100%;
}

.application .v-overflow-btn--segmented .v-select__selections .v-btn__content {
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: start;
}

.application .v-overflow-btn--segmented .v-select__selections .v-btn__content::before {
	background-color: transparent;
}

.application .v-overflow-btn--editable .v-input__append-inner,
  .application .v-overflow-btn--editable .v-input__append-inner * {
	cursor: pointer;
}

.application .theme--light.v-table {
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-table thead tr:first-child {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.application .theme--light.v-table thead th {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-table tbody tr:not(:last-child) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.application .theme--light.v-table tbody tr[active] {
	background: #f5f5f5;
}

.application .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
	background: #eee;
}

.application .theme--light.v-table tfoot tr {
	border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.application .theme--dark.v-table {
	background-color: #424242;
	color: #fff;
}

.application .theme--dark.v-table thead tr:first-child {
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.application .theme--dark.v-table thead th {
	color: rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-table tbody tr:not(:last-child) {
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.application .theme--dark.v-table tbody tr[active] {
	background: #505050;
}

.application .theme--dark.v-table tbody tr:hover:not(.v-datatable__expand-row) {
	background: #616161;
}

.application .theme--dark.v-table tfoot tr {
	border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.application .v-table__overflow {
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
}

.application table.v-table {
	border-radius: 2px;
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	max-width: 100%;
}

.application table.v-table thead td:not(:nth-child(1)),
  .application table.v-table tbody td:not(:nth-child(1)),
  .application table.v-table thead th:not(:nth-child(1)),
  .application table.v-table tbody th:not(:nth-child(1)),
  .application table.v-table thead td:first-child,
  .application table.v-table tbody td:first-child,
  .application table.v-table thead th:first-child,
  .application table.v-table tbody th:first-child {
	padding: 0 24px;
}

.application table.v-table thead tr {
	height: 56px;
}

.application table.v-table thead th {
	font-weight: 500;
	font-size: 12px;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application table.v-table thead th.sortable {
	pointer-events: auto;
}

.application table.v-table thead th > div {
	width: 100%;
}

.application table.v-table tbody tr {
	-webkit-transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	will-change: background;
}

.application table.v-table tbody td,
  .application table.v-table tbody th {
	height: 48px;
}

.application table.v-table tbody td {
	font-weight: 400;
	font-size: 13px;
}

.application table.v-table .input-group--selection-controls {
	padding: 0;
}

.application table.v-table .input-group--selection-controls .input-group__details {
	display: none;
}

.application table.v-table .input-group--selection-controls.checkbox .v-icon {
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.application table.v-table .input-group--selection-controls.checkbox .input-group--selection-controls__ripple {
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.application table.v-table tfoot tr {
	height: 48px;
}

.application table.v-table tfoot tr td {
	padding: 0 24px;
}

.application .theme--light.v-datatable thead th.column.sortable i {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-datatable thead th.column.sortable:hover {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-datatable thead th.column.sortable.active {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-datatable thead th.column.sortable.active i {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-datatable .v-datatable__actions {
	background-color: #fff;
	color: rgba(0, 0, 0, 0.54);
	border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.application .theme--dark.v-datatable thead th.column.sortable i {
	color: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-datatable thead th.column.sortable:hover {
	color: #fff;
}

.application .theme--dark.v-datatable thead th.column.sortable.active {
	color: #fff;
}

.application .theme--dark.v-datatable thead th.column.sortable.active i {
	color: #fff;
}

.application .theme--dark.v-datatable .v-datatable__actions {
	background-color: #424242;
	color: rgba(255, 255, 255, 0.7);
	border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.application .v-datatable .v-input--selection-controls {
	margin: 0;
}

.application .v-datatable thead th.column.sortable {
	cursor: pointer;
	outline: 0;
}

.application .v-datatable thead th.column.sortable i {
	font-size: 16px;
	display: inline-block;
	opacity: 0;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-datatable thead th.column.sortable:focus i,
  .application .v-datatable thead th.column.sortable:hover i {
	opacity: 0.6;
}

.application .v-datatable thead th.column.sortable.active {
	-webkit-transform: none;
	transform: none;
}

.application .v-datatable thead th.column.sortable.active i {
	opacity: 1;
}

.application .v-datatable thead th.column.sortable.active.desc i {
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.application .v-datatable__actions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-size: 12px;
	-ms-flex-wrap: wrap-reverse;
	    flex-wrap: wrap-reverse;
}

.application .v-datatable__actions .v-btn {
	color: inherit;
}

.application .v-datatable__actions .v-btn:last-of-type {
	margin-left: 14px;
}

.application .v-datatable__actions__range-controls {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	min-height: 48px;
}

.application .v-datatable__actions__pagination {
	display: block;
	text-align: center;
	margin: 0 32px 0 24px;
}

.application .v-datatable__actions__select {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
	margin-right: 14px;
	white-space: nowrap;
}

.application .v-datatable__actions__select .v-select {
	-webkit-box-flex: 0;
	    -ms-flex: 0 1 0px;
	        flex: 0 1 0;
	margin: 13px 0 13px 34px;
	padding: 0;
	position: initial;
}

.application .v-datatable__actions__select .v-select__selections {
	-ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
}

.application .v-datatable__actions__select .v-select__selections .v-select__selection--comma {
	font-size: 12px;
}

.application .v-datatable__progress {
	height: auto !important;
}

.application .v-datatable__progress tr,
  .application .v-datatable__progress td,
  .application .v-datatable__progress th {
	height: auto !important;
}

.application .v-datatable__progress th {
	padding: 0 !important;
}

.application .v-datatable__progress th .v-progress-linear {
	margin: 0;
}

.application .v-datatable__expand-row {
	border: none !important;
}

.application .v-datatable__expand-col {
	padding: 0 !important;
	height: 0px !important;
}

.application .v-datatable__expand-col--expanded {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.application .v-datatable__expand-content {
	-webkit-transition: height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-datatable__expand-content > .card {
	border-radius: 0;
	-webkit-box-shadow: none;
	        box-shadow: none;
}

.application .theme--light.v-small-dialog a {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--dark.v-small-dialog a {
	color: #fff;
}

.application .theme--light.v-small-dialog__content {
	background: #fff;
}

.application .theme--dark.v-small-dialog__content {
	background: #424242;
}

.application .theme--light.v-small-dialog__actions {
	background: #fff;
}

.application .theme--dark.v-small-dialog__actions {
	background: #424242;
}

.application .v-small-dialog {
	display: block;
	width: 100%;
	height: 100%;
}

.application .v-small-dialog__content {
	padding: 0 24px;
}

.application .v-small-dialog__actions {
	text-align: right;
	white-space: pre;
}

.application .v-small-dialog a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	height: 100%;
	text-decoration: none;
}

.application .v-small-dialog a > * {
	width: 100%;
}

.application .v-small-dialog .v-menu__activator {
	height: 100%;
}

.application .application--is-rtl .v-date-picker-title .v-picker__title__btn {
	text-align: right;
}

.application .v-date-picker-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	line-height: 1;
}

.application .v-date-picker-title__year {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 8px;
}

.application .v-date-picker-title__date {
	font-size: 34px;
	text-align: left;
	font-weight: 500;
	position: relative;
	overflow: hidden;
	padding-bottom: 8px;
	margin-bottom: -8px;
}

.application .v-date-picker-title__date > div {
	position: relative;
}

.application .theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) strong:not(:hover) {
	color: rgba(0, 0, 0, 0.87) !important;
}

.application .theme--light.v-date-picker-header .v-date-picker-header__value--disabled strong {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--dark.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) strong:not(:hover) {
	color: #fff !important;
}

.application .theme--dark.v-date-picker-header .v-date-picker-header__value--disabled strong {
	color: rgba(255, 255, 255, 0.5);
}

.application .v-date-picker-header {
	padding: 4px 16px;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	position: relative;
}

.application .v-date-picker-header .v-btn {
	margin: 0;
	z-index: auto;
}

.application .v-date-picker-header .v-icon {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-date-picker-header__value {
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	text-align: center;
	position: relative;
	overflow: hidden;
}

.application .v-date-picker-header__value strong {
	cursor: pointer;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	display: block;
	width: 100%;
}

.application .theme--light.v-date-picker-table th {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--dark.v-date-picker-table th {
	color: rgba(255, 255, 255, 0.5);
}

.application .v-date-picker-table {
	position: relative;
	padding: 0 12px;
	height: 242px;
}

.application .v-date-picker-table table {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	top: 0;
	table-layout: fixed;
	width: 100%;
}

.application .v-date-picker-table td,
  .application .v-date-picker-table th {
	text-align: center;
	position: relative;
}

.application .v-date-picker-table th {
	font-size: 12px;
}

.application .v-date-picker-table--date .v-btn {
	height: 32px;
	width: 32px;
}

.application .v-date-picker-table .v-btn {
	z-index: auto;
	margin: 0;
	font-size: 12px;
}

.application .v-date-picker-table .v-btn.v-btn--active {
	color: #fff;
}

.application .v-date-picker-table--month td {
	width: 33.333333%;
	height: 56px;
	vertical-align: middle;
	text-align: center;
}

.application .v-date-picker-table--month td .v-btn {
	margin: 0 auto;
	max-width: 160px;
	min-width: 40px;
	width: 100%;
}

.application .v-date-picker-table--date th {
	padding: 8px 0;
	font-weight: 600;
}

.application .v-date-picker-table--date td {
	width: 45px;
}

.application .v-date-picker-table__event {
	border-radius: 50%;
	bottom: 2px;
	content: "";
	display: block;
	height: 8px;
	left: 50%;
	position: absolute;
	-webkit-transform: translateX(-4px);
	transform: translateX(-4px);
	width: 8px;
}

.application .v-date-picker-years {
	font-size: 16px;
	font-weight: 400;
	height: 286px;
	list-style-type: none;
	overflow: auto;
	padding: 0;
	text-align: center;
}

.application .v-date-picker-years li {
	cursor: pointer;
	padding: 8px 0;
	-webkit-transition: none;
	transition: none;
}

.application .v-date-picker-years li.active {
	font-size: 26px;
	font-weight: 500;
	padding: 10px 0;
}

.application .v-date-picker-years li:hover {
	background: rgba(0, 0, 0, 0.12);
}

.application .v-picker--landscape .v-date-picker-years {
	height: 286px;
}

.application .theme--light.v-picker__title {
	background: #e0e0e0;
}

.application .theme--dark.v-picker__title {
	background: #616161;
}

.application .theme--light.v-picker__body {
	background: #fff;
}

.application .theme--dark.v-picker__body {
	background: #424242;
}

.application .v-picker {
	border-radius: 2px;
	contain: layout style;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	vertical-align: top;
}

.application .v-picker--full-width {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.application .v-picker__title {
	color: #fff;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	padding: 16px;
}

.application .v-picker__title__btn {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-picker__title__btn:not(.active) {
	opacity: 0.6;
	cursor: pointer;
}

.application .v-picker__title__btn:not(.active):hover {
	opacity: 1;
}

.application .v-picker__title__btn--readonly {
	pointer-events: none;
}

.application .v-picker__title__btn--active {
	opacity: 1;
}

.application .v-picker__body {
	height: auto;
	overflow: hidden;
	position: relative;
	z-index: 0;
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.application .v-picker__body > div {
	width: 100%;
}

.application .v-picker__body > div.fade-transition-leave-active {
	position: absolute;
}

.application .v-picker--landscape .v-picker__title {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	width: 170px;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 1;
}

.application .v-picker--landscape .v-picker__body,
  .application .v-picker--landscape .v-picker__actions {
	margin-left: 170px;
}

.application .theme--light.v-expansion-panel .v-expansion-panel__container {
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-expansion-panel .v-expansion-panel__container .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-expansion-panel .v-expansion-panel__container--disabled {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-expansion-panel--focusable .v-expansion-panel__container:focus {
	background-color: #eee;
}

.application .theme--dark.v-expansion-panel .v-expansion-panel__container {
	border-top: 1px solid rgba(255, 255, 255, 0.12);
	background-color: #424242;
	color: #fff;
}

.application .theme--dark.v-expansion-panel .v-expansion-panel__container .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon {
	color: #fff;
}

.application .theme--dark.v-expansion-panel .v-expansion-panel__container--disabled {
	color: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-expansion-panel--focusable .v-expansion-panel__container:focus {
	background-color: #494949;
}

.application .v-expansion-panel {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	list-style-type: none;
	padding: 0;
	text-align: left;
	width: 100%;
	-webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.application .v-expansion-panel__container {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 100%;
	        flex: 1 0 100%;
	max-width: 100%;
	outline: none;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-expansion-panel__container:first-child {
	border-top: none !important;
}

.application .v-expansion-panel__container .v-expansion-panel__header__iconnel__header__icon {
	margin-left: auto;
}

.application .v-expansion-panel__container--disabled .v-expansion-panel__header {
	pointer-events: none;
}

.application .v-expansion-panel__container--active > .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon {
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.application .v-expansion-panel__header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	cursor: pointer;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	position: relative;
	padding: 12px 24px;
	min-height: 48px;
}

.application .v-expansion-panel__header > *:not(.v-expansion-panel__header__icon) {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
}

.application .v-expansion-panel__body {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-expansion-panel__body .v-card {
	border-radius: 0;
	-webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.application .v-expansion-panel--popout,
  .application .v-expansion-panel--inset {
	-webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.application .v-expansion-panel--popout .v-expansion-panel__container--active,
  .application .v-expansion-panel--inset .v-expansion-panel__container--active {
	margin: 16px;
	-webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.application .v-expansion-panel--popout .v-expansion-panel__container,
  .application .v-expansion-panel--inset .v-expansion-panel__container {
	max-width: 95%;
}

.application .v-expansion-panel--popout .v-expansion-panel__container--active {
	max-width: 100%;
}

.application .v-expansion-panel--inset .v-expansion-panel__container--active {
	max-width: 85%;
}

.application .theme--light.v-footer {
	background: #f5f5f5;
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--dark.v-footer {
	background: #212121;
	color: #fff;
}

.application .v-footer {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0 !important;
	    -ms-flex: 0 1 auto !important;
	        flex: 0 1 auto !important;
	min-height: 36px;
	-webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.application .v-footer--absolute,
  .application .v-footer--fixed {
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 3;
}

.application .v-footer--inset {
	z-index: 2;
}

.application .v-footer--absolute {
	position: absolute;
}

.application .v-footer--fixed {
	position: fixed;
}

.application .v-form > .container {
	padding: 16px;
}

.application .v-form > .container > .layout > .flex {
	padding: 8px;
}

.application .v-form > .container > .layout:only-child {
	margin: -8px;
}

.application .v-form > .container > .layout:not(:only-child) {
	margin: auto -8px;
}

.application .container {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 100%;
	        flex: 1 1 100%;
	margin: auto;
	padding: 24px;
	width: 100%;
}

.application .container.fluid {
	max-width: 100%;
}

.application .container.fill-height {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.application .container.fill-height > .layout {
	height: 100%;
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
}

.application .container.grid-list-xs .layout .flex {
	padding: 1px;
}

.application .container.grid-list-xs .layout:only-child {
	margin: -1px;
}

.application .container.grid-list-xs .layout:not(:only-child) {
	margin: auto -1px;
}

.application .container.grid-list-xs *:not(:only-child) .layout:first-child {
	margin-top: -1px;
}

.application .container.grid-list-xs *:not(:only-child) .layout:last-child {
	margin-bottom: -1px;
}

.application .container.grid-list-sm .layout .flex {
	padding: 2px;
}

.application .container.grid-list-sm .layout:only-child {
	margin: -2px;
}

.application .container.grid-list-sm .layout:not(:only-child) {
	margin: auto -2px;
}

.application .container.grid-list-sm *:not(:only-child) .layout:first-child {
	margin-top: -2px;
}

.application .container.grid-list-sm *:not(:only-child) .layout:last-child {
	margin-bottom: -2px;
}

.application .container.grid-list-md .layout .flex {
	padding: 4px;
}

.application .container.grid-list-md .layout:only-child {
	margin: -4px;
}

.application .container.grid-list-md .layout:not(:only-child) {
	margin: auto -4px;
}

.application .container.grid-list-md *:not(:only-child) .layout:first-child {
	margin-top: -4px;
}

.application .container.grid-list-md *:not(:only-child) .layout:last-child {
	margin-bottom: -4px;
}

.application .container.grid-list-lg .layout .flex {
	padding: 8px;
}

.application .container.grid-list-lg .layout:only-child {
	margin: -8px;
}

.application .container.grid-list-lg .layout:not(:only-child) {
	margin: auto -8px;
}

.application .container.grid-list-lg *:not(:only-child) .layout:first-child {
	margin-top: -8px;
}

.application .container.grid-list-lg *:not(:only-child) .layout:last-child {
	margin-bottom: -8px;
}

.application .container.grid-list-xl .layout .flex {
	padding: 12px;
}

.application .container.grid-list-xl .layout:only-child {
	margin: -12px;
}

.application .container.grid-list-xl .layout:not(:only-child) {
	margin: auto -12px;
}

.application .container.grid-list-xl *:not(:only-child) .layout:first-child {
	margin-top: -12px;
}

.application .container.grid-list-xl *:not(:only-child) .layout:last-child {
	margin-bottom: -12px;
}

.application .layout {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	-ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
	min-width: 0;
}

.application .layout.row {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
}

.application .layout.row.reverse {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}

.application .layout.column {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

.application .layout.column.reverse {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: column-reverse;
	        flex-direction: column-reverse;
}

.application .layout.column > .flex {
	max-width: 100%;
}

.application .layout.wrap {
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}

.application .flex,
  .application .child-flex > * {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	max-width: 100%;
}

.application .align-start {
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
}

.application .align-end {
	-webkit-box-align: end;
	    -ms-flex-align: end;
	        align-items: flex-end;
}

.application .align-center {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.application .align-baseline {
	-webkit-box-align: baseline;
	    -ms-flex-align: baseline;
	        align-items: baseline;
}

.application .align-self-start {
	-ms-flex-item-align: start;
	    align-self: flex-start;
}

.application .align-self-end {
	-ms-flex-item-align: end;
	    align-self: flex-end;
}

.application .align-self-center {
	-ms-flex-item-align: center;
	    align-self: center;
}

.application .align-self-baseline {
	-ms-flex-item-align: baseline;
	    align-self: baseline;
}

.application .align-content-start {
	-ms-flex-line-pack: start;
	    align-content: flex-start;
}

.application .align-content-end {
	-ms-flex-line-pack: end;
	    align-content: flex-end;
}

.application .align-content-center {
	-ms-flex-line-pack: center;
	    align-content: center;
}

.application .align-content-space-between {
	-ms-flex-line-pack: justify;
	    align-content: space-between;
}

.application .align-content-space-around {
	-ms-flex-line-pack: distribute;
	    align-content: space-around;
}

.application .justify-start {
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
}

.application .justify-end {
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
}

.application .justify-center {
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.application .justify-space-around {
	-ms-flex-pack: distribute;
	    justify-content: space-around;
}

.application .justify-space-between {
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
}

.application .justify-self-start {
	justify-self: flex-start;
}

.application .justify-self-end {
	justify-self: flex-end;
}

.application .justify-self-center {
	justify-self: center;
}

.application .justify-self-baseline {
	justify-self: baseline;
}

.application .spacer {
	-webkit-box-flex: 1 !important;
	    -ms-flex-positive: 1 !important;
	        flex-grow: 1 !important;
}

.application .grow {
	-webkit-box-flex: 1 !important;
	    -ms-flex-positive: 1 !important;
	        flex-grow: 1 !important;
	-ms-flex-negative: 0 !important;
	    flex-shrink: 0 !important;
}

.application .shrink {
	-webkit-box-flex: 0 !important;
	    -ms-flex-positive: 0 !important;
	        flex-grow: 0 !important;
	-ms-flex-negative: 1 !important;
	    flex-shrink: 1 !important;
}

.application .scroll-y {
	overflow-y: auto;
}

.application .fill-height {
	height: 100%;
}

.application .hide-overflow {
	overflow: hidden !important;
}

.application .show-overflow {
	overflow: visible !important;
}

.application .no-wrap {
	white-space: nowrap;
}

.application .ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.application .d-flex {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

.application .d-inline-flex {
	display: -webkit-inline-box !important;
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}

.application .d-flex > *,
  .application .d-inline-flex > * {
	-webkit-box-flex: 1 !important;
	    -ms-flex: 1 1 auto !important;
	        flex: 1 1 auto !important;
}

.application .d-block {
	display: block !important;
}

.application .d-inline-block {
	display: inline-block !important;
}

.application .d-inline {
	display: inline !important;
}

@media only screen and (min-width: 960px) {
	.application .container {
		max-width: 900px;
	}
}

@media only screen and (min-width: 1264px) {
	.application .container {
		max-width: 1185px;
	}
}

@media only screen and (min-width: 1904px) {
	.application .container {
		max-width: 1785px;
	}
}

@media only screen and (max-width: 959px) {
	.application .container {
		padding: 16px;
	}
}

@media all and (min-width: 0) {
	.application .flex.xs1 {
		-ms-flex-preferred-size: 8.333333333333332%;
		    flex-basis: 8.333333333333332%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 8.333333333333332%;
	}

	.application .flex.order-xs1 {
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
	}

	.application .flex.xs2 {
		-ms-flex-preferred-size: 16.666666666666664%;
		    flex-basis: 16.666666666666664%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 16.666666666666664%;
	}

	.application .flex.order-xs2 {
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
	}

	.application .flex.xs3 {
		-ms-flex-preferred-size: 25%;
		    flex-basis: 25%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 25%;
	}

	.application .flex.order-xs3 {
		-webkit-box-ordinal-group: 4;
		    -ms-flex-order: 3;
		        order: 3;
	}

	.application .flex.xs4 {
		-ms-flex-preferred-size: 33.33333333333333%;
		    flex-basis: 33.33333333333333%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 33.33333333333333%;
	}

	.application .flex.order-xs4 {
		-webkit-box-ordinal-group: 5;
		    -ms-flex-order: 4;
		        order: 4;
	}

	.application .flex.xs5 {
		-ms-flex-preferred-size: 41.66666666666667%;
		    flex-basis: 41.66666666666667%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 41.66666666666667%;
	}

	.application .flex.order-xs5 {
		-webkit-box-ordinal-group: 6;
		    -ms-flex-order: 5;
		        order: 5;
	}

	.application .flex.xs6 {
		-ms-flex-preferred-size: 50%;
		    flex-basis: 50%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 50%;
	}

	.application .flex.order-xs6 {
		-webkit-box-ordinal-group: 7;
		    -ms-flex-order: 6;
		        order: 6;
	}

	.application .flex.xs7 {
		-ms-flex-preferred-size: 58.333333333333336%;
		    flex-basis: 58.333333333333336%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 58.333333333333336%;
	}

	.application .flex.order-xs7 {
		-webkit-box-ordinal-group: 8;
		    -ms-flex-order: 7;
		        order: 7;
	}

	.application .flex.xs8 {
		-ms-flex-preferred-size: 66.66666666666666%;
		    flex-basis: 66.66666666666666%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 66.66666666666666%;
	}

	.application .flex.order-xs8 {
		-webkit-box-ordinal-group: 9;
		    -ms-flex-order: 8;
		        order: 8;
	}

	.application .flex.xs9 {
		-ms-flex-preferred-size: 75%;
		    flex-basis: 75%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 75%;
	}

	.application .flex.order-xs9 {
		-webkit-box-ordinal-group: 10;
		    -ms-flex-order: 9;
		        order: 9;
	}

	.application .flex.xs10 {
		-ms-flex-preferred-size: 83.33333333333334%;
		    flex-basis: 83.33333333333334%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 83.33333333333334%;
	}

	.application .flex.order-xs10 {
		-webkit-box-ordinal-group: 11;
		    -ms-flex-order: 10;
		        order: 10;
	}

	.application .flex.xs11 {
		-ms-flex-preferred-size: 91.66666666666666%;
		    flex-basis: 91.66666666666666%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 91.66666666666666%;
	}

	.application .flex.order-xs11 {
		-webkit-box-ordinal-group: 12;
		    -ms-flex-order: 11;
		        order: 11;
	}

	.application .flex.xs12 {
		-ms-flex-preferred-size: 100%;
		    flex-basis: 100%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 100%;
	}

	.application .flex.order-xs12 {
		-webkit-box-ordinal-group: 13;
		    -ms-flex-order: 12;
		        order: 12;
	}

	.application .flex.offset-xs0 {
		margin-left: 0%;
	}

	.application .flex.offset-xs1 {
		margin-left: 8.333333333333332%;
	}

	.application .flex.offset-xs2 {
		margin-left: 16.666666666666664%;
	}

	.application .flex.offset-xs3 {
		margin-left: 25%;
	}

	.application .flex.offset-xs4 {
		margin-left: 33.33333333333333%;
	}

	.application .flex.offset-xs5 {
		margin-left: 41.66666666666667%;
	}

	.application .flex.offset-xs6 {
		margin-left: 50%;
	}

	.application .flex.offset-xs7 {
		margin-left: 58.333333333333336%;
	}

	.application .flex.offset-xs8 {
		margin-left: 66.66666666666666%;
	}

	.application .flex.offset-xs9 {
		margin-left: 75%;
	}

	.application .flex.offset-xs10 {
		margin-left: 83.33333333333334%;
	}

	.application .flex.offset-xs11 {
		margin-left: 91.66666666666666%;
	}

	.application .flex.offset-xs12 {
		margin-left: 100%;
	}
}

@media all and (min-width: 600px) {
	.application .flex.sm1 {
		-ms-flex-preferred-size: 8.333333333333332%;
		    flex-basis: 8.333333333333332%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 8.333333333333332%;
	}

	.application .flex.order-sm1 {
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
	}

	.application .flex.sm2 {
		-ms-flex-preferred-size: 16.666666666666664%;
		    flex-basis: 16.666666666666664%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 16.666666666666664%;
	}

	.application .flex.order-sm2 {
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
	}

	.application .flex.sm3 {
		-ms-flex-preferred-size: 25%;
		    flex-basis: 25%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 25%;
	}

	.application .flex.order-sm3 {
		-webkit-box-ordinal-group: 4;
		    -ms-flex-order: 3;
		        order: 3;
	}

	.application .flex.sm4 {
		-ms-flex-preferred-size: 33.33333333333333%;
		    flex-basis: 33.33333333333333%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 33.33333333333333%;
	}

	.application .flex.order-sm4 {
		-webkit-box-ordinal-group: 5;
		    -ms-flex-order: 4;
		        order: 4;
	}

	.application .flex.sm5 {
		-ms-flex-preferred-size: 41.66666666666667%;
		    flex-basis: 41.66666666666667%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 41.66666666666667%;
	}

	.application .flex.order-sm5 {
		-webkit-box-ordinal-group: 6;
		    -ms-flex-order: 5;
		        order: 5;
	}

	.application .flex.sm6 {
		-ms-flex-preferred-size: 50%;
		    flex-basis: 50%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 50%;
	}

	.application .flex.order-sm6 {
		-webkit-box-ordinal-group: 7;
		    -ms-flex-order: 6;
		        order: 6;
	}

	.application .flex.sm7 {
		-ms-flex-preferred-size: 58.333333333333336%;
		    flex-basis: 58.333333333333336%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 58.333333333333336%;
	}

	.application .flex.order-sm7 {
		-webkit-box-ordinal-group: 8;
		    -ms-flex-order: 7;
		        order: 7;
	}

	.application .flex.sm8 {
		-ms-flex-preferred-size: 66.66666666666666%;
		    flex-basis: 66.66666666666666%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 66.66666666666666%;
	}

	.application .flex.order-sm8 {
		-webkit-box-ordinal-group: 9;
		    -ms-flex-order: 8;
		        order: 8;
	}

	.application .flex.sm9 {
		-ms-flex-preferred-size: 75%;
		    flex-basis: 75%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 75%;
	}

	.application .flex.order-sm9 {
		-webkit-box-ordinal-group: 10;
		    -ms-flex-order: 9;
		        order: 9;
	}

	.application .flex.sm10 {
		-ms-flex-preferred-size: 83.33333333333334%;
		    flex-basis: 83.33333333333334%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 83.33333333333334%;
	}

	.application .flex.order-sm10 {
		-webkit-box-ordinal-group: 11;
		    -ms-flex-order: 10;
		        order: 10;
	}

	.application .flex.sm11 {
		-ms-flex-preferred-size: 91.66666666666666%;
		    flex-basis: 91.66666666666666%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 91.66666666666666%;
	}

	.application .flex.order-sm11 {
		-webkit-box-ordinal-group: 12;
		    -ms-flex-order: 11;
		        order: 11;
	}

	.application .flex.sm12 {
		-ms-flex-preferred-size: 100%;
		    flex-basis: 100%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 100%;
	}

	.application .flex.order-sm12 {
		-webkit-box-ordinal-group: 13;
		    -ms-flex-order: 12;
		        order: 12;
	}

	.application .flex.offset-sm0 {
		margin-left: 0%;
	}

	.application .flex.offset-sm1 {
		margin-left: 8.333333333333332%;
	}

	.application .flex.offset-sm2 {
		margin-left: 16.666666666666664%;
	}

	.application .flex.offset-sm3 {
		margin-left: 25%;
	}

	.application .flex.offset-sm4 {
		margin-left: 33.33333333333333%;
	}

	.application .flex.offset-sm5 {
		margin-left: 41.66666666666667%;
	}

	.application .flex.offset-sm6 {
		margin-left: 50%;
	}

	.application .flex.offset-sm7 {
		margin-left: 58.333333333333336%;
	}

	.application .flex.offset-sm8 {
		margin-left: 66.66666666666666%;
	}

	.application .flex.offset-sm9 {
		margin-left: 75%;
	}

	.application .flex.offset-sm10 {
		margin-left: 83.33333333333334%;
	}

	.application .flex.offset-sm11 {
		margin-left: 91.66666666666666%;
	}

	.application .flex.offset-sm12 {
		margin-left: 100%;
	}
}

@media all and (min-width: 960px) {
	.application .flex.md1 {
		-ms-flex-preferred-size: 8.333333333333332%;
		    flex-basis: 8.333333333333332%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 8.333333333333332%;
	}

	.application .flex.order-md1 {
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
	}

	.application .flex.md2 {
		-ms-flex-preferred-size: 16.666666666666664%;
		    flex-basis: 16.666666666666664%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 16.666666666666664%;
	}

	.application .flex.order-md2 {
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
	}

	.application .flex.md3 {
		-ms-flex-preferred-size: 25%;
		    flex-basis: 25%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 25%;
	}

	.application .flex.order-md3 {
		-webkit-box-ordinal-group: 4;
		    -ms-flex-order: 3;
		        order: 3;
	}

	.application .flex.md4 {
		-ms-flex-preferred-size: 33.33333333333333%;
		    flex-basis: 33.33333333333333%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 33.33333333333333%;
	}

	.application .flex.order-md4 {
		-webkit-box-ordinal-group: 5;
		    -ms-flex-order: 4;
		        order: 4;
	}

	.application .flex.md5 {
		-ms-flex-preferred-size: 41.66666666666667%;
		    flex-basis: 41.66666666666667%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 41.66666666666667%;
	}

	.application .flex.order-md5 {
		-webkit-box-ordinal-group: 6;
		    -ms-flex-order: 5;
		        order: 5;
	}

	.application .flex.md6 {
		-ms-flex-preferred-size: 50%;
		    flex-basis: 50%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 50%;
	}

	.application .flex.order-md6 {
		-webkit-box-ordinal-group: 7;
		    -ms-flex-order: 6;
		        order: 6;
	}

	.application .flex.md7 {
		-ms-flex-preferred-size: 58.333333333333336%;
		    flex-basis: 58.333333333333336%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 58.333333333333336%;
	}

	.application .flex.order-md7 {
		-webkit-box-ordinal-group: 8;
		    -ms-flex-order: 7;
		        order: 7;
	}

	.application .flex.md8 {
		-ms-flex-preferred-size: 66.66666666666666%;
		    flex-basis: 66.66666666666666%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 66.66666666666666%;
	}

	.application .flex.order-md8 {
		-webkit-box-ordinal-group: 9;
		    -ms-flex-order: 8;
		        order: 8;
	}

	.application .flex.md9 {
		-ms-flex-preferred-size: 75%;
		    flex-basis: 75%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 75%;
	}

	.application .flex.order-md9 {
		-webkit-box-ordinal-group: 10;
		    -ms-flex-order: 9;
		        order: 9;
	}

	.application .flex.md10 {
		-ms-flex-preferred-size: 83.33333333333334%;
		    flex-basis: 83.33333333333334%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 83.33333333333334%;
	}

	.application .flex.order-md10 {
		-webkit-box-ordinal-group: 11;
		    -ms-flex-order: 10;
		        order: 10;
	}

	.application .flex.md11 {
		-ms-flex-preferred-size: 91.66666666666666%;
		    flex-basis: 91.66666666666666%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 91.66666666666666%;
	}

	.application .flex.order-md11 {
		-webkit-box-ordinal-group: 12;
		    -ms-flex-order: 11;
		        order: 11;
	}

	.application .flex.md12 {
		-ms-flex-preferred-size: 100%;
		    flex-basis: 100%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 100%;
	}

	.application .flex.order-md12 {
		-webkit-box-ordinal-group: 13;
		    -ms-flex-order: 12;
		        order: 12;
	}

	.application .flex.offset-md0 {
		margin-left: 0%;
	}

	.application .flex.offset-md1 {
		margin-left: 8.333333333333332%;
	}

	.application .flex.offset-md2 {
		margin-left: 16.666666666666664%;
	}

	.application .flex.offset-md3 {
		margin-left: 25%;
	}

	.application .flex.offset-md4 {
		margin-left: 33.33333333333333%;
	}

	.application .flex.offset-md5 {
		margin-left: 41.66666666666667%;
	}

	.application .flex.offset-md6 {
		margin-left: 50%;
	}

	.application .flex.offset-md7 {
		margin-left: 58.333333333333336%;
	}

	.application .flex.offset-md8 {
		margin-left: 66.66666666666666%;
	}

	.application .flex.offset-md9 {
		margin-left: 75%;
	}

	.application .flex.offset-md10 {
		margin-left: 83.33333333333334%;
	}

	.application .flex.offset-md11 {
		margin-left: 91.66666666666666%;
	}

	.application .flex.offset-md12 {
		margin-left: 100%;
	}
}

@media all and (min-width: 1264px) {
	.application .flex.lg1 {
		-ms-flex-preferred-size: 8.333333333333332%;
		    flex-basis: 8.333333333333332%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 8.333333333333332%;
	}

	.application .flex.order-lg1 {
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
	}

	.application .flex.lg2 {
		-ms-flex-preferred-size: 16.666666666666664%;
		    flex-basis: 16.666666666666664%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 16.666666666666664%;
	}

	.application .flex.order-lg2 {
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
	}

	.application .flex.lg3 {
		-ms-flex-preferred-size: 25%;
		    flex-basis: 25%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 25%;
	}

	.application .flex.order-lg3 {
		-webkit-box-ordinal-group: 4;
		    -ms-flex-order: 3;
		        order: 3;
	}

	.application .flex.lg4 {
		-ms-flex-preferred-size: 33.33333333333333%;
		    flex-basis: 33.33333333333333%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 33.33333333333333%;
	}

	.application .flex.order-lg4 {
		-webkit-box-ordinal-group: 5;
		    -ms-flex-order: 4;
		        order: 4;
	}

	.application .flex.lg5 {
		-ms-flex-preferred-size: 41.66666666666667%;
		    flex-basis: 41.66666666666667%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 41.66666666666667%;
	}

	.application .flex.order-lg5 {
		-webkit-box-ordinal-group: 6;
		    -ms-flex-order: 5;
		        order: 5;
	}

	.application .flex.lg6 {
		-ms-flex-preferred-size: 50%;
		    flex-basis: 50%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 50%;
	}

	.application .flex.order-lg6 {
		-webkit-box-ordinal-group: 7;
		    -ms-flex-order: 6;
		        order: 6;
	}

	.application .flex.lg7 {
		-ms-flex-preferred-size: 58.333333333333336%;
		    flex-basis: 58.333333333333336%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 58.333333333333336%;
	}

	.application .flex.order-lg7 {
		-webkit-box-ordinal-group: 8;
		    -ms-flex-order: 7;
		        order: 7;
	}

	.application .flex.lg8 {
		-ms-flex-preferred-size: 66.66666666666666%;
		    flex-basis: 66.66666666666666%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 66.66666666666666%;
	}

	.application .flex.order-lg8 {
		-webkit-box-ordinal-group: 9;
		    -ms-flex-order: 8;
		        order: 8;
	}

	.application .flex.lg9 {
		-ms-flex-preferred-size: 75%;
		    flex-basis: 75%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 75%;
	}

	.application .flex.order-lg9 {
		-webkit-box-ordinal-group: 10;
		    -ms-flex-order: 9;
		        order: 9;
	}

	.application .flex.lg10 {
		-ms-flex-preferred-size: 83.33333333333334%;
		    flex-basis: 83.33333333333334%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 83.33333333333334%;
	}

	.application .flex.order-lg10 {
		-webkit-box-ordinal-group: 11;
		    -ms-flex-order: 10;
		        order: 10;
	}

	.application .flex.lg11 {
		-ms-flex-preferred-size: 91.66666666666666%;
		    flex-basis: 91.66666666666666%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 91.66666666666666%;
	}

	.application .flex.order-lg11 {
		-webkit-box-ordinal-group: 12;
		    -ms-flex-order: 11;
		        order: 11;
	}

	.application .flex.lg12 {
		-ms-flex-preferred-size: 100%;
		    flex-basis: 100%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 100%;
	}

	.application .flex.order-lg12 {
		-webkit-box-ordinal-group: 13;
		    -ms-flex-order: 12;
		        order: 12;
	}

	.application .flex.offset-lg0 {
		margin-left: 0%;
	}

	.application .flex.offset-lg1 {
		margin-left: 8.333333333333332%;
	}

	.application .flex.offset-lg2 {
		margin-left: 16.666666666666664%;
	}

	.application .flex.offset-lg3 {
		margin-left: 25%;
	}

	.application .flex.offset-lg4 {
		margin-left: 33.33333333333333%;
	}

	.application .flex.offset-lg5 {
		margin-left: 41.66666666666667%;
	}

	.application .flex.offset-lg6 {
		margin-left: 50%;
	}

	.application .flex.offset-lg7 {
		margin-left: 58.333333333333336%;
	}

	.application .flex.offset-lg8 {
		margin-left: 66.66666666666666%;
	}

	.application .flex.offset-lg9 {
		margin-left: 75%;
	}

	.application .flex.offset-lg10 {
		margin-left: 83.33333333333334%;
	}

	.application .flex.offset-lg11 {
		margin-left: 91.66666666666666%;
	}

	.application .flex.offset-lg12 {
		margin-left: 100%;
	}
}

@media all and (min-width: 1904px) {
	.application .flex.xl1 {
		-ms-flex-preferred-size: 8.333333333333332%;
		    flex-basis: 8.333333333333332%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 8.333333333333332%;
	}

	.application .flex.order-xl1 {
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
	}

	.application .flex.xl2 {
		-ms-flex-preferred-size: 16.666666666666664%;
		    flex-basis: 16.666666666666664%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 16.666666666666664%;
	}

	.application .flex.order-xl2 {
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
	}

	.application .flex.xl3 {
		-ms-flex-preferred-size: 25%;
		    flex-basis: 25%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 25%;
	}

	.application .flex.order-xl3 {
		-webkit-box-ordinal-group: 4;
		    -ms-flex-order: 3;
		        order: 3;
	}

	.application .flex.xl4 {
		-ms-flex-preferred-size: 33.33333333333333%;
		    flex-basis: 33.33333333333333%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 33.33333333333333%;
	}

	.application .flex.order-xl4 {
		-webkit-box-ordinal-group: 5;
		    -ms-flex-order: 4;
		        order: 4;
	}

	.application .flex.xl5 {
		-ms-flex-preferred-size: 41.66666666666667%;
		    flex-basis: 41.66666666666667%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 41.66666666666667%;
	}

	.application .flex.order-xl5 {
		-webkit-box-ordinal-group: 6;
		    -ms-flex-order: 5;
		        order: 5;
	}

	.application .flex.xl6 {
		-ms-flex-preferred-size: 50%;
		    flex-basis: 50%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 50%;
	}

	.application .flex.order-xl6 {
		-webkit-box-ordinal-group: 7;
		    -ms-flex-order: 6;
		        order: 6;
	}

	.application .flex.xl7 {
		-ms-flex-preferred-size: 58.333333333333336%;
		    flex-basis: 58.333333333333336%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 58.333333333333336%;
	}

	.application .flex.order-xl7 {
		-webkit-box-ordinal-group: 8;
		    -ms-flex-order: 7;
		        order: 7;
	}

	.application .flex.xl8 {
		-ms-flex-preferred-size: 66.66666666666666%;
		    flex-basis: 66.66666666666666%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 66.66666666666666%;
	}

	.application .flex.order-xl8 {
		-webkit-box-ordinal-group: 9;
		    -ms-flex-order: 8;
		        order: 8;
	}

	.application .flex.xl9 {
		-ms-flex-preferred-size: 75%;
		    flex-basis: 75%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 75%;
	}

	.application .flex.order-xl9 {
		-webkit-box-ordinal-group: 10;
		    -ms-flex-order: 9;
		        order: 9;
	}

	.application .flex.xl10 {
		-ms-flex-preferred-size: 83.33333333333334%;
		    flex-basis: 83.33333333333334%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 83.33333333333334%;
	}

	.application .flex.order-xl10 {
		-webkit-box-ordinal-group: 11;
		    -ms-flex-order: 10;
		        order: 10;
	}

	.application .flex.xl11 {
		-ms-flex-preferred-size: 91.66666666666666%;
		    flex-basis: 91.66666666666666%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 91.66666666666666%;
	}

	.application .flex.order-xl11 {
		-webkit-box-ordinal-group: 12;
		    -ms-flex-order: 11;
		        order: 11;
	}

	.application .flex.xl12 {
		-ms-flex-preferred-size: 100%;
		    flex-basis: 100%;
		-webkit-box-flex: 0;
		    -ms-flex-positive: 0;
		        flex-grow: 0;
		max-width: 100%;
	}

	.application .flex.order-xl12 {
		-webkit-box-ordinal-group: 13;
		    -ms-flex-order: 12;
		        order: 12;
	}

	.application .flex.offset-xl0 {
		margin-left: 0%;
	}

	.application .flex.offset-xl1 {
		margin-left: 8.333333333333332%;
	}

	.application .flex.offset-xl2 {
		margin-left: 16.666666666666664%;
	}

	.application .flex.offset-xl3 {
		margin-left: 25%;
	}

	.application .flex.offset-xl4 {
		margin-left: 33.33333333333333%;
	}

	.application .flex.offset-xl5 {
		margin-left: 41.66666666666667%;
	}

	.application .flex.offset-xl6 {
		margin-left: 50%;
	}

	.application .flex.offset-xl7 {
		margin-left: 58.333333333333336%;
	}

	.application .flex.offset-xl8 {
		margin-left: 66.66666666666666%;
	}

	.application .flex.offset-xl9 {
		margin-left: 75%;
	}

	.application .flex.offset-xl10 {
		margin-left: 83.33333333333334%;
	}

	.application .flex.offset-xl11 {
		margin-left: 91.66666666666666%;
	}

	.application .flex.offset-xl12 {
		margin-left: 100%;
	}
}

.application .v-content {
	-webkit-transition: none;
	transition: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	max-width: 100%;
}

.application .v-content[data-booted="true"] {
	-webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.application .v-content__wrap {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	max-width: 100%;
	position: relative;
}

.application .theme--light.v-jumbotron .v-jumbotron__content {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--dark.v-jumbotron .v-jumbotron__content {
	color: #fff;
}

.application .v-jumbotron {
	display: block;
	top: 0;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 100%;
}

.application .v-jumbotron__wrapper {
	height: 100%;
	overflow: hidden;
	position: relative;
	-webkit-transition: inherit;
	transition: inherit;
	width: 100%;
}

.application .v-jumbotron__background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	contain: strict;
	-webkit-transition: inherit;
	transition: inherit;
}

.application .v-jumbotron__image {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	min-width: 100%;
	will-change: transform;
	-webkit-transition: inherit;
	transition: inherit;
}

.application .v-jumbotron__content {
	height: 100%;
	position: relative;
	-webkit-transition: inherit;
	transition: inherit;
}

.application .theme--light.v-navigation-drawer {
	background-color: #fff;
}

.application .theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
	background-color: rgba(0, 0, 0, 0.12);
}

.application .theme--light.v-navigation-drawer .v-divider {
	border-color: rgba(0, 0, 0, 0.12);
}

.application .theme--dark.v-navigation-drawer {
	background-color: #424242;
}

.application .theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
	background-color: rgba(255, 255, 255, 0.12);
}

.application .theme--dark.v-navigation-drawer .v-divider {
	border-color: rgba(255, 255, 255, 0.12);
}

.application .v-navigation-drawer {
	-webkit-transition: none;
	transition: none;
	display: block;
	left: 0;
	max-width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	pointer-events: auto;
	top: 0;
	will-change: transform;
	z-index: 3;
	-webkit-overflow-scrolling: touch;
}

.application .v-navigation-drawer[data-booted="true"] {
	-webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.application .v-navigation-drawer[data-booted="true"] {
	transition-property: width, -webkit-transform;
	-webkit-transition-property: width, -webkit-transform;
	transition-property: transform, width;
	transition-property: transform, width, -webkit-transform;
}

.application .v-navigation-drawer__border {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 1px;
}

.application .v-navigation-drawer.v-navigation-drawer--right:after {
	left: 0;
	right: initial;
}

.application .v-navigation-drawer--right {
	left: auto;
	right: 0;
}

.application .v-navigation-drawer--right > .v-navigation-drawer__border {
	right: auto;
	left: 0;
}

.application .v-navigation-drawer--absolute {
	position: absolute;
}

.application .v-navigation-drawer--fixed {
	position: fixed;
}

.application .v-navigation-drawer--floating:after {
	display: none;
}

.application .v-navigation-drawer--mini-variant {
	overflow: hidden;
}

.application .v-navigation-drawer--mini-variant .v-list__group__header__prepend-icon {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	width: 100%;
}

.application .v-navigation-drawer--mini-variant .v-list__tile__action,
  .application .v-navigation-drawer--mini-variant .v-list__tile__avatar {
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	min-width: 48px;
}

.application .v-navigation-drawer--mini-variant .v-list__tile__content,
  .application .v-navigation-drawer--mini-variant .v-list__tile:after {
	opacity: 0;
}

.application .v-navigation-drawer--mini-variant .v-subheader,
  .application .v-navigation-drawer--mini-variant .v-divider,
  .application .v-navigation-drawer--mini-variant .v-list--group {
	display: none !important;
}

.application .v-navigation-drawer--temporary,
  .application .v-navigation-drawer--is-mobile {
	z-index: 6;
}

.application .v-navigation-drawer--temporary:not(.v-navigation-drawer--close),
  .application .v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close) {
	-webkit-box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.application .v-navigation-drawer .v-list {
	background: inherit;
}

.application .v-navigation-drawer > .v-list .v-list__tile {
	-webkit-transition: none;
	transition: none;
	font-weight: 500;
}

.application .v-navigation-drawer > .v-list .v-list__tile--active .v-list__tile__title {
	color: inherit;
}

.application .v-navigation-drawer > .v-list .v-list--group .v-list__tile {
	font-weight: 400;
}

.application .v-navigation-drawer > .v-list .v-list--group__header--active:after {
	background: transparent;
}

.application .v-navigation-drawer > .v-list:not(.v-list--dense) .v-list__tile {
	font-size: 14px;
}

.application .theme--light.v-pagination .v-pagination__item {
	background: #fff;
	color: #000;
}

.application .theme--light.v-pagination .v-pagination__item--active {
	color: #fff;
}

.application .theme--light.v-pagination .v-pagination__navigation {
	background: #fff;
}

.application .theme--light.v-pagination .v-pagination__navigation .v-icon {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--dark.v-pagination .v-pagination__item {
	background: #424242;
	color: #fff;
}

.application .theme--dark.v-pagination .v-pagination__item--active {
	color: #fff;
}

.application .theme--dark.v-pagination .v-pagination__navigation {
	background: #424242;
}

.application .theme--dark.v-pagination .v-pagination__navigation .v-icon {
	color: #fff;
}

.application .v-pagination {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	list-style-type: none;
	margin: 0;
	max-width: 100%;
	padding: 0;
}

.application .v-pagination > li {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.application .v-pagination--circle .v-pagination__item,
  .application .v-pagination--circle .v-pagination__more,
  .application .v-pagination--circle .v-pagination__navigation {
	border-radius: 50%;
}

.application .v-pagination--disabled {
	pointer-events: none;
	opacity: 0.6;
}

.application .v-pagination__item {
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-size: 14px;
	background: transparent;
	height: 34px;
	width: 34px;
	margin: 0.3rem;
	text-decoration: none;
	-webkit-transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
	transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.application .v-pagination__item--active {
	-webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.application .v-pagination__navigation {
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	text-decoration: none;
	height: 2rem;
	border-radius: 4px;
	width: 2rem;
	margin: 0.3rem 10px;
}

.application .v-pagination__navigation .v-icon {
	font-size: 2rem;
	-webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
	transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
	vertical-align: middle;
}

.application .v-pagination__navigation--disabled {
	opacity: 0.6;
	pointer-events: none;
}

.application .v-pagination__more {
	margin: 0.3rem;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: end;
	    -ms-flex-align: end;
	        align-items: flex-end;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	height: 2rem;
	width: 2rem;
}

.application .v-parallax {
	position: relative;
	overflow: hidden;
	z-index: 0;
}

.application .v-parallax__image-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	contain: strict;
}

.application .v-parallax__image {
	position: absolute;
	bottom: 0;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	display: none;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	will-change: transform;
	-webkit-transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s opacity cubic-bezier(0.25, 0.8, 0.5, 1);
	z-index: 1;
}

.application .v-parallax__content {
	color: #fff;
	height: 100%;
	z-index: 2;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	padding: 0 1rem;
}

.application .v-input--radio-group .v-radio {
	margin-bottom: 8px;
}

.application .v-input--radio-group__input {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.application .v-input--radio-group__input > .v-label {
	padding-bottom: 8px;
}

.application .v-input--radio-group--row .v-input--radio-group__input {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}

.application .v-input--radio-group--column .v-input--radio-group__input {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

.application .theme--light.v-radio--is-disabled label {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-radio--is-disabled .v-icon {
	color: rgba(0, 0, 0, 0.26) !important;
}

.application .theme--dark.v-radio--is-disabled label {
	color: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-radio--is-disabled .v-icon {
	color: rgba(255, 255, 255, 0.3) !important;
}

.application .v-radio {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-right: 16px;
	outline: none;
}

.application .v-radio--is-disabled {
	pointer-events: none;
}

.application .theme--light.v-input--range-slider.v-input--slider.v-input--is-disabled .v-slider.v-slider .v-slider__thumb {
	background: #bdbdbd;
}

.application .theme--dark.v-input--range-slider.v-input--slider.v-input--is-disabled .v-slider.v-slider .v-slider__thumb {
	background: #424242;
}

.application .v-input--range-slider.v-input--is-disabled .v-slider__track-fill {
	display: none;
}

.application .v-input--range-slider.v-input--is-disabled.v-input--slider .v-slider.v-slider .v-slider__thumb {
	border-color: transparent;
}

.application .theme--light.v-input--slider .v-slider__track,
  .application .theme--light.v-input--slider .v-slider__track-fill {
	background: rgba(0, 0, 0, 0.26);
}

.application .theme--light.v-input--slider .v-slider__track__container:after {
	border: 1px solid rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-input--slider .v-slider__ticks {
	border-color: rgba(0, 0, 0, 0.87);
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb-label {
	background: rgba(0, 0, 0, 0.26);
}

.application .theme--light.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb {
	border: 3px solid rgba(0, 0, 0, 0.26);
}

.application .theme--light.v-input--slider:not(.v-input--is-dirty).v-input--slider--is-active .v-slider__thumb {
	border: 3px solid rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-input--slider.v-input--is-disabled .v-slider__thumb {
	border: 5px solid rgba(0, 0, 0, 0.26);
}

.application .theme--light.v-input--slider.v-input--is-disabled.v-input--is-dirty .v-slider__thumb {
	background: rgba(0, 0, 0, 0.26);
}

.application .theme--light.v-input--slider.v-input--slider--is-active .v-slider__track {
	background: rgba(0, 0, 0, 0.38);
}

.application .theme--dark.v-input--slider .v-slider__track,
  .application .theme--dark.v-input--slider .v-slider__track-fill {
	background: rgba(255, 255, 255, 0.2);
}

.application .theme--dark.v-input--slider .v-slider__track__container:after {
	border: 1px solid #fff;
}

.application .theme--dark.v-input--slider .v-slider__ticks {
	border-color: #fff;
	color: rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb-label {
	background: rgba(255, 255, 255, 0.2);
}

.application .theme--dark.v-input--slider:not(.v-input--is-dirty) .v-slider__thumb {
	border: 3px solid rgba(255, 255, 255, 0.2);
}

.application .theme--dark.v-input--slider:not(.v-input--is-dirty).v-input--slider--is-active .v-slider__thumb {
	border: 3px solid rgba(255, 255, 255, 0.3);
}

.application .theme--dark.v-input--slider.v-input--is-disabled .v-slider__thumb {
	border: 5px solid rgba(255, 255, 255, 0.2);
}

.application .theme--dark.v-input--slider.v-input--is-disabled.v-input--is-dirty .v-slider__thumb {
	background: rgba(255, 255, 255, 0.2);
}

.application .theme--dark.v-input--slider.v-input--slider--is-active .v-slider__track {
	background: rgba(255, 255, 255, 0.3);
}

.application .application--is-rtl .v-input--slider .v-label {
	margin-left: 16px;
	margin-right: 0;
}

.application .v-input--slider {
	margin-top: 16px;
}

.application .v-input--slider.v-input--is-focused .v-slider__thumb-container--is-active:not(.v-slider__thumb-container--show-label):before {
	opacity: 0.2;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.application .v-input--slider.v-input--is-focused .v-slider__track {
	-webkit-transition: none;
	transition: none;
}

.application .v-input--slider.v-input--is-focused.v-input--slider--ticks .v-slider__track__container:after,
  .application .v-input--slider.v-input--is-focused.v-input--slider--ticks .v-slider .v-slider__tick {
	opacity: 1;
}

.application .v-input--slider.v-input--slider--ticks .v-slider__ticks.v-slider__ticks--always-show {
	opacity: 1;
}

.application .v-input--slider.v-input--slider--ticks-labels .v-input__slot {
	margin-bottom: 16px;
}

.application .v-input--slider.v-input--is-readonly {
	pointer-events: none;
}

.application .v-input--slider.v-input--is-disabled .v-slider__thumb {
	-webkit-transform: translateY(-50%) scale(0.45);
	transform: translateY(-50%) scale(0.45);
}

.application .v-input--slider.v-input--is-disabled.v-input--is-dirty .v-slider__thumb {
	border: 0px solid transparent;
}

.application .v-input--slider .v-input__slot > *:first-child:not(:only-child) {
	margin-right: 16px;
}

.application .v-slider {
	cursor: default;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	position: relative;
	height: 32px;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	outline: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-slider input {
	cursor: default;
	opacity: 0;
	padding: 0;
	width: 100%;
}

.application .v-slider__track__container {
	height: 2px;
	left: 0;
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 100%;
}

.application .v-slider__track__container:after {
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	height: 2px;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: 2px;
	opacity: 0;
}

.application .v-slider__track,
  .application .v-slider__thumb,
  .application .v-slider__ticks {
	position: absolute;
	top: 0;
}

.application .v-slider__track {
	height: 2px;
	left: 0;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	-webkit-transform-origin: right;
	transform-origin: right;
	overflow: hidden;
	width: 100%;
}

.application .v-slider__track-fill {
	position: absolute;
	left: 0;
	height: 2px;
	-webkit-transform-origin: left;
	transform-origin: left;
	width: 100%;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-slider__ticks-container {
	position: absolute;
	left: 0;
	height: 2px;
	width: 100%;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

.application .v-slider__ticks {
	opacity: 0;
	border-style: solid;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-slider__ticks > span {
	position: absolute;
	top: 8px;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-slider__ticks:first-child > span {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.application .v-slider__ticks:last-child > span {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}

.application .v-slider:not(.v-input--is-dirty) .v-slider__ticks:first-child {
	border-color: transparent;
}

.application .v-slider__thumb-container {
	position: absolute;
	top: 50%;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-slider__thumb-container:before {
	content: '';
	color: inherit;
	border-radius: 50%;
	background: currentColor;
	height: 32px;
	left: -16px;
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	top: -16px;
	-webkit-transform: scale(0.2);
	transform: scale(0.2);
	-webkit-transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
	transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
	width: 32px;
	will-change: transform, opacity;
}

.application .v-slider__thumb {
	width: 24px;
	height: 24px;
	left: -12px;
	top: 50%;
	border-radius: 50%;
	background: transparent;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	-webkit-transform: translateY(-50%) scale(0.6);
	transform: translateY(-50%) scale(0.6);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-slider--is-active .v-slider__thumb-container--is-active .v-slider__thumb {
	-webkit-transform: translateY(-50%) scale(1);
	transform: translateY(-50%) scale(1);
}

.application .v-slider--is-active .v-slider__thumb-container--is-active.v-slider__thumb-container--show-label .v-slider__thumb {
	-webkit-transform: translateY(-50%) scale(0);
	transform: translateY(-50%) scale(0);
}

.application .v-slider--is-active .v-slider__ticks-container .v-slider__ticks {
	opacity: 1;
}

.application .v-slider__thumb-label__container {
	position: absolute;
	left: 0;
	top: 0;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.application .v-slider__thumb-label {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	font-size: 12px;
	color: #fff;
	width: 32px;
	height: 32px;
	border-radius: 50% 50% 0;
	position: absolute;
	left: 0;
	bottom: 100%;
	-webkit-transform: translateY(-20%) translateY(-12px) translateX(-50%) rotate(45deg);
	transform: translateY(-20%) translateY(-12px) translateX(-50%) rotate(45deg);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.application .v-slider__thumb-label > * {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.application .v-slider__track,
  .application .v-slider__track-fill {
	position: absolute;
}

.application .v-rating .v-icon {
	padding: 0.5rem;
	border-radius: 50%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-rating--readonly .v-icon {
	pointer-events: none;
}

.application .v-rating--dense .v-icon {
	padding: 0.1rem;
}

.application .v-snack {
	position: fixed;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	color: #fff;
	pointer-events: none;
	z-index: 1000;
	font-size: 14px;
	left: 0;
	right: 0;
}

.application .v-snack--absolute {
	position: absolute;
}

.application .v-snack--top {
	top: 0;
}

.application .v-snack--bottom {
	bottom: 0;
}

.application .v-snack__wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	background-color: #323232;
	pointer-events: auto;
	width: 100%;
	-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.application .v-snack__content {
	width: 100%;
	height: 48px;
	padding: 14px 24px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	overflow: hidden;
}

.application .v-snack__content .v-btn {
	color: #fff;
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 auto;
	        flex: 0 0 auto;
	padding: 8px;
	margin: 0 0 0 24px;
	height: auto;
	min-width: auto;
	width: auto;
}

.application .v-snack__content .v-btn__content {
	margin: -2px;
}

.application .v-snack__content .v-btn::before {
	display: none;
}

.application .v-snack--multi-line .v-snack__content {
	height: 80px;
	padding: 24px;
}

.application .v-snack--vertical .v-snack__content {
	height: 112px;
	padding: 24px 24px 14px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
}

.application .v-snack--vertical .v-snack__content .v-btn.v-btn {
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
	padding: 0;
	margin-left: 0;
	margin-top: 24px;
}

.application .v-snack--vertical .v-snack__content .v-btn__content {
	-webkit-box-flex: 0;
	    -ms-flex: 0 0 auto;
	        flex: 0 0 auto;
	margin: 0;
}

.application .v-snack--auto-height .v-snack__content {
	height: auto;
}

.application .v-snack-transition-enter-active,
  .application .v-snack-transition-leave-active {
	transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
	-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-snack-transition-enter-active .v-snack__content,
  .application .v-snack-transition-leave-active .v-snack__content {
	-webkit-transition: opacity 0.3s linear 0.1s;
	transition: opacity 0.3s linear 0.1s;
}

.application .v-snack-transition-enter .v-snack__content {
	opacity: 0;
}

.application .v-snack-transition-enter-to .v-snack__content,
  .application .v-snack-transition-leave .v-snack__content {
	opacity: 1;
}

.application .v-snack-transition-enter.v-snack.v-snack--top,
  .application .v-snack-transition-leave-to.v-snack.v-snack--top {
	-webkit-transform: translateY(calc(-100% - 8px));
	transform: translateY(calc(-100% - 8px));
}

.application .v-snack-transition-enter.v-snack.v-snack--bottom,
  .application .v-snack-transition-leave-to.v-snack.v-snack--bottom {
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}

@media only screen and (min-width: 600px) {
	.application .v-snack__wrapper {
		width: auto;
		max-width: 568px;
		min-width: 288px;
		margin: 0 auto;
		border-radius: 2px;
	}

	.application .v-snack--left .v-snack__wrapper {
		margin-left: 0;
	}

	.application .v-snack--right .v-snack__wrapper {
		margin-right: 0;
	}

	.application .v-snack--left,
    .application .v-snack--right {
		margin: 0 24px;
	}

	.application .v-snack--left.v-snack--top,
    .application .v-snack--right.v-snack--top {
		-webkit-transform: translateY(24px);
		transform: translateY(24px);
	}

	.application .v-snack--left.v-snack--bottom,
    .application .v-snack--right.v-snack--bottom {
		-webkit-transform: translateY(-24px);
		transform: translateY(-24px);
	}

	.application .v-snack__content .v-btn:first-of-type {
		margin-left: 42px;
	}
}

.application .v-speed-dial {
	position: relative;
}

.application .v-speed-dial--absolute {
	position: absolute;
}

.application .v-speed-dial--fixed {
	position: fixed;
}

.application .v-speed-dial--fixed,
  .application .v-speed-dial--absolute {
	z-index: 4;
}

.application .v-speed-dial--fixed > .v-btn--floating,
  .application .v-speed-dial--absolute > .v-btn--floating {
	margin: 0;
}

.application .v-speed-dial--top:not(.v-speed-dial--absolute) {
	top: 16px;
}

.application .v-speed-dial--top.v-speed-dial--absolute {
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.application .v-speed-dial--bottom:not(.v-speed-dial--absolute) {
	bottom: 16px;
}

.application .v-speed-dial--bottom.v-speed-dial--absolute {
	bottom: 50%;
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
}

.application .v-speed-dial--left {
	left: 16px;
}

.application .v-speed-dial--right {
	right: 16px;
}

.application .v-speed-dial--direction-left .v-speed-dial__list,
  .application .v-speed-dial--direction-right .v-speed-dial__list {
	height: 100%;
	top: 0;
}

.application .v-speed-dial--direction-top .v-speed-dial__list,
  .application .v-speed-dial--direction-bottom .v-speed-dial__list {
	left: 0;
	width: 100%;
}

.application .v-speed-dial--direction-top .v-speed-dial__list {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: column-reverse;
	        flex-direction: column-reverse;
	bottom: 100%;
}

.application .v-speed-dial--direction-right .v-speed-dial__list {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	left: 100%;
}

.application .v-speed-dial--direction-bottom .v-speed-dial__list {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	top: 100%;
}

.application .v-speed-dial--direction-left .v-speed-dial__list {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	right: 100%;
}

.application .v-speed-dial__list {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	position: absolute;
}

.application .v-speed-dial__list .v-btn:nth-child(1) {
	-webkit-transition-delay: 0.05s;
	        transition-delay: 0.05s;
}

.application .v-speed-dial__list .v-btn:nth-child(2) {
	-webkit-transition-delay: 0.1s;
	        transition-delay: 0.1s;
}

.application .v-speed-dial__list .v-btn:nth-child(3) {
	-webkit-transition-delay: 0.15s;
	        transition-delay: 0.15s;
}

.application .v-speed-dial__list .v-btn:nth-child(4) {
	-webkit-transition-delay: 0.2s;
	        transition-delay: 0.2s;
}

.application .v-speed-dial__list .v-btn:nth-child(5) {
	-webkit-transition-delay: 0.25s;
	        transition-delay: 0.25s;
}

.application .v-speed-dial__list .v-btn:nth-child(6) {
	-webkit-transition-delay: 0.3s;
	        transition-delay: 0.3s;
}

.application .v-speed-dial__list .v-btn:nth-child(7) {
	-webkit-transition-delay: 0.35s;
	        transition-delay: 0.35s;
}

.application .theme--light.v-stepper {
	background: #fff;
}

.application .theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
	background: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-stepper .v-stepper__step__step {
	color: #fff;
}

.application .theme--light.v-stepper .v-stepper__step__step .v-icon {
	color: #fff;
}

.application .theme--light.v-stepper .v-stepper__header .v-divider {
	border-color: rgba(0, 0, 0, 0.12);
}

.application .theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
	text-shadow: 0px 0px 0px #000;
}

.application .theme--light.v-stepper .v-stepper__step--editable:hover {
	background: rgba(0, 0, 0, 0.06);
}

.application .theme--light.v-stepper .v-stepper__step--editable:hover .v-stepper__label {
	text-shadow: 0px 0px 0px #000;
}

.application .theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error):hover .v-stepper__step__step {
	background: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-stepper .v-stepper__label {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-stepper .v-stepper__label small {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-stepper--non-linear .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__label {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) {
	border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.application .theme--dark.v-stepper {
	background: #303030;
}

.application .theme--dark.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
	background: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-stepper .v-stepper__step__step {
	color: #fff;
}

.application .theme--dark.v-stepper .v-stepper__step__step .v-icon {
	color: #fff;
}

.application .theme--dark.v-stepper .v-stepper__header .v-divider {
	border-color: rgba(255, 255, 255, 0.12);
}

.application .theme--dark.v-stepper .v-stepper__step--active .v-stepper__label {
	text-shadow: 0px 0px 0px #fff;
}

.application .theme--dark.v-stepper .v-stepper__step--editable:hover {
	background: rgba(255, 255, 255, 0.06);
}

.application .theme--dark.v-stepper .v-stepper__step--editable:hover .v-stepper__label {
	text-shadow: 0px 0px 0px #fff;
}

.application .theme--dark.v-stepper .v-stepper__step--complete .v-stepper__label {
	color: rgba(255, 255, 255, 0.87);
}

.application .theme--dark.v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error):hover .v-stepper__step__step {
	background: rgba(255, 255, 255, 0.75);
}

.application .theme--dark.v-stepper .v-stepper__label {
	color: rgba(255, 255, 255, 0.5);
}

.application .theme--dark.v-stepper .v-stepper__label small {
	color: rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-stepper--non-linear .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__label {
	color: rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-stepper--vertical .v-stepper__content:not(:last-child) {
	border-left: 1px solid rgba(255, 255, 255, 0.12);
}

.application .v-stepper {
	overflow: hidden;
	position: relative;
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.application .v-stepper__header {
	height: 72px;
	-webkit-box-align: stretch;
	    -ms-flex-align: stretch;
	        align-items: stretch;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.application .v-stepper__header .v-divider {
	-ms-flex-item-align: center;
	    align-self: center;
	margin: 0 -16px;
}

.application .v-stepper__items {
	position: relative;
	overflow: hidden;
}

.application .v-stepper__step__step {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	border-radius: 50%;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 12px;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	height: 24px;
	margin-right: 8px;
	min-width: 24px;
	width: 24px;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.application .v-stepper__step__step .v-icon {
	font-size: 18px;
}

.application .v-stepper__step {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	padding: 24px;
	position: relative;
}

.application .v-stepper__step--active .v-stepper__label {
	-webkit-transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
	transition: 0.3s cubic-bezier(0.4, 0, 0.6, 1);
}

.application .v-stepper__step--editable {
	cursor: pointer;
}

.application .v-stepper__step.v-stepper__step--error .v-stepper__step__step {
	background: transparent;
	color: inherit;
}

.application .v-stepper__step.v-stepper__step--error .v-stepper__step__step .v-icon {
	font-size: 24px;
	color: inherit;
}

.application .v-stepper__step.v-stepper__step--error .v-stepper__label {
	color: inherit;
	text-shadow: none;
	font-weight: 500;
}

.application .v-stepper__step.v-stepper__step--error .v-stepper__label small {
	color: inherit;
}

.application .v-stepper__label {
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	text-align: left;
}

.application .v-stepper__label small {
	font-size: 12px;
	font-weight: 300;
	text-shadow: none;
}

.application .v-stepper__wrapper {
	overflow: hidden;
	-webkit-transition: none;
	transition: none;
}

.application .v-stepper__content {
	top: 0;
	padding: 24px 24px 16px 24px;
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	width: 100%;
}

.application .v-stepper__content > .v-btn {
	margin: 24px 8px 8px 0;
}

.application .v-stepper--is-booted .v-stepper__content,
  .application .v-stepper--is-booted .v-stepper__wrapper {
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-stepper--vertical {
	padding-bottom: 36px;
}

.application .v-stepper--vertical .v-stepper__content {
	margin: -8px -36px -16px 36px;
	padding: 16px 60px 16px 23px;
	width: auto;
}

.application .v-stepper--vertical .v-stepper__step {
	padding: 24px 24px 16px;
}

.application .v-stepper--vertical .v-stepper__step__step {
	margin-right: 12px;
}

.application .v-stepper--alt-labels .v-stepper__header {
	height: auto;
}

.application .v-stepper--alt-labels .v-stepper__header .v-divider {
	margin: 35px -67px 0;
	-ms-flex-item-align: start;
	    align-self: flex-start;
}

.application .v-stepper--alt-labels .v-stepper__step {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-ms-flex-preferred-size: 175px;
	    flex-basis: 175px;
}

.application .v-stepper--alt-labels .v-stepper__step small {
	-ms-flex-item-align: center;
	    align-self: center;
}

.application .v-stepper--alt-labels .v-stepper__step__step {
	margin-right: 0;
	margin-bottom: 11px;
}

@media only screen and (max-width: 959px) {
	.application .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
		display: none;
	}

	.application .v-stepper:not(.v-stepper--vertical) .v-stepper__step__step {
		margin-right: 0;
	}
}

.application .theme--light.v-input--switch__thumb {
	color: #fafafa;
}

.application .theme--light.v-input--switch__track {
	color: rgba(0, 0, 0, 0.38);
}

.application .theme--light.v-input--switch.v-input--is-disabled .v-input--switch__thumb {
	color: #bdbdbd !important;
}

.application .theme--light.v-input--switch.v-input--is-disabled .v-input--switch__track {
	color: rgba(0, 0, 0, 0.12) !important;
}

.application .theme--dark.v-input--switch__thumb {
	color: #bdbdbd;
}

.application .theme--dark.v-input--switch__track {
	color: rgba(255, 255, 255, 0.3);
}

.application .theme--dark.v-input--switch.v-input--is-disabled .v-input--switch__thumb {
	color: #424242 !important;
}

.application .theme--dark.v-input--switch.v-input--is-disabled .v-input--switch__track {
	color: rgba(255, 255, 255, 0.1) !important;
}

.application .application--is-rtl .v-input--switch .v-input--selection-controls__ripple {
	left: auto;
	right: -14px;
}

.application .application--is-rtl .v-input--switch.v-input--is-dirty .v-input--selection-controls__ripple,
  .application .application--is-rtl .v-input--switch.v-input--is-dirty .v-input--switch__thumb {
	-webkit-transform: translate(-16px, 0);
	transform: translate(-16px, 0);
}

.application .v-input--switch__track,
  .application .v-input--switch__thumb {
	background-color: currentColor;
	pointer-events: none;
	-webkit-transition: inherit;
	transition: inherit;
}

.application .v-input--switch__track {
	border-radius: 8px;
	height: 14px;
	left: 2px;
	opacity: 0.6;
	position: absolute;
	right: 2px;
	top: calc(50% - 7px);
}

.application .v-input--switch__thumb {
	border-radius: 50%;
	top: calc(50% - 10px);
	height: 20px;
	position: relative;
	width: 20px;
	-webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.application .v-input--switch .v-input--selection-controls__input {
	width: 38px;
}

.application .v-input--switch .v-input--selection-controls__ripple {
	left: -14px;
	top: calc(50% - 24px);
}

.application .v-input--switch.v-input--is-dirty .v-input--selection-controls__ripple,
  .application .v-input--switch.v-input--is-dirty .v-input--switch__thumb {
	-webkit-transform: translate(16px, 0);
	transform: translate(16px, 0);
}

.application .theme--light.v-system-bar {
	background-color: #e0e0e0;
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-system-bar .v-icon {
	color: rgba(0, 0, 0, 0.54);
}

.application .theme--light.v-system-bar--lights-out {
	background-color: rgba(255, 255, 255, 0.7) !important;
}

.application .theme--dark.v-system-bar {
	background-color: #000;
	color: rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-system-bar .v-icon {
	color: rgba(255, 255, 255, 0.7);
}

.application .theme--dark.v-system-bar--lights-out {
	background-color: rgba(0, 0, 0, 0.2) !important;
}

.application .v-system-bar {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 14px;
	font-weight: 500;
	padding: 0 8px;
}

.application .v-system-bar .v-icon {
	font-size: 16px;
}

.application .v-system-bar--fixed,
  .application .v-system-bar--absolute {
	left: 0;
	top: 0;
	width: 100%;
	z-index: 3;
}

.application .v-system-bar--fixed {
	position: fixed;
}

.application .v-system-bar--absolute {
	position: absolute;
}

.application .v-system-bar--status .v-icon {
	margin-right: 4px;
}

.application .v-system-bar--window .v-icon {
	font-size: 20px;
	margin-right: 8px;
}

.application .theme--light.v-tabs__bar {
	background-color: #fff;
}

.application .theme--light.v-tabs__bar .v-tabs__div {
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--light.v-tabs__bar .v-tabs__item--disabled {
	color: rgba(0, 0, 0, 0.26);
}

.application .theme--dark.v-tabs__bar {
	background-color: #424242;
}

.application .theme--dark.v-tabs__bar .v-tabs__div {
	color: #fff;
}

.application .theme--dark.v-tabs__bar .v-tabs__item--disabled {
	color: rgba(255, 255, 255, 0.3);
}

.application .v-tabs {
	position: relative;
}

.application .v-tabs__bar {
	position: relative;
}

.application .v-tabs__icon {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	cursor: pointer;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	height: 100%;
	position: absolute;
	top: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 32px;
}

.application .v-tabs__icon--prev {
	left: 4px;
}

.application .v-tabs__icon--next {
	right: 4px;
}

.application .v-tabs__wrapper {
	overflow: hidden;
	contain: content;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.application .v-tabs__wrapper--show-arrows {
	margin-left: 40px;
	margin-right: 40px;
}

.application .v-tabs__wrapper--show-arrows .v-tabs__container--align-with-title {
	padding-left: 16px;
}

.application .v-tabs__container {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 48px;
	list-style-type: none;
	transition: -webkit-transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	white-space: nowrap;
	position: relative;
}

.application .v-tabs__container--overflow .v-tabs__div {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
}

.application .v-tabs__container--grow .v-tabs__div {
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
	max-width: none;
}

.application .v-tabs__container--icons-and-text {
	height: 72px;
}

.application .v-tabs__container--align-with-title {
	padding-left: 56px;
}

.application .v-tabs__container--fixed-tabs .v-tabs__div,
  .application .v-tabs__container--icons-and-text .v-tabs__div {
	min-width: 72px;
}

.application .v-tabs__container--centered > .v-tabs__div:first-child,
  .application .v-tabs__container--fixed-tabs > .v-tabs__div:first-child,
  .application .v-tabs__container--right > .v-tabs__div:first-child {
	margin-left: auto;
}

.application .v-tabs__container--centered .v-tabs__slider-wrapper + .v-tabs__div,
  .application .v-tabs__container--fixed-tabs .v-tabs__slider-wrapper + .v-tabs__div,
  .application .v-tabs__container--right .v-tabs__slider-wrapper + .v-tabs__div {
	margin-left: auto;
}

.application .v-tabs__container--centered > .v-tabs__div:last-child,
  .application .v-tabs__container--fixed-tabs > .v-tabs__div:last-child {
	margin-right: auto;
}

.application .v-tabs__container--icons-and-text .v-tabs__item {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: column-reverse;
	        flex-direction: column-reverse;
}

.application .v-tabs__container--icons-and-text .v-tabs__item .v-icon {
	margin-bottom: 6px;
}

.application .v-tabs__div {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-flex: 0;
	    -ms-flex: 0 1 auto;
	        flex: 0 1 auto;
	font-size: 14px;
	font-weight: 500;
	line-height: normal;
	height: inherit;
	max-width: 264px;
	text-align: center;
	text-transform: uppercase;
	vertical-align: middle;
}

.application .v-tabs__item {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	color: inherit;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	height: 100%;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	max-width: inherit;
	padding: 6px 12px;
	text-decoration: none;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: normal;
}

.application .v-tabs__item:not(.v-tabs__item--active) {
	opacity: 0.7;
}

.application .v-tabs__item--disabled {
	pointer-events: none;
}

.application .v-tabs__slider {
	height: 2px;
	width: 100%;
}

.application .v-tabs__slider-wrapper {
	bottom: 0;
	margin: 0 !important;
	position: absolute;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.application .v-tabs__items {
	overflow: hidden;
	position: relative;
}

.application .v-tabs__content {
	width: 100%;
	transition: -webkit-transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
	-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
	transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
	transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

@media only screen and (max-width: 599px) {
	.application .v-tabs__wrapper--show-arrows .v-tabs__container--align-with-title {
		padding-left: 24px;
	}

	.application .v-tabs__container--fixed-tabs .v-tabs__div {
		-webkit-box-flex: 1;
		    -ms-flex: 1 0 auto;
		        flex: 1 0 auto;
	}
}

@media only screen and (min-width: 600px) {
	.application .v-tabs__container--fixed-tabs .v-tabs__div,
    .application .v-tabs__container--icons-and-text .v-tabs__div {
		min-width: 160px;
	}
}

.application .theme--light.v-textarea.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused textarea {
	color: #fff;
}

.application .theme--dark.v-textarea.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused textarea {
	color: rgba(0, 0, 0, 0.87);
}

.application .application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot {
	margin-right: 0;
	margin-left: -12px;
}

.application .application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
	padding-right: 0;
	padding-left: 12px;
}

.application .v-textarea textarea {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	line-height: 18px;
	max-width: 100%;
	min-height: 32px;
	outline: none;
	padding: 7px 0 8px;
	width: 100%;
}

.application .v-textarea .v-text-field__prefix {
	padding-top: 4px;
	-ms-flex-item-align: start;
	    align-self: start;
}

.application .v-textarea.v-text-field--full-width .v-text-field__slot textarea,
  .application .v-textarea.v-text-field--full-width.v-text-field--single-line .v-text-field__slot textarea {
	margin-top: 0;
}

.application .v-textarea.v-text-field--full-width .v-text-field__details,
  .application .v-textarea.v-text-field--full-width.v-text-field--single-line .v-text-field__details {
	bottom: 4px;
}

.application .v-textarea.v-text-field--enclosed .v-text-field__slot {
	margin-right: -12px;
}

.application .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
	padding-right: 12px;
}

.application .v-textarea.v-text-field--box .v-text-field__prefix,
  .application .v-textarea.v-text-field--enclosed .v-text-field__prefix,
  .application .v-textarea.v-text-field--box textarea,
  .application .v-textarea.v-text-field--enclosed textarea {
	margin-top: 24px;
}

.application .v-textarea.v-text-field--box.v-text-field--single-line .v-text-field__prefix,
  .application .v-textarea.v-text-field--enclosed.v-text-field--single-line .v-text-field__prefix,
  .application .v-textarea.v-text-field--box.v-text-field--single-line textarea,
  .application .v-textarea.v-text-field--enclosed.v-text-field--single-line textarea {
	margin-top: 12px;
}

.application .v-textarea.v-text-field--box.v-text-field--single-line .v-label,
  .application .v-textarea.v-text-field--enclosed.v-text-field--single-line .v-label {
	top: 18px;
}

.application .v-textarea.v-text-field--box.v-text-field--single-line.v-text-field--outline .v-input__control,
  .application .v-textarea.v-text-field--enclosed.v-text-field--single-line.v-text-field--outline .v-input__control {
	padding-top: 0;
}

.application .v-textarea.v-text-field--solo {
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
}

.application .v-textarea.v-text-field--solo .v-input__prepend-inner,
  .application .v-textarea.v-text-field--solo .v-input__prepend-outer,
  .application .v-textarea.v-text-field--solo .v-input__append-inner,
  .application .v-textarea.v-text-field--solo .v-input__append-outer {
	-ms-flex-item-align: start;
	    align-self: flex-start;
	margin-top: 16px;
}

.application .v-textarea--auto-grow textarea {
	overflow: hidden;
}

.application .v-textarea--no-resize textarea {
	resize: none;
}

.application .v-time-picker-title {
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	line-height: 1;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
}

.application .v-time-picker-title__time {
	white-space: nowrap;
}

.application .v-time-picker-title__time .v-picker__title__btn,
  .application .v-time-picker-title__time span {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	height: 70px;
	font-size: 70px;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.application .v-time-picker-title__ampm {
	-ms-flex-item-align: end;
	    align-self: flex-end;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	font-size: 16px;
	margin: 8px 0 6px 8px;
	text-transform: uppercase;
}

.application .v-time-picker-title__ampm div:only-child {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
}

.application .v-picker__title--landscape .v-time-picker-title {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	height: 100%;
}

.application .v-picker__title--landscape .v-time-picker-title__time {
	text-align: right;
}

.application .v-picker__title--landscape .v-time-picker-title__time .v-picker__title__btn,
  .application .v-picker__title--landscape .v-time-picker-title__time span {
	height: 55px;
	font-size: 55px;
}

.application .v-picker__title--landscape .v-time-picker-title__ampm {
	margin: 16px 0 0;
	-ms-flex-item-align: initial;
	    align-self: initial;
	text-align: center;
}

.application .theme--light.v-time-picker-clock {
	background: #e0e0e0;
}

.application .theme--light.v-time-picker-clock > span.disabled {
	color: rgba(0, 0, 0, 0.26);
}

.application .theme--light.v-time-picker-clock > span.disabled.active {
	color: rgba(255, 255, 255, 0.3);
}

.application .theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__hand {
	background-color: #bdbdbd;
}

.application .theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__hand:after {
	color: #bdbdbd;
}

.application .theme--light.v-time-picker-clock--indeterminate > span.active {
	background-color: #bdbdbd;
}

.application .theme--dark.v-time-picker-clock {
	background: #616161;
}

.application .theme--dark.v-time-picker-clock > span.disabled {
	color: rgba(255, 255, 255, 0.3);
}

.application .theme--dark.v-time-picker-clock > span.disabled.active {
	color: rgba(255, 255, 255, 0.3);
}

.application .theme--dark.v-time-picker-clock--indeterminate .v-time-picker-clock__hand {
	background-color: #757575;
}

.application .theme--dark.v-time-picker-clock--indeterminate .v-time-picker-clock__hand:after {
	color: #757575;
}

.application .theme--dark.v-time-picker-clock--indeterminate > span.active {
	background-color: #757575;
}

.application .v-time-picker-clock {
	border-radius: 100%;
	position: relative;
	-webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-time-picker-clock__container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	padding: 10px;
}

.application .v-time-picker-clock__hand {
	height: calc(50% - 28px);
	width: 2px;
	bottom: 50%;
	left: calc(50% - 1px);
	-webkit-transform-origin: center bottom;
	transform-origin: center bottom;
	position: absolute;
	will-change: transform;
	z-index: 1;
}

.application .v-time-picker-clock__hand:before {
	background: transparent;
	border-width: 2px;
	border-style: solid;
	border-color: inherit;
	border-radius: 100%;
	width: 10px;
	height: 10px;
	content: '';
	position: absolute;
	top: -3%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.application .v-time-picker-clock__hand:after {
	content: '';
	position: absolute;
	height: 8px;
	width: 8px;
	top: 100%;
	left: 50%;
	border-radius: 100%;
	border-style: solid;
	border-color: inherit;
	background-color: inherit;
	-webkit-transform: translate(calc(-50%), -50%);
	transform: translate(calc(-50%), -50%);
}

.application .v-time-picker-clock > span {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	border-radius: 100%;
	cursor: default;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 16px;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	left: calc(50% - 40px / 2);
	height: 40px;
	position: absolute;
	text-align: center;
	top: calc(50% - 40px / 2);
	width: 40px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.application .v-time-picker-clock > span > span {
	z-index: 1;
}

.application .v-time-picker-clock > span:before,
  .application .v-time-picker-clock > span:after {
	content: '';
	border-radius: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	height: 14px;
	width: 14px;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.application .v-time-picker-clock > span:after,
  .application .v-time-picker-clock > span:before {
	height: 40px;
	width: 40px;
}

.application .v-time-picker-clock > span.active {
	color: #fff;
	cursor: default;
	z-index: 2;
}

.application .v-time-picker-clock > span.disabled {
	pointer-events: none;
}

.application .theme--light.v-toolbar {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.87);
}

.application .theme--dark.v-toolbar {
	background-color: #212121;
	color: #fff;
}

.application .v-toolbar {
	-webkit-transition: none;
	transition: none;
	-webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	position: relative;
	width: 100%;
	will-change: padding-left, padding-right;
}

.application .v-toolbar[data-booted="true"] {
	-webkit-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.application .v-toolbar .v-text-field--enclosed,
  .application .v-toolbar .v-text-field--box {
	margin: 0;
}

.application .v-toolbar .v-text-field--enclosed .v-text-field__details,
  .application .v-toolbar .v-text-field--box .v-text-field__details {
	display: none;
}

.application .v-toolbar .v-tabs {
	width: 100%;
}

.application .v-toolbar__title {
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 0.02em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.application .v-toolbar__title:not(:first-child) {
	margin-left: 20px;
}

.application .v-toolbar__content,
  .application .v-toolbar__extension {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 0 24px;
}

.application .v-toolbar__content .v-btn--icon,
  .application .v-toolbar__extension .v-btn--icon {
	margin: 6px;
}

.application .v-toolbar__content > *:first-child,
  .application .v-toolbar__extension > *:first-child {
	margin-left: 0;
}

.application .v-toolbar__content > *:first-child.v-btn--icon,
  .application .v-toolbar__extension > *:first-child.v-btn--icon {
	margin-left: -6px;
}

.application .v-toolbar__content > *:first-child.v-menu .v-menu__activator .v-btn,
  .application .v-toolbar__extension > *:first-child.v-menu .v-menu__activator .v-btn,
  .application .v-toolbar__content > *:first-child.v-tooltip span .v-btn,
  .application .v-toolbar__extension > *:first-child.v-tooltip span .v-btn {
	margin-left: 0;
}

.application .v-toolbar__content > *:first-child.v-menu .v-menu__activator .v-btn--icon,
  .application .v-toolbar__extension > *:first-child.v-menu .v-menu__activator .v-btn--icon,
  .application .v-toolbar__content > *:first-child.v-tooltip span .v-btn--icon,
  .application .v-toolbar__extension > *:first-child.v-tooltip span .v-btn--icon {
	margin-left: -6px;
}

.application .v-toolbar__content > *:last-child,
  .application .v-toolbar__extension > *:last-child {
	margin-right: 0;
}

.application .v-toolbar__content > *:last-child.v-btn--icon,
  .application .v-toolbar__extension > *:last-child.v-btn--icon {
	margin-right: -6px;
}

.application .v-toolbar__content > *:last-child.v-menu .v-menu__activator .v-btn,
  .application .v-toolbar__extension > *:last-child.v-menu .v-menu__activator .v-btn,
  .application .v-toolbar__content > *:last-child.v-tooltip span .v-btn,
  .application .v-toolbar__extension > *:last-child.v-tooltip span .v-btn {
	margin-right: 0;
}

.application .v-toolbar__content > *:last-child.v-menu .v-menu__activator .v-btn--icon,
  .application .v-toolbar__extension > *:last-child.v-menu .v-menu__activator .v-btn--icon,
  .application .v-toolbar__content > *:last-child.v-tooltip span .v-btn--icon,
  .application .v-toolbar__extension > *:last-child.v-tooltip span .v-btn--icon {
	margin-right: -6px;
}

.application .v-toolbar__content > .v-list,
  .application .v-toolbar__extension > .v-list {
	-webkit-box-flex: 1;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	max-height: 100%;
}

.application .v-toolbar__content > .v-list:first-child,
  .application .v-toolbar__extension > .v-list:first-child {
	margin-left: -24px;
}

.application .v-toolbar__content > .v-list:last-child,
  .application .v-toolbar__extension > .v-list:last-child {
	margin-right: -24px;
}

.application .v-toolbar__extension > .v-toolbar__title {
	margin-left: 72px;
}

.application .v-toolbar__items {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: inherit;
	max-width: 100%;
	padding: 0;
}

.application .v-toolbar__items .v-btn {
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-ms-flex-item-align: center;
	    align-self: center;
}

.application .v-toolbar__items .v-tooltip,
  .application .v-toolbar__items .v-tooltip > span {
	height: inherit;
}

.application .v-toolbar__items .v-btn:not(.v-btn--floating):not(.v-btn--icon),
  .application .v-toolbar__items .v-menu,
  .application .v-toolbar__items .v-menu__activator {
	height: inherit;
	margin: 0;
}

.application .v-toolbar .v-overflow-btn,
  .application .v-toolbar .v-btn-toggle {
	-webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.application .v-toolbar .v-input {
	margin: 0;
}

.application .v-toolbar .v-overflow-btn .v-input__control:before,
  .application .v-toolbar .v-overflow-btn .v-input__slot:before {
	display: none;
}

.application .v-toolbar--card {
	border-radius: 2px 2px 0 0;
	-webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.application .v-toolbar--fixed {
	position: fixed;
	z-index: 2;
}

.application .v-toolbar--fixed,
  .application .v-toolbar--absolute {
	top: 0;
	left: 0;
}

.application .v-toolbar--absolute {
	position: absolute;
	z-index: 2;
}

.application .v-toolbar--floating {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin: 16px;
	width: auto;
}

.application .v-toolbar--clipped {
	z-index: 3;
}

@media only screen and (max-width: 959px) {
	.application .v-toolbar__content,
    .application .v-toolbar__extension {
		padding: 0 16px;
	}

	.application .v-toolbar__content > .v-list:first-child,
    .application .v-toolbar__extension > .v-list:first-child {
		margin-left: -16px;
	}

	.application .v-toolbar__content > .v-list:last-child,
    .application .v-toolbar__extension > .v-list:last-child {
		margin-right: -16px;
	}
}

.application .v-tooltip {
	position: relative;
}

.application .v-tooltip__content {
	background: #616161;
	border-radius: 2px;
	color: #fff;
	font-size: 12px;
	display: inline-block;
	padding: 5px 8px;
	position: absolute;
	text-transform: initial;
	-webkit-transition: 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
	transition: 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
	width: auto;
	-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.application .v-tooltip__content[class*="-active"] {
	pointer-events: none;
}

@media only screen and (max-width: 959px) {
	.application .v-tooltip .v-tooltip__content {
		padding: 10px 16px;
	}
}
.app-store-heading-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-line-pack: center;
      align-content: center;
}
.autosuggest__results-container {
  position: absolute;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255,0);
  width: calc(100% + 1px - 1.2rem);
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  top: calc(100% - 5px);
  left: -2px;
  padding: 0;
  line-height: 1.5;
  font-size: 1.3rem;
  cursor: pointer;
}
.autosuggest__results-container.suggest-window-open {
  padding: 0.5rem 0.6rem;
  border-right: 2px solid #e0e0e0;
  border-bottom: 2px solid #e0e0e0;
  border-left: 2px solid #e0e0e0;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}
.autosuggest__results-container ul {
  list-style: none;
  padding-left: 0;
}
.autosuggest__results-container ul > li > img {
  display: none !important;
}
#app {
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  overflow: hidden;
}
.mh-0 {
  max-height: 0px;
  overflow: hidden;
  -webkit-transition: max-height 0.2s linear;
  transition: max-height 0.2s linear;
}
.mh-0.mh-386 {
  max-height: 386px;
}
.row-offset-right {
  margin: 0 -20px 0 0 !important;
}
.vb-content {
  max-height: 27.1rem;
}
#autocomplete-input {
  -ms-flex-item-align: center;
      align-self: center;
}
.app-no-selection {
  font-size: 0.85rem;
  font-weight: 400;
  text-align: center;
  margin-top: 12rem;
  color: #474747;
}
.application--wrap {
  min-height: 1px;
}
.v-toolbar {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.v-toolbar__content {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 16px;
}
.v-toolbar__content i {
  font-size: 2rem;
  cursor: pointer;
}
.v-toolbar.toolbar-top {
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.v-toolbar__title {
  font-size: 1.725rem;
}
.application {
  background: none !important;
}
.v-content {
  background: #fff;
  padding-top: 0 !important;
}
.container.fluid {
  max-width: 100%;
  padding: 0;
}
.v-subheader {
  padding: 0;
  height: auto;
}
.app-store-list-heading {
  font-size: 0.925rem;
  color: #000;
  font-weight: 700;
  letter-spacing: 0.02rem;
}
.app-store-list-heading-link,
.app-store-list-heading-link:hover {
  text-decoration: none;
}
.app-store-list-info {
  font-size: 0.925rem;
  font-weight: 400;
  letter-spacing: normal;
}
.stores-list-container {
  padding-left: 0;
  padding-right: 0;
  height: 65vh;
  padding-top: 10px;
}
.vb-dragger {
  background: #939598;
  width: 10px;
  right: 0;
}
.store-item-card.v-card.theme--light {
  background-color: #cccccc;
}
.store-container.store-hovered-on .store-item-card.v-card.theme--light {
  border-left: 8px solid #ec1b2f;
  background-color: #e5e5e5;
}
.store-hours {
  padding-left: 20px;
}
.store-item-card {
  cursor: pointer;
}
.store-item-card.v-card.theme--light.isSelected {
  border-left: 8px solid #ec1b2f;
  background-color: #e5e5e5;
}
.store-item-card .v-card__text {
  font-size: 0.85rem;
  font-weight: 400;
}
.store-item-card.v-card {
  border-radius: 0;
}
.container.search-container {
  padding: 1.4rem 1.2rem;
}
.container.lower-search-container.fluid.grid-list-lg {
  padding: 8px;
}
.container.grid-list-lg .layout .flex.stores-list,
.container.grid-list-lg .layout .flex.stores-infowindow {
  padding: 0;
}
.container.stores-list-container.fluid .layout.row,
.flex.stores-infowindow.xs12.md6 .layout.row {
  margin: 0;
}
.container.grid-list-lg .layout .flex.store-container,
.flex.stores-infowindow .layout.row .flex {
  padding: 0;
}
.v-icon.recenter-map-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #1976d2;
  font-size: 1.45rem;
  margin-left: auto;
  width: 1.4rem;
}
.location-edit .inline-input {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 70%;
}
.location-edit .inline-button {
  margin-left: 0;
  margin-right: 0;
}
.recenter-map-icon {
  cursor: pointer;
}
.store-item-card {
  border-left: 8px solid #cccccc;
  background-color: #cccccc;
}
.app-search-subheading {
  font-size: 0.8rem;
  color: #808080 !important;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.025rem;
}
.app-status-subheading {
  font-size: 0.8rem;
  color: #808080;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.025rem;
  line-height: 1.4rem;
}
.v-autocomplete.v-input.primary--text,
.v-text-field .v-label.primary--text {
  color: #333333 !important;
}
.v-input.app-search-input {
  border: 2px solid #e0e0e0;
  border-radius: 0.4rem;
  margin-top: 0;
  padding-top: 0;
}
button.app-input-button.v-btn {
  width: 100%;
  border: 2px solid #e0e0e0;
  border-radius: 0.4rem;
  text-transform: none;
  color: #999999;
  font-size: 0.9rem;
  font-weight: 700;
  margin: 0;
}
.app-search-input .v-icon {
  color: #ec1b2f !important;
  margin-right: 1rem;
  font-size: 1.6rem;
  position: relative;
  top: 1px;
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
}
.v-input.app-search-input input {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0 0.6rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.5;
}
.v-input.app-search-input label {
  display: none !important;
}
.v-btn__content > .app-input-btn-text {
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 400;
}
.v-input.app-search-input .v-input__slot:before,
.v-input.app-search-input .v-input__slot:after {
  display: none !important;
}
.v-btn__content .fa-statewide-map-marker {
  margin-right: 0.75rem;
  font-size: 1.4rem;
}
button.app-input-button {
  outline: 0;
}
.app-store-list-distance {
  line-height: 19.4px;
}
.app-location-window-info.app-location-info-street-one {
  max-width: 300px;
}













.span-pipe {
  padding-left: 10px;
  padding-right: 10px;
}
.app-location-headline {
  font-size: 1.4rem;
  letter-spacing: normal;
  font-weight: 500;
  line-height: 1.5;
}
.app-location-image {
  width: 100%;
  max-width: 100%;
  -ms-flex-item-align: end;
      align-self: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.app-location-day {
  min-width: 3rem;
  display: inline-block;
}
.app-location-prefix {
  display: inline-block;
  min-width: 1.2rem;
}
.app-location-card.v-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  min-height: 27.1rem;
}
.app-location-window-info {
  font-size: 0.925rem;
  font-weight: 400;
}
.app-location-card .v-card__text {
  padding: 0 8px;
}
.app-location-card > .layout.row {
  padding: 0 8px 16px;
}
